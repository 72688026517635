import React from "react";
import styles from "./SpeakerDetails.module.css";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import StylishHeading from "../../../../../components/StylishHeading/StylishHeading";
import TemplateButton from "../../../../../components/TemplateButton/TemplateButton";
import { KTALKS_REGISTRATION_LINK } from "../../../../../data/GuestLectures";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function SpeakerDetails({ isOpen, data, clickedSpeakerDetailsClose }) {
  return (
    <Modal
      className={`${styles.modalWrapper} customScrollbar`}
      overlayClassName={`${styles.modalOverlay}`}
      isOpen={isOpen}
      closeTimeoutMS={200}
    >
      <div className={`${styles.modalContainer} customScrollbar`}>
        <CloseIcon
          onClick={clickedSpeakerDetailsClose}
          className={`${styles.close_icon}`}
        />
        <div className={`${styles.imageContainer}`}>
          <img src={data.speaker.filler} alt={data.speaker.name} />
        </div>
        <div className={`${styles.contentContainer}`}>
          <StylishHeading text={data.speaker.title} />
          <div className={`${styles.descriptionContainer} customScrollbar`}>
            <div className={`customScrollbar`}>{data.speaker.description}</div>
          </div>
          <div className={`${styles.sessionInfo}`}>
            <p>
              <CalendarTodayIcon className={`${styles.icon}`} />
              &nbsp;{data.date}
            </p>
            <p>
              <AccessTimeIcon className={`${styles.icon}`} />
              &nbsp;{data.time}
            </p>
            <p>
              <LocationOnIcon className={`${styles.icon}`} />
              &nbsp;{data.venue}
            </p>
          </div>
          <div className={`${styles.regButtonContainer}`}>
            <TemplateButton
              text={"Book Tickets"}
              onClickMethod={() => window.open(KTALKS_REGISTRATION_LINK)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SpeakerDetails;
