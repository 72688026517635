import React from "react";
import styles from "./ProjectDetails.module.css";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import ImageCarouselInCard from "../../../components/ImageCarouselInCard/ImageCarouselInCard";
import StylishHeading from "../../../components/StylishHeading/StylishHeading";

function ProjectDetails({ isOpen, project, clickedProjectDetailsClose }) {
  return (
    <Modal
      className={`${styles.modalWrapper}`}
      overlayClassName={`${styles.modalOverlay}`}
      isOpen={isOpen}
      closeTimeoutMS={200}
    >
      <div className={`${styles.modalContainer}`}>
        <CloseIcon
          onClick={clickedProjectDetailsClose}
          className={`${styles.close_icon}`}
        />
        <div className={`${styles.imageContainer}`}>
          {project !== undefined && <ImageCarouselInCard data={project} />}
        </div>
        <div className={`${styles.contentContainer}`}>
          <StylishHeading text={project.title} />
          <div>
            <div className={`customScrollbar`}>{project.description}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ProjectDetails;
