import { useRef } from "react";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import styles from "./GuestLectures.module.css";
import { IMAGES } from "../../data/GuestLectures";

import Header from "./Sections/Header/header.js";
import Quote from "./Sections/Quote/quote.js";
import Description from "./Sections/Description/description";
import Domains from "./Sections/Domains/domains.js";
import Information from "./Sections/Information/information";
import { useOnLoadImages } from "../../utils/useOnLoadImages.ts";
import Page_transition from "../../components/Animation/Transition";
import PageLoader from "../../components/PageLoader/PageLoader";
import UpcomingKTalks from "./Sections/UpcomingKTalks/UpcomingKTalks";
function GuestLectures() {
  return (
    <Page_transition>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.bgDiv} colorPaletteGradientBackground`}></div>
        <section>
          <Header />
          <UpcomingKTalks />
          <Quote />
          <Description />
          <Domains />
          <ImageCarousel data={IMAGES} />
          <Information />
        </section>
      </div>
    </Page_transition>
  );
}

export default GuestLectures;
