import React, { useRef, useEffect } from "react";

import { INFO1, imageBasePath } from "../../data/AboutUs";
import styles from "./AboutUs.module.css";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Heading from "../../components/Heading/Heading";
import Page_transition from "../../components/Animation/Transition";
import { useOnLoadImages } from "../../utils/useOnLoadImages.ts";
import PageLoader from "../../components/PageLoader/PageLoader";
import StylishHeading from "../../components/StylishHeading/StylishHeading";
import backdrop from "../../assets/bg/quote_bg_1.jpg";
gsap.registerPlugin(ScrollTrigger);

function AboutUs() {
  const wrapperRef = useRef(null);
  // const imagesLoaded = useOnLoadImages(wrapperRef);

  let containersRef = new Array(INFO1.length);
  let imagesRef = new Array(INFO1.length);

  useEffect(() => {
    let timelines = new Array(INFO1.length);

    for (let i = 0; i < containersRef.length; i++) {
      timelines[i] = gsap.timeline();
      timelines[i].from(imagesRef[i], {
        scrollTrigger: {
          trigger: containersRef[i],
          start: "top 60%",
          end: "center 50%",
          scrub: 0.5,
        },
        duration: 1,
        opacity: 0,
        y: "100px",
        ease: Power3.easeOut,
      });
    }

    return () => {};
  }, []);

  return (
    <Page_transition>
      <div className={`${styles.wrapper}`} ref={wrapperRef}>
        <div className={`${styles.bgDiv}`}>
          <img src={backdrop} alt="Backdrop" />
        </div>
        {/* {!imagesLoaded && <PageLoader />} */}
        <div
          name="main"
          className={`${styles.content_wrapper}`}
          // style={{ display: imagesLoaded ? "flex" : "none" }}
        >
          <div className={`${styles.headingContainer}`}>
            <Heading text={"About Us"} />
          </div>
          {INFO1.map((item, index) => {
            return (
              <div
                ref={(el) => (containersRef[index] = el)}
                key={index}
                className={`${styles.content_container}`}
              >
                <div
                  ref={(el) => (imagesRef[index] = el)}
                  className={`${styles.image_box}`}
                >
                  <img
                    src={`${imageBasePath}${item.image}`}
                    alt={`${item.title}`}
                  />
                </div>

                <div className={`${styles.text_box}`}>
                  <StylishHeading text={item.title} />
                  <p>{item.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Page_transition>
  );
}

export default AboutUs;
