import { CODING_EVENTS } from "./Coding";
import { ENGINEERING_EVENTS } from "./Engineering";
import { MANAGEMENT_EVENTS } from "./Management";
import { QUIZ_EVENTS } from "./Quiz";
import { EXTRAVAGANZA_EVENTS } from "./Extravaganza";
import { ROBOTICS_EVENTS } from "./Robotics";
import { PAPER_PRESENTATION_EVENTS } from "./Paper Presentation";

export const eventTypeImageBasePath = `${process.env.PUBLIC_URL}/assets/images/EventTypes/`;
export const EVENT_TYPES = [
  {
    name: "General Registration",
    image: "engineering.png",
    url: "gen-reg",
  },
  {
    name: "Engineering",
    image: "engineering.png",
    url: "engineering",
  },
  {
    name: "Coding",
    image: "coding.png",
    url: "coding",
  },
  {
    name: "Management",
    image: "management.png",
    url: "management",
  },
  {
    name: "Quiz",
    image: "quiz.png",
    url: "quiz",
  },
  {
    name: "Extravaganza",
    image: "extravaganza.png",
    url: "extravaganza",
  },
  {
    name: "Robotics",
    image: "robotics.png",
    url: "robotics",
  },
  {
    name: "Paper Presentation",
    image: "paper_presentation.png",
    url: "paper-presentation",
  },
];

export const eventDataMapping = {
  engineering: ENGINEERING_EVENTS,
  coding: CODING_EVENTS,
  management: MANAGEMENT_EVENTS,
  quiz: QUIZ_EVENTS,
  extravaganza: EXTRAVAGANZA_EVENTS,
  robotics: ROBOTICS_EVENTS,
  "paper-presentation": PAPER_PRESENTATION_EVENTS,
};
