import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Accomodation.module.css";
import Heading from "../../components/Heading/Heading";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";
import FormField from "../../components/FormField/FormField";
import Popup from "./Popup";
import styles1 from "./Popup.module.css";

import { ABSTRACT_PDF_EXTENSIONS, TEAM_SIZES } from "../../data/Technovation";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from "../../components/ToastNotification";
import DeleteIcon from "@mui/icons-material/Delete";
import { validateTechnovationForm } from "../../validators/technovationFormValidator";
import {
  apiGetEventRegistrations,
  apiTechnovationRegistration,
  apiPay,
  apiAccomodationRegistration,
} from "../../api/auth";
import Page_transition from "../../components/Animation/Transition";
import { Auth } from "../../App";
import { ApartmentOutlined } from "@mui/icons-material";
import { validateAccomodationForm } from "../../validators/accomodationFormValidation";
import InstructionsDialog from "./InstructionsDialog/InstructionsDialog";
const AccomodationFormat = {
  days: "",
};

const userDetailsFormat = {
  name: "",
  dept: "",
  year: 1,
};

let amount = 0.0;
let n = 0;
function Accomodation() {
  const auth = useContext(Auth);

  //Changed code starts here

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [foodreq, setFoodreq] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggle1 = () => {
    setChecked1(!checked1);
    if (checked1 == false) {
      amount += 250;
      n += 1;
      if (foodreq) {
        amount += 150;
      }
    } else {
      amount = amount - 250;
      n -= 1;
      if (foodreq) {
        amount -= 150;
      }
    }
  };
  const toggle2 = () => {
    setChecked2(!checked2);
    if (checked2 == false) {
      amount = amount + 250;
      n += 1;
      if (foodreq) {
        amount += 150;
      }
    } else {
      amount = amount - 250;
      n -= 1;
      if (foodreq) {
        amount -= 150;
      }
    }
  };

  const toggle3 = () => {
    setChecked3(!checked3);
    if (checked3 == false) {
      amount = amount + 250;
      n += 1;
      if (foodreq) {
        amount += 150;
      }
    } else {
      amount = amount - 250;
      n -= 1;
      if (foodreq) {
        amount -= 150;
      }
    }
  };

  const toggle4 = () => {
    setChecked4(!checked4);
    if (checked4 == false) {
      amount = amount + 250;
      n += 1;
      if (foodreq) {
        amount += 150;
      }
    } else {
      amount = amount - 250;
      n -= 1;
      if (foodreq) {
        amount -= 150;
      }
    }
  };

  const Foodreq = () => {
    setFoodreq(!foodreq);
    if (foodreq == false) {
      amount = amount + n * 150;
    } else {
      amount = n * 250;
    }
  };

  const togglePopup = (action) => {
    // Form Validation
    if (action === "OPEN") {
      let validation = validateAccomodationForm({
        govtIdPDF: govtIdPDF,
        collegeIdPDF: collegeIdPDF,
        dates: [checked1, checked2, checked3, checked4],
      });

      if (validation.status === false) {
        showErrorToastNotification(<p>{validation.message}</p>);
        return;
      }
    }

    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const [AccomodationDetails, setAccomodationDetails] =
    useState(AccomodationFormat);
  const [govtIdPDF, setGovtIdPDF] = useState(null);
  const [collegeIdPDF, setCollegeIdPDF] = useState(null);
  const [loader, setloader] = useState(false);

  const [regStatus, setregStatus] = useState(false);
  const [payStatus, setpayStatus] = useState(false);

  const [userGender, setuserGender] = useState("M");

  useEffect(() => {
    let _AccomodationDetails = [];
    _AccomodationDetails.push({
      dates: "Apr 5",
      isFormOpen: true,
    });

    return () => {};
  }, []);

  const fetchRegistrationsDetails = async () => {
    let config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };

    let resp = await apiGetEventRegistrations(config);

    if (
      (resp.status >= 400 && resp.status < 500) ||
      (resp.status >= 500 && resp.status < 600)
    ) {
      showErrorToastNotification(<p>{resp.data.message}</p>);
      navigate("/");
      return;
    }

    for (let i = 0; i < resp.data.length; i++) {
      let event = resp.data[i];
      // Registered
      if (event.purpose === "ACCOMODATION") {
        setregStatus(true);
        // Paid
        if (event.paymentid === "Credit") {
          setpayStatus(true);
        }
        // Not Paid
        else {
          setpayStatus(false);
        }
        break;
      }
    }
  };

  // useEffect(() => {
  //   fetchRegistrationsDetails();

  //   return () => {};
  // }, []);

  const govtIdPDFChangeHandler = (event) => {
    let file = event.target.files[0];
    if (file.size > 5000000) {
      showErrorToastNotification(<p>File size should be less than 5MB.</p>);
      return;
    }

    if (!ABSTRACT_PDF_EXTENSIONS.includes(getFileExtension(file.name))) {
      showErrorToastNotification(<p>Only pdf is allowed.</p>);
      return;
    }
    setGovtIdPDF(file);
  };

  const collegeIdPDFChangeHandler = (event) => {
    let file = event.target.files[0];
    if (file.size > 5000000) {
      showErrorToastNotification(<p>File size should be less than 5MB.</p>);
      return;
    }

    if (!ABSTRACT_PDF_EXTENSIONS.includes(getFileExtension(file.name))) {
      showErrorToastNotification(<p>Only pdf is allowed.</p>);
      return;
    }
    setCollegeIdPDF(file);
  };

  const removeGovtIdPDF = () => {
    setGovtIdPDF(null);
  };
  const removeCollegeIdPDF = () => {
    setCollegeIdPDF(null);
  };
  const clickedRegister = async () => {
    // Form Validation
    let validation = validateAccomodationForm({
      govtIdPDF: govtIdPDF,
      collegeIdPDF: collegeIdPDF,
      dates: [checked1, checked2, checked3, checked4],
    });

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    setloader(true);

    let config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };

    const resp = await apiAccomodationRegistration(
      {
        data: {
          d1: checked1,
          d2: checked2,
          d3: checked3,
          d4: checked4,
          food: foodreq,
          sex: userGender,
        },
        collegeIdPDF: collegeIdPDF,
        govtIdPDF: govtIdPDF,
      },
      config
    );

    setloader(false);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        showSuccessToastNotification(<p>{resp.data.message}</p>);
        setregStatus(true);
        setpayStatus(false);
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  const getFileExtension = (fileName) => {
    let ext = "";

    for (let i = fileName.length - 1; i >= 0; i--) {
      if (fileName.charAt(i) === ".") break;
      ext += fileName.charAt(i);
    }

    return ext.split("").reverse().join("");
  };

  const clickedPay = async () => {
    let resp = await apiPay(
      {
        event: "ACCOMODATION",
      },
      {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }
    );

    // If errors
    if (
      (resp.status >= 400 && resp.status < 500) ||
      (resp.status >= 500 && resp.status < 600)
    ) {
      showErrorToastNotification(<p>{resp.data.message}</p>);
      return;
    }

    // Success ==> Redirect
    window.location = resp.data.link;
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper} colorPaletteGradientBackground`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.headingContainer}`}>
            <Heading text={"Accomodation Booking"} />
          </div>
          {loader && <SimpleLoader text={"Booking..."} />}
          {/* Registered && Not Paid */}
          {regStatus && !payStatus && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90%",
                textAlign: "center",
              }}
            >
              <p>Already registered for accomodation. Yet to pay!</p>
              <p style={{ marginTop: "10px" }}>
                For any queries, contact hospitality@cegtechforum.in
              </p>
              <div
                className={`${styles.buttonContainer}`}
                style={{ marginTop: "30px" }}
              >
                <GlitchButton text={"Pay"} onClickMethod={clickedPay} />
              </div>
            </div>
          )}

          {/* Registered && Paid */}
          {regStatus && payStatus && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90%",
                textAlign: "center",
              }}
            >
              <p>Already registered and paid for accomodation.</p>
              <p style={{ marginTop: "10px" }}>
                For any queries, contact hospitality@cegtechforum.in
              </p>
            </div>
          )}

          {/* Not Registered */}
          {!regStatus && !payStatus && (
            <>
              <div className={styles.topHeading}>
                <p className={styles.Choose}>
                  Choose the dates for accomodation
                </p>
                <p className={`${styles.costDetails}`}>
                  Without food - Rs. 250 per day
                </p>
                <p className={`${styles.costDetails}`}>
                  With food - Rs. 400 per day
                </p>
              </div>
              <div className={styles.checkWrapper}>
                <div className={`${styles.formComponents}`}>
                  <input
                    type="checkbox"
                    value="5"
                    onChange={toggle1}
                    className={styles.checker}
                  ></input>
                  <p>April 5</p>
                </div>

                <div className={`${styles.formComponents}`}>
                  <input
                    type="checkbox"
                    value="6"
                    onChange={toggle2}
                    className={styles.checker}
                  ></input>
                  <p>April 6</p>
                </div>

                <div className={`${styles.formComponents}`}>
                  <input
                    type="checkbox"
                    value="7"
                    onChange={toggle3}
                    className={styles.checker}
                  ></input>
                  <p>April 7</p>
                </div>

                <div className={`${styles.formComponents}`}>
                  <input
                    type="checkbox"
                    value="8"
                    onChange={toggle4}
                    className={styles.checker}
                  ></input>
                  <p>April 8</p>
                </div>
              </div>

              <div className={`${styles.genderContainer}`}>
                <div className={`${styles.genderRadio}`}>
                  <input
                    checked={userGender === "M"}
                    id="male"
                    type="radio"
                    value={"Male"}
                    onChange={(e) => setuserGender("M")}
                  />
                  <label htmlFor="male">Male</label>
                </div>
                <div className={`${styles.genderRadio}`}>
                  <input
                    checked={userGender === "F"}
                    id="female"
                    type="radio"
                    value={"Female"}
                    onChange={(e) => setuserGender("F")}
                  />
                  <label htmlFor="female">Female</label>
                </div>
              </div>

              <div className={styles.Fooddiv}>
                <p>I require food for the days I had requested accomodation</p>
                <input
                  type="checkbox"
                  className={styles.checker}
                  onChange={Foodreq}
                ></input>
              </div>

              <div className={`${styles.totalAmountContainer}`}>
                <p>
                  Total - <span>Rs.&nbsp;{amount}/-</span>
                </p>
              </div>

              <div className={`${styles.projectDetailsContainer}`}>
                <div className={`${styles.projectImagesContainer}`}>
                  <p className={`${styles.projectFileHeading}`}>
                    Upload College ID Card
                  </p>
                  <p className={`${styles.instructions}`}>
                    Only PDF format is allowed. Size of the file should be less
                    than 5MB.
                  </p>
                  <input
                    type="file"
                    onChange={collegeIdPDFChangeHandler}
                    className={styles.fileInput}
                  ></input>
                  {collegeIdPDF !== null && (
                    <div className={`${styles.projectFileName}`}>
                      <p>{collegeIdPDF.name}</p>&nbsp;
                      <DeleteIcon
                        className={`${styles.deleteIcon}`}
                        onClick={removeCollegeIdPDF}
                      />
                    </div>
                  )}
                </div>
                <div className={`${styles.projectImagesContainer}`}>
                  <p className={`${styles.projectFileHeading}`}>
                    Upload any Government ID Card
                  </p>
                  <p className={`${styles.instructions}`}>
                    Only PDF format is allowed. Size of the file should be less
                    than 5MB.
                  </p>
                  <input
                    type="file"
                    onChange={govtIdPDFChangeHandler}
                    className={styles.fileInput}
                  ></input>
                  {govtIdPDF !== null && (
                    <div className={`${styles.projectFileName}`}>
                      <p>{govtIdPDF.name}</p>&nbsp;
                      <DeleteIcon
                        className={`${styles.deleteIcon}`}
                        onClick={removeGovtIdPDF}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.buttonContainer}`}>
                <GlitchButton
                  text={"BOOK NOW"}
                  // onClickMethod={() => togglePopup("OPEN")}
                ></GlitchButton>
              </div>
              <InstructionsDialog
                isOpen={isOpen}
                clickedClose={togglePopup}
                clickedRegister={clickedRegister}
              />
            </>
          )}
        </div>
      </div>
    </Page_transition>
  );
}

export default Accomodation;
