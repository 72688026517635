import React, { useState } from "react";
import Heading from "../../../../components/Heading/Heading";
import SimpleDetailHoverCard from "../../../../components/SimpleDetailHoverCard/SimpleDetailHoverCard";
import TemplateButton from "../../../../components/TemplateButton/TemplateButton";
import {
  guestLecturesBasePath,
  KTALKS_DETAILS,
  KTALKS_REGISTRATION_LINK,
} from "../../../../data/GuestLectures";
import SpeakerDetails from "./SpeakerDetails/SpeakerDetails";
import styles from "./UpcomingKTalks.module.css";

function UpcomingKTalks() {
  const [isSpeakerDetailsOpen, setisSpeakerDetailsOpen] = useState(false);
  const [speakerDetails, setspeakerDetails] = useState(KTALKS_DETAILS[0]);

  const clickedSpeaker = (data) => {
    setspeakerDetails(data);
    setisSpeakerDetailsOpen(true);
  };

  const clickedSpeakerDetailsClose = () => {
    setisSpeakerDetailsOpen(false);
  };

  return (
    <>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.headingContainer}`}>
          <h1>Upcoming K! Talks</h1>
        </div>
        <div className={`${styles.cardsContainer}`}>
          {KTALKS_DETAILS.map((talk, index) => {
            return (
              <div key={index} className={`${styles.cardBox}`}>
                <SimpleDetailHoverCard
                  data={talk.speaker}
                  onClickMethod={() => clickedSpeaker(talk)}
                />
              </div>
            );
          })}
        </div>
        <div className={`${styles.regButtonContainer}`}>
          <TemplateButton
            text={"Book Tickets"}
            onClickMethod={() => window.open(KTALKS_REGISTRATION_LINK)}
          />
        </div>
      </div>
      <SpeakerDetails
        isOpen={isSpeakerDetailsOpen}
        data={speakerDetails}
        clickedSpeakerDetailsClose={clickedSpeakerDetailsClose}
      />
    </>
  );
}

export default UpcomingKTalks;
