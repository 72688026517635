export const imageBasePath = `${process.env.PUBLIC_URL}/assets/images/Logos/`;

export const INFO1 = [
  {
    title: "College of Engineering Guindy",
    content:
      "College of Engineering Guindy emerged as the top institution by creating professionals with high technical knowledge, ethical values by providing them with high quality academic and research programs. We enhance the collaborative partnership between industry and institute for commercializing efficient administrative coordination and effective decision-making through necessary reforms.",
    image: "ceg.png",
  },
  {
    title: "CEG Tech Forum",
    content:
      "CEG Tech Forum is a non-profit techno-management organization managed completely by students with the vision of expanding the technical horizons of the student community. CEG Tech Forum sets the bar on the technical benchmark by supporting students in constructing projects with high yielding ideas and adroit initiatives. Our initiatives are mainly focused on the social concerns of the current times. We have been doing this hustle since 2006 and we never missed our quality which earned us the ISO 9001:2015 quality standard certification.",
    image: "ctf_full.png",
  },

  {
    title: "Kurukshetra",
    content:
      "Kurukshetra is an international techno-management fest holding a UNESCO patronage. It was named after the apocalyptic battle between Kauravas and Pandavas in an ancient Indian epic, the Mahabharata. All the five Pandavas have different powers and skills which led them to win the battle. So, the different qualities of people will make this a perfect battle. But here, the battle is more interesting because it takes place with brains instead of swords. Its logo, the Cyclotron, symbolizes the celebration of the indomitable spirit of engineering and innovation. That was the battle fought with immense physical powers to conquer the territory but this is the battle of cognitive skills to win the tech world from which the tagline ‘’The Battle of Brains‘’ was derived.",
    image: "kurukshetra_logo_white.png",
  },
];
