import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./LoginRegistration.module.css";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import FormField from "../../components/FormField/FormField";
import { showSuccessToastNotification, showErrorToastNotification } from "../../components/ToastNotification";
import Page_transition from "../../components/Animation/Transition";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";
import rollNoIcon from "../../assets/images/LoginRegistration/idcard.png";
import yearIcon from "../../assets/images/LoginRegistration/year.png";
import { validateCEGianVerificationForm } from "../../validators/CEGianVerificationValidator";
import { apiCegianVerify } from "../../api/auth";
const CEGianVerificationDetailsFormat = {
  roll: "",
  dob: "",
};

function VerifyCEGian() {
  const [CEGianVerificationDetails, setCEGianVerificationDetails] = useState(
    CEGianVerificationDetailsFormat
  );

  const [loader, setloader] = useState(false);
  let reCaptchaRef = useRef(null);
  const changeCEGianVerificationDetails = (args) => {
    let prevState = CEGianVerificationDetails;
    prevState[args.key] = args.value;
    setCEGianVerificationDetails({ ...prevState });
  };

  const clickedVerify = async () => {
    // Form Validation
    let validation = validateCEGianVerificationForm({
      roll: CEGianVerificationDetails.roll,
      dob: CEGianVerificationDetails.dob,
    });
    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    setloader(true);
    const resp = await apiCegianVerify(
      {
      ...CEGianVerificationDetails,
      captcha: reCaptchaRef.current.getValue(),
      },
      {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
    reCaptchaRef.current.reset();
    setloader(false);
    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        showSuccessToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.componentContainer}`}>
          <div className={`${styles.designContainer}`}></div>
          <div className={`${styles.formContainer}  customScrollbar`}>
            {loader && <SimpleLoader text={"Verifying"} />}
            <div
              className={`${styles.formContent}`}
              style={{ display: loader ? "none" : "flex" }}
            >
              <h1>CEGian Verification</h1>
              <p className={`${styles.divider}`}>Verify yourself as a CEGian</p>

              <div className={`${styles.formBox}`}>
                <FormField
                  type={"text"}
                  fieldIcon={rollNoIcon}
                  placeholder="Roll Number"
                  name="roll"
                  value={CEGianVerificationDetails}
                  setter={changeCEGianVerificationDetails}
                />
                <FormField
                  type={"text"}
                  fieldIcon={yearIcon}
                  placeholder="Date of Birth (DD-MM-YYYY)"
                  name="dob"
                  value={CEGianVerificationDetails}
                  setter={changeCEGianVerificationDetails}
                />
              </div>

              <div className="recaptcha_container">
                <ReCAPTCHA
                  sitekey={"6LcMoTUdAAAAAGFo2lgEFl5sIpitgdT-lExG05FL"}
                  theme="dark"
                  size="normal"
                  className="recaptcha"
                  ref={reCaptchaRef}
                />
              </div>

              <div className={`${styles.buttonContainer}`}>
                <GlitchButton text={"Verify"} onClickMethod={clickedVerify} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page_transition>
  );
}

export default VerifyCEGian;
