import React, { useState, useEffect } from "react";
import styles from "./WorkshopPane.module.css";

import { makeStyles } from "@material-ui/core/styles";

import ReactTooltip from "react-tooltip";
import SortMenu from "../../components/SortMenu/SortMenu";
import { EVENT_DATA_MAPPING } from "../../../../utils/eventMapper";
import { apiGetEventRegistrations } from "../../../../api/auth";
import { showErrorToastNotification } from "../../../ToastNotification";

import { useNavigate } from "react-router-dom";
import DataAccordion from "../../components/DataAccordion/DataAccordion";
import { WORKSHOPS } from "../../../../data/Workshops/Workshops";
import Heading from "../../../Heading/Heading";

const useStyles = makeStyles({
  tableData: {
    color: "#FFFFFF",
    fontFamily: "PrimaryFont",
    fontWeight: "600",
    fontSize: "0.9rem",
  },
  status: {
    fontFamily: "PrimaryFont",
    fontSize: "0.9rem",
    padding: "0.25rem 0.75rem",
    fontWeight: "600",
    borderRadius: "0.5rem",
    display: "inline-block",
    textAlign: "center",
  },
  accordian: {
    backgroundColor: "#262626",
    color: "#FFFFFF",
    borderRadius: "0.5rem",
    margin: "0.5rem 0",
  },
  tableLink: {
    margin: "1rem 0",
    fontFamily: "PrimaryFont",
    fontWeight: "600",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
  },
  tableLinkSpan: {
    display: "flex",
    alignItems: "center",
  },
});

export default function WorkshopPane() {
  const navigate = useNavigate();

  const classes = useStyles();
  const [allUserRegistrations, setallUserRegistrations] = useState([]);

  useEffect(async () => {
    const resp = await apiGetEventRegistrations({
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        setallUserRegistrations(resp.data);
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }

    return () => {};
  }, []);

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.headingContainer}`}>
        <Heading text={"Workshops"} />
      </div>

      <div className={`${styles.accordianContainer}`}>
        {WORKSHOPS.map((row, index) => {
          // here
          let status = {
            name: "Not Registered",
            colorFlag: "red",
          };
          let eventRedirect = `/workshops/${row.path}`;

          for (let i = 0; i < allUserRegistrations.length; i++) {
            if (row.eventcode === allUserRegistrations[i].purpose) {
              // Paid
              if (allUserRegistrations[i].status === "Credit") {
                status = {
                  name: "Paid",
                  paymentId: allUserRegistrations[i].paymentid,
                  colorFlag: "green",
                };
              }
              // Only Registered
              else {
                status = {
                  name: "Registered. Yet to pay",
                  colorFlag: "yellow",
                };
              }
              break;
            }
            // Not registered
            else {
              status = {
                name: "Not Registered",
                colorFlag: "red",
              };
            }
          }

          return (
            <DataAccordion
              status={status}
              row={row}
              eventRedirect={eventRedirect}
            />
          );
        })}
        <ReactTooltip />
      </div>
    </div>
  );
}
