import React, { useRef, useEffect } from "react";
import styles from "./description.module.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Description = () => {
  const descRef = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.matchMedia({
      all: function () {
        gsap.from(descRef.current, {
          scrollTrigger: {
            trigger: descRef.current,
            start: "top 90%",
            end: "bottom bottom",
            toggleActions: "play complete reverse none",
          },
          scale: 0.3,
          opacity: 0,
        });
      },
    });
  }, []);
  return (
    <div className={`${styles.description}`} ref={descRef}>
      <p>
        K! Talks is an inspirational talk-series of CEG Tech Forum initiated in
        the year 2020.
      </p>
      <p>
        It is a platform where students get to interact with people who excel in
        various fields. The third edition of K! Talks is planned such that it
        connects students with industry experts even during this pandemic.
      </p>
      <p>
        It is to make sure that every student is benefitted and a wide variety
        of topics are discussed.
      </p>
      <p>
        The main motto of this talk-series is to let the audience hear the
        thoughts that could translate imagination into reality!
      </p>
    </div>
  );
};

export default Description;
