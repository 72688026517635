const localImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Events/paper-presentation/`;
export const PAPER_PRESENTATION_EVENTS = [
  // Paper Presentation
  {
    price: "99",
    eventcode: "PAPERPRESENTATION",
    participantsTypeFlag: "INDIVIDUAL&TEAM",
    participantsDetail: {
      minSize: 1,
      maxSize: 3,
    },
    regOpen: true,
    title: "Paper Presentation",
    path: "paper-presentation",
    filler: localImageBasePath + "pp/filler.jpg",
    oneLiner: "",
    description: [
      "This event is for all the intellectuals out there.",
      "The success of your presentation will not be based on the content you just deliver, but on what the listener receives.",
      "Come, present and make the crowd listen to your words of wisdom.",
      "Every successful presentation is built on four pillars- namely People, Ideas, Passion, and Preparation!",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "An abstract must be prepared by the participants based on the research and applications you have made.",
          "The abstract must be submitted by the 31st of March 2022 to the mail ID paperpresentation@cegtechforum.in",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "The shortlisted participants will be called on the day of the event and will be asked to present their ideas to the crew.",
        ],
      },
    ],
    rules: [
      "A team can have a maximum of 3 members.",
      "Plagiarism will not be entertained and may lead to direct disqualification.",
      "Participants must have a valid K! Id.",
      "Equal participation by all the team members in the presentation.",
      "Participants can be from any college and it is not necessary that all 3 members should be from the same college or same year of study.",
      "The decision of the judge is final.",
    ],
    contact: [
      {
        name: "Anitha B",
        mobile: "88707 92902",
      },
      {
        name: "Vaishnavi S",
        mobile: "94446 56713",
      },
    ],
    mail: "paperpresentation@cegtechforum.in",
    dateTimeFees: {
      date: "April 8, 2022",
    },
    abstractPDF: `${process.env.PUBLIC_URL}/assets/images/Events/paper-presentation/pp/abstract.pdf`,
  },
];
