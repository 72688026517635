export const CONTACTDETAILS = [
  {
    name: "Praveen VS",
    mobile: "+91 98652 88018",
  },
  {
    name: "Monika V",
    mobile: "+91 88705 04862",
  },
];

const socialIconBasePath = `${process.env.PUBLIC_URL}/assets/images/ContactUs/`;
export const SOCIALMEDIA = [
  {
    network: "Email",
    icon: socialIconBasePath + "email.png",
    url: "mailto:hr@cegtechforum.in",
  },
  {
    network: "Facebook",
    icon: socialIconBasePath + "facebook.png",
    url: "https://www.facebook.com/techforum.ceg",
  },
  {
    network: "Instagram",
    icon: socialIconBasePath + "instagram.png",
    url: "https://www.instagram.com/ceg_tech_forum/",
  },
  {
    network: "LinkedIn",
    icon: socialIconBasePath + "linked_in.png",
    url: "https://www.linkedin.com/company/ceg-tech-forum/mycompany/",
  },
  {
    network: "Twitter",
    icon: socialIconBasePath + "twitter.png",
    url: "https://twitter.com/TechCeg",
  },
];

export const CEG_TECH_FORM_URL = "https://cegtechforum.in/";

export const CONTACT_FORM_FIELDS = [
  {
    type: "text",
    heading: "Full Name",
    name: "name",
  },
  {
    type: "text",
    heading: "Email Address",
    name: "email",
  },
  {
    type: "textarea",
    heading: "Message",
    name: "message",
  },
];
