export const teamImageBasePath = `${process.env.PUBLIC_URL}/assets/images/CreativeTeam/`;
export const domainIconBasePath = `${process.env.PUBLIC_URL}/assets/images/Domains/`;
export const DOMAINS = [
  {
    name: "All",
    icon: domainIconBasePath + "all.png",
  },
  {
    name: "Content",
    icon: domainIconBasePath + "contents-01.svg",
    email: "content@cegtechforum.in",
  },
  {
    name: "Design",
    icon: domainIconBasePath + "design-01.svg",
    email: "design@cegtechforum.in",
  },
  {
    name: "Events",
    icon: domainIconBasePath + "events.svg",
    email: "events@cegtechforum.in",
  },
  {
    name: "Finance",
    icon: domainIconBasePath + "finance-01.svg",
    email: "finance@cegtechforum.in",
  },
  {
    name: "Guest Lectures",
    icon: domainIconBasePath + "gl-01.svg",
    email: "guestlectures@cegtechforum.in",
  },
  {
    name: "Human Resources",
    icon: domainIconBasePath + "hr-01.svg",
    email: "hr@cegtechforum.in",
  },
  {
    name: "Hospitality",
    icon: domainIconBasePath + "hospitality-01.svg",
    email: "hospitality@cegtechforum.in",
  },
  {
    name: "Initiatives",
    icon: domainIconBasePath + "initiative-01.svg",
    email: "initiatives@kurukshetra.org.in",
  },
  {
    name: "Internal Auditing and Alumni Relations",
    icon: domainIconBasePath + "marketing-01.svg",
    email: "internalaudit@kurukshetra.org.in",
  },
  {
    name: "Industry Relations",
    icon: domainIconBasePath + "ir-01.svg",
    email: "industryrelations@cegtechforum.in",
  },
  {
    name: "Logistics",
    icon: domainIconBasePath + "logistics-01.svg",
    email: "logistics@cegtechforum.in",
  },
  {
    name: "Marketing and Media",
    icon: domainIconBasePath + "media-01.svg",
    email: "marketing@cegtechforum.in",
  },
  {
    name: "Quality Assurance and Control",
    icon: domainIconBasePath + "qac.svg",
    email: "qac@cegtechforum.in",
  },
  {
    name: "Tech",
    icon: domainIconBasePath + "tech-01.svg",
    email: "techops@cegtechforum.in",
  },
  {
    name: "Workshops",
    icon: domainIconBasePath + "workshop-01.svg",
    email: "workshop@cegtechforum.in",
  },
  {
    name: "XnK",
    icon: domainIconBasePath + "exceed-01.svg",
    email: "xceed@cegtechforum.in",
  },
  {
    name: "Projects",
    icon: domainIconBasePath + "projects.svg",
    email: "projects@cegtechforum.in",
  },
];

export const CREATIVE_TEAM = [
  {
    name: "Abdullah Shahul Hameed",
    image: "Abdullah_S.jpg",
    squareImage: "Abdullah_S_square.jpg",
    mobile: "+91 98407 39702",
    linkedIn: "https://www.linkedin.com/in/abdullah-shahul-hameed-5a7b131a7",
    domain: "Events",
    quote: "I keep the competitiveness up and party going."
  },
  {
    name: "Abitha P",
    image: "Abitha_P.jpg",
    squareImage: "Abitha_P_square.jpg",
    mobile: "+91 94456 23236",
    linkedIn: "https://www.linkedin.com/in/abitha-perumal-9a6155173/",
    domain: "Internal Auditing and Alumni Relations",
    quote: "Giving up is always an option, but it's never my choice."
  },
  {
    name: "Anushri",
    image: "Anushri.jpeg",
    squareImage: "Anushri_square.jpeg",
    mobile: "+91 99623 60062",
    linkedIn: "https://www.linkedin.com/in/anushri-eswaran/",
    domain: "Industry Relations",
    quote: "Would I rather be feared or loved? Easy. Both. I want people to be afraid of how much they love me - Michael Scott"
  },
  {
    name: "Aravintakshan R K",
    image: "Aravintakshan_RK.jpg",
    squareImage: "Aravintakshan_RK_square.jpg",
    mobile: "+91 91764 71473",
    linkedIn: "https://www.linkedin.com/in/aravintakshan-r-k-614b7a223",
    domain: "Marketing and Media",
    quote: "I can sell pens."
  },
  {
    name: "Archana B",
    image: "Archana_B.jpeg",
    squareImage: "Archana_B_square.jpg",
    mobile: "+91 63852 21777",
    linkedIn: "https://www.linkedin.com/in/archana-balasubramanian-434b301b7/",
    domain: "Hospitality",
    quote: "The greatest glory in living lies not in never falling, but in rising every time we fall."
  },
  {
    name: "Deepthi V",
    image: "Deepthi_V.jpeg",
    squareImage: "Deepthi_V_square.jpeg",
    mobile: "+91 94897 18955",
    linkedIn: "https://www.linkedin.com/in/deepthi-v-970167167",
    domain: "Initiatives",
    quote: "Nothing, and everything, is possimpible."
  },
  {
    name: "Diviya Seshani",
    image: "Diviya_Seshani_Kumanan.jpg",
    squareImage: "Diviya_Seshani_Kumanan_square.jpg",
    mobile: "+91 86087 06034",
    linkedIn: "https://www.linkedin.com/in/diviya-seshani-kumanan-046906129/",
    domain: "Internal Auditing and Alumni Relations",
    quote: "Explore everything you like - when you can! But make sure you don't doubt - what you can !"
  },
  {
    name: "Frederick Jeba Samuel J",
    image: "Frederick_Jeba_Samuel_J.jpg",
    squareImage: "Frederick_Jeba_Samuel_J_square.jpg",
    mobile: "+91 89039 30082",
    linkedIn: "https://www.linkedin.com/in/frederick-jeba-samuel-j-1292961b2",
    domain: "Finance",
    quote: "Money! Money!! Money!!!"
  },
  {
    name: "Harini K",
    image: "Harini_K.jpeg",
    squareImage: "Harini_K_square.jpeg",
    mobile: "+91 70920 28561",
    linkedIn: "https://www.linkedin.com/in/harini-kandasamy-323252194/",
    domain: "Projects",
    quote: "Born to fight hurdles"
  },
  {
    name: "Harini V K",
    image: "Harini_V_K.jpg",
    squareImage: "Harini_V_K_square.jpg",
    mobile: "+91 75503 72274",
    linkedIn: "https://www.linkedin.com/in/harinikesavan/",
    domain: "Marketing and Media",
    quote: "Love the life you live"
  },
  {
    name: "Jacob Biju",
    image: "Jacob_Biju.jpg",
    squareImage: "Jacob_Biju_square.jpg",
    mobile: "+91 63742 88894",
    linkedIn: "https://www.linkedin.com/in/jacob-biju-424815175/",
    domain: "Workshops",
    quote: "Work Hard. Play Harder!"
  },
  {
    name: "Jananeswari R",
    image: "Jananeswari_R.jpeg",
    squareImage: "Jananeswari_R_square.jpeg",
    mobile: "+91 89390 17588",
    linkedIn: "https://www.linkedin.com/in/jananeswari-r-98a761210",
    domain: "Workshops",
    quote: "Learn, Cherish and Accept every part of our journey."
  },
  {
    name: "Kavia M",
    image: "Kavia_M.jpeg",
    squareImage: "Kavia_M_square.jpeg",
    mobile: "+91 97917 09264",
    linkedIn: "https://www.linkedin.com/in/kavia-mariappan/",
    domain: "Tech",
    quote: "Whatever I don't know today doesn't mean I will never know it."
  },
  {
    name: "Kishore Naarayan S",
    image: "Kishore_Naarayan_S.JPG",
    squareImage: "Kishore_Naarayan_S_square.JPG",
    mobile: "+91 73584 84426",
    linkedIn: "https://www.linkedin.com/in/kishore-siva-41861b17b",
    domain: "Events",
    quote: "This Year, I met the most broken version of me, but also the Strongest."
  },
  {
    name: "Madhumitha R",
    image: "Madhumitha_Raju.jpeg",
    squareImage: "Madhumitha_Raju_square.jpeg",
    mobile: "+91 94444 57634",
    linkedIn: "https://www.linkedin.com/in/madhumitha-raju-5883b720a/",
    domain: "Finance",
    quote: "Kaasu. Panam. Dhutthu. Money."
  },
  {
    name: "Monika V",
    image: "Monika_V.jpg",
    squareImage: "Monika_V_square.jpg",
    mobile: "+91 88705 04862",
    linkedIn: "https://www.linkedin.com/in/monika-venkatesan-6ba73417a",
    domain: "Human Resources",
    quote: "Being Positive in a negative situation"
  },
  {
    name: "Murugan A",
    image: "Murugan_A.jpg",
    squareImage: "Murugan_A_square.png",
    mobile: "+91 81908 20472",
    linkedIn: "https://www.linkedin.com/in/murugan2000",
    domain: "Design",
    quote: "I am enough of an artist to draw freely upon my imagination."
  },
  {
    name: "Paargav Shanker Su",
    image: "Paargav_Shanker_Su.JPG",
    squareImage: "Paargav_Shanker_Su_square.JPG",
    mobile: "+91 98948 97631",
    linkedIn: "https://www.linkedin.com/in/paargav-shanker/",
    domain: "Tech",
    quote: "That’s what I do: I code and I know things :)"
  },
  {
    name: "Prasith Basky M",
    image: "Prasith_Basky_M.jpg",
    squareImage: "Prasith_Basky_M_square.jpg",
    mobile: "+91 96299 59188",
    linkedIn: "https://www.linkedin.com/in/prasith-basky-a20338161/",
    domain: "Logistics",
    quote: "Inhale the Future, Exhale the Past"
  },
  {
    name: "Praveen VS",
    image: "Praveen_V_S.png",
    squareImage: "Praveen_V_S_square.jpeg",
    mobile: "+91 98652 88018",
    linkedIn: "https://www.linkedin.com/in/praveen-vs-327438150/",
    domain: "Human Resources",
    quote: "I keep things up and running."
  },
  {
    name: "Raghul VS",
    image: "Ragul_VS.jpg",
    squareImage: "Ragul_VS_square.jpg",
    mobile: "+91 63817 69074",
    linkedIn: "https://www.linkedin.com/in/ragul-v-s-b402721a7",
    domain: "Logistics",
    quote: "Be real with me. I respect honesty."
  },
  {
    name: "Rajakumaran P",
    image: "Rajakumaran_P.JPG",
    squareImage: "Rajakumaran_P_square.JPG",
    mobile: "+91 89393 34738",
    linkedIn: "https://www.linkedin.com/in/raja-kumaran-p-9782b8225",
    domain: "Quality Assurance and Control",
    quote: "I don't find a happy life, I make it :)"
  },
  {
    name: "Rajalakshmi M",
    image: "Rajalakshmi_Maheshwaran.jpeg",
    squareImage: "Rajalakshmi_Maheshwaran_square.jpeg",
    mobile: "+91 95976 87704",
    linkedIn: "https://www.linkedin.com/in/thisis-raji",
    domain: "Content",
    quote: "There’s this haunted house nearby with haunted items and they have a sign like “if you touch it you will have bad luck forever” and I cannot wait to touch it."
  },
  {
    name: "Rushikesh D",
    image: "Rushikesh_D.jpg",
    squareImage: "Rushikesh_D_square.jpg",
    mobile: "+91 76038 36366",
    linkedIn: "https://www.linkedin.com/in/rushikesh-deivamani-222bab207",
    domain: "Projects",
    quote: "Intelligence is the ability to adapt to change."
  },
  {
    name: "Senbaga Saranya B",
    image: "Senbaga_Saranya_B.jpg",
    squareImage: "Senbaga_Saranya_B_square.jpg",
    mobile: "+91 81909 68696",
    linkedIn: "https://www.linkedin.com/in/senbaga-saranya-b-0747b1201",
    domain: "Design",
    quote: "Enjoy life :) that's what we're here for"
  },
  {
    name: "Shibu SL",
    image: "Shibu_S_L.jpeg",
    squareImage: "Shibu_S_L_square.jpeg",
    mobile: "+91 95977 03637",
    linkedIn: "https://www.linkedin.com/in/shibu-s-l-43515b191",
    domain: "Events",
    quote: "Naturally introverted. Selectedly extroverted. But good and funny talks are always interested;)!!!"
  },
  {
    name: "Shruthi Sundar",
    image: "Shruthi_S.JPG",
    squareImage: "Shruthi_S_square.JPG",
    mobile: "+91 73583 25784",
    linkedIn: "https://www.linkedin.com/in/shruthisundar1812",
    domain: "Quality Assurance and Control",
    quote: "I believe in being strong when everything seems to go wrong."
  },
  {
    name: "Siva K",
    image: "Siva_K.jpg",
    squareImage: "Siva_K_square.jpg",
    mobile: "+91 63851 14512",
    linkedIn: "https://www.linkedin.com/in/siva-k-696762209",
    domain: "Hospitality",
    quote: "What defines me is what I do"
  },
  {
    name: "Soumya S",
    image: "Soumya_S.jpeg",
    squareImage: "Soumya_S_square.jpeg",
    mobile: "+91 94988 21520",
    linkedIn: "https://www.linkedin.com/in/soumya-senthil-1002",
    domain: "XnK",
    quote: "She is powerful! not because she isn't scared, but because she continues despite the fear"
  },
  {
    name: "Sri Hari G C",
    image: "Sri_Hari_GC.jpeg",
    squareImage: "Sri_Hari_GC_square.jpeg",
    mobile: "+91 98405 41098",
    linkedIn: "https://www.linkedin.com/in/sri-hari-g-c-a7ab94204/",
    domain: "Design",
    quote: "Fierce and Fearless"
  },
  {
    name: "Srinath Sureshkumar",
    image: "Srinath_Sureshkumar.JPG",
    squareImage: "Srinath_Sureshkumar_square.JPG",
    mobile: "+91 78260 81980",
    linkedIn: "https://www.linkedin.com/in/srinathsureshkumar",
    domain: "Tech",
    quote: "I own this place and keep the gates."
  },
  {
    name: "Subash Bhaskar",
    image: "Subash_Baskar.JPG",
    squareImage: "Subash_Baskar_square.JPG",
    mobile: "+91 63838 27163",
    linkedIn: "https://www.linkedin.com/in/subash-baskar-51b970180",
    domain: "Initiatives",
    quote: "Work hard in silence let success make the noise"
  },
  {
    name: " Vaishnavi R",
    image: "Vaishnavi_R.jpg",
    squareImage: "Vaishnavi_R_square.jpg",
    mobile: "+91 90879 15558",
    linkedIn: "https://www.linkedin.com/in/vaishnavi-r-53ba201bb",
    domain: "Guest Lectures",
    quote: "I keep the spotlights shining brighter and brighter."
  },
  {
    name: "Varshini E",
    image: "Varshini_E.jpeg",
    squareImage: "Varshini_E_square.jpg",
    mobile: "+91 99627 68298",
    linkedIn: "https://www.linkedin.com/in/varshini-elangovan-9699421b2/",
    domain: "Industry Relations",
    quote: "Take that leap of faith :)"
  },
  {
    name: "Veniniyan V",
    image: "Veniniyan_V.jpg",
    squareImage: "Veniniyan_V_square.jpg",
    mobile: "+91 82487 25604",
    linkedIn: "https://www.linkedin.com/in/veniniyanv",
    domain: "Projects",
    quote: "Sometimes it is the very people who no one imagines anything of, are the ones who do the things no one can imagine!!"
  },
  {
    name: "Vignesh Kumar Murugavel",
    image: "Vignesh_Kumar_Murugavel.jpg",
    squareImage: "Vignesh_Kumar_Murugavel_square.jpg",
    mobile: "+91 97909 52568",
    linkedIn: "https://www.linkedin.com/in/vignesh-kumar-murugavel-6b8395184/",
    domain: "XnK",
    quote: "You may not always be able to control a situation or the outcome, but you can always control the way it affects you and your response to it in order to find a way to deal with anything."
  },
  {
    name: "Vigneshwaran N",
    image: "Vignesh_Waran_Nagarajan.jpg",
    squareImage: "Vignesh_Waran_Nagarajan_square.jpg",
    mobile: "+91 97890 22747",
    linkedIn: "https://www.linkedin.com/in/vigneshwaran-nagarajan-162508223",
    domain: "Marketing and Media",
    quote: "தயங்கிட தயங்கு"
  },
  {
    name: "Vikash Kumar S",
    image: "Vikash_Kumar_S.jpeg",
    squareImage: "Vikash_Kumar_S_square.jpeg",
    mobile: "+91 81247 88778",
    linkedIn: "https://www.linkedin.com/in/vikashkumar001",
    domain: "Design",
    quote: "Throughout the centuries there were men who took first steps, down new roads, armed with nothing but their own vision. -Ayn Rand"
  },
];
