import { EVENT_TYPES } from "../../../../data/Events/EventTypes";
import styles from "./SortMenu.module.css";
import { useEffect, useRef } from "react";

export default function SortMenu(props) {
  let sortMenuRefs = new Array(EVENT_TYPES.length);

  useEffect(() => {
    sortMenuRefs[0].classList.add(`colorPaletteGradientBackground`);
    sortMenuRefs[0].classList.remove(`${styles.itemContainerOff}`);
    sortMenuRefs[0].children[0].classList.remove(
      `colorPaletteGradientBackground`
    );

    return () => {};
  }, []);

  const clickedEventCategory = (newEventCategory, index) => {
    if (newEventCategory === props.option.title) return;

    // For new domain
    sortMenuRefs[index].classList.add(`colorPaletteGradientBackground`);
    sortMenuRefs[index].classList.remove(`${styles.itemContainerOff}`);
    // sortMenuRefs[index].children[0].classList.remove(
    //   `colorPaletteGradientBackground`
    // );

    // For current/old domain
    sortMenuRefs[props.option.index].classList.remove(
      `colorPaletteGradientBackground`
    );
    sortMenuRefs[props.option.index].classList.add(
      `${styles.itemContainerOff}`
    );
    // sortMenuRefs[props.option.index].children[0].classList.add(
    //   `colorPaletteGradientBackground`
    // );

    props.changeOption({
      title: newEventCategory,
      index: index,
    });
  };

  return (
    <div className={`${styles.menuContainer}`}>
      {EVENT_TYPES.map((category, index) => {
        return (
          <div
            ref={(el) => (sortMenuRefs[index] = el)}
            key={index}
            className={`${styles.itemContainer} ${styles.itemContainerOff}`}
            onClick={() => {
              clickedEventCategory(category.url, index);
            }}
          >
            {/* {
                                category.image !== null && <img
                                    src={category.image}
                                    alt={category.name}
                                    className={`colorPaletteGradientBackground`}
                                />
                            } */}
            <span>{category.name}</span>
          </div>
        );
      })}
    </div>
  );
}
