import "./App.css";
import "./fonts.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useState, createContext, useEffect, useRef } from "react";

//Page Transition
import { AnimatePresence } from "framer-motion";

// Components
import Navbar from "./components/Navbar/Navbar";

// Pages
import Landing from "./pages/Landing/Landing";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import AboutUs from "./pages/AboutUs/AboutUs";

// Themes
import styled, { ThemeProvider } from "styled-components";
import { darkTheme, GlobalStyles } from "./themes";
import Template from "./components/EventTemplate/Template";
import Login from "./pages/LoginRegistration/Login";
import Registration from "./pages/LoginRegistration/Registration";
import RegistrationDetails from "./pages/LoginRegistration/RegistrationDetails";
import ForgotPassword from "./pages/LoginRegistration/ForgotPassword";
import ResetPassword from "./pages/LoginRegistration/ResetPassword";
import Events from "./pages/Events/Events";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import CreativeTeam from "./pages/CreativeTeam/CreativeTeam";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import { isAuthDataStored } from "./utils/localStorageHelper";
import UnAuthOnlyRoutes from "./UnAuthOnlyRoutes";
import ListEvents from "./pages/Events/ListEvents";
import Workshops from "./pages/Workshops/Workshops";
import EventTemplateMiddleware from "./pages/Events/EventTemplateMiddleware";
import WorkshopTemplateMiddleware from "./pages/Workshops/WorkshopTemplateMiddleware";
import Projects from "./pages/Projects/Projects";
import GuestLectures from "./pages/GuestLectures/GuestLectures";

import PageLoader from "./components/PageLoader/PageLoader";
import ScrollToTop from "./utils/ScrollToTop";
import Sponsor from "./pages/Sponsors/Sponsors";
import CEGRegistrationDetails from "./pages/LoginRegistration/CEGRegistrationDetails";
import Contact from "./pages/Contact/Contact";
import VerifyCEGian from "./pages/LoginRegistration/VerifyCEGian";
import Technovation from "./pages/Projects/Technovation/Technovation";
import EventListMenu from "./components/EventListMenu/EventListMenu";
import EventListDialog from "./components/EventListDialog/EventListDialog";
import Accomodation from "./pages/Accomodation/Accomodation";
import KMag from "./pages/KMag/KMag";

const StyledApp = styled.div``;

// Contexts
export const Width = createContext();
export const Auth = createContext();
export const SetAuth = createContext();
export const LoggedInUserName = createContext();
export const SetLoggedInUserName = createContext();
export const ToggleEventListDialog = createContext();
export const ToggleEventListMenuDialog = createContext();

toast.configure();
Modal.setAppElement("#root");
function App() {
  const [width, setwidth] = useState(0);
  const [auth, setauth] = useState(false);
  const [loggedInUserName, setloggedInUserName] = useState("");
  const [isEventListOpen, setisEventListOpen] = useState(false);
  const [isEventListMenuOpen, setisEventListMenuOpen] = useState(false);

  useEffect(() => {
    if (isAuthDataStored()) {
      setauth(true);
    } else {
      setauth(false);
    }

    return () => { };
  }, []);

  // Get window dimensions on resize
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setwidth(window.innerWidth);
  };

  const toggleEventListDialog = (action) => {
    setisEventListOpen(action);
  };

  const toggleEventListMenuDialog = (action) => {
    setisEventListMenuOpen(action);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles />
      <StyledApp>
        <Width.Provider value={width}>
          <Auth.Provider value={auth}>
            <SetAuth.Provider value={setauth}>
              <LoggedInUserName.Provider value={loggedInUserName}>
                <SetLoggedInUserName.Provider value={setloggedInUserName}>
                  <ToggleEventListDialog.Provider value={toggleEventListDialog}>
                    <ToggleEventListMenuDialog.Provider
                      value={toggleEventListMenuDialog}
                    >
                      <div className="App">
                        <Router>
                          <ScrollToTop />
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/Logos/kurukshetra_logo_theme_sponsor.png`}
                            className="kBrandLogo"
                          />
                          <Navbar />
                          <AllRoutes auth={auth} />
                          {isEventListMenuOpen && (
                            <EventListMenu
                              onClickMethod={toggleEventListDialog}
                            />
                          )}
                          <EventListDialog
                            isOpen={isEventListOpen}
                            toggleDialog={toggleEventListDialog}
                          />
                        </Router>
                      </div>
                    </ToggleEventListMenuDialog.Provider>
                  </ToggleEventListDialog.Provider>
                </SetLoggedInUserName.Provider>
              </LoggedInUserName.Provider>
            </SetAuth.Provider>
          </Auth.Provider>
        </Width.Provider>
      </StyledApp>
    </ThemeProvider>
  );
}

const AllRoutes = ({ auth }) => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Routes location={location} key={location.pathname} initial={false}>
        <Route path="/" element={<Landing />} />

        <Route element={<UnAuthOnlyRoutes auth={auth} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route
            path="/registration-details"
            element={<RegistrationDetails />}
          />
          {/* <Route
            path="/ceg-registration"
            element={<CEGRegistrationDetails />}
          /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route path="/about-us" exact element={<AboutUs />} />

        <Route path="/events" element={<Events />} />
        <Route path="/events/:category" element={<ListEvents />} />
        <Route
          path="/events/:category/:title"
          element={<EventTemplateMiddleware />}
        />

        <Route path="/workshops" element={<Workshops />} />
        <Route
          path="/workshops/:title"
          element={<WorkshopTemplateMiddleware />}
        />

        <Route
          path="/projects/technovation"
          exact
          element={<Technovation />}
        />
        <Route path="/accomodation" exact element={<Accomodation />} />
        <Route element={<ProtectedRoute auth={auth} />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/verify-cegian" element={<VerifyCEGian />} />
        </Route>

        <Route path="/projects" exact element={<Projects />} />

        <Route path="/ktalks" exact element={<GuestLectures />} />
        <Route path="/team" element={<CreativeTeam />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sponsors" element={<Sponsor />} />
        <Route path="/kmag" element={<KMag />} />
        <Route path="/page-loader" exact element={<PageLoader />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default App;
