export const projectsImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Projects/`;

export const PROJECTS = [
  {
    title: "3D Virtual Map",
    oneLiner: "An interactive application that depicts the beauty of CEG",
    description:
      "An interactive application that depicts the beauty of CEG, the incredible design of various buildings, the atmosphere generated by  natural surroundings and the diversity of additional amenities available on campus. The user can take a virtual tour of the campus and also find the shortest route between various areas. With the use of a toggle, the user can also wander manually through the map, exploring the various locations at their own pace.",
    images: ["1.jpg"],
    folderName: "3D_Virtual_Map/",
  },
  {
    title: "CEG App",
    oneLiner:
      "A social media platform especially for CEGians to get to know about the events, technical and non-technical fests happening on our campus",
    description:
      "CEG App is a social media platform especially for CEG students to get to know about the events, technical and non-technical fests happening on our campus. This is an app where each CEG student can have their yet-to-be cost-efficient account to follow various clubs, departments, and organizations of our campus and to get instant notification about the events. This app will also feature important notifications from the college administration so that students will not miss out on any important notice from the college. Every page will have a forum where interactions between the student and the respective page admin can also take place.",
    images: ["1.png"],
    folderName: "CEG_App/",
  },
  {
    title: "E-Transmission",
    oneLiner: "An advanced non-contact type electromagnetic transmission",
    description:
      "E-transmission is an advanced non-contact type electromagnetic transmission. This couples the engine to the gearbox electromagnetically and allows engine speed to vary independently of the vehicle's speed and it also reduces the number of gears required. It ensures a smooth power delivery to the wheel despite the varying road conditions and speed of the engine. It occupies less space than conventional ones, it also increases the life of the car and ease of driving the car due to the elimination of gears and clutches and hence the car almost has the characteristics similar to an electric car but has the range and reliability of a normal engine car. The efficiency of transmission of power from the engine to the road increases and also life of the engine increases due to reduced fatigue loading.",
    images: ["1.png", "2.png"],
    folderName: "E_Transmission/",
  },
  {
    title: "Intelligent Line Marking Bot",
    oneLiner:
      "An autonomous wheeled mobile robot that is modeled and designed to mark the lines of sports fields without any human intervention",
    description:
      "iLMBt (intelligent Line Marking Bot) is an autonomous wheeled mobile robot that is modeled and designed to mark the lines of sports fields without any human intervention. A hopper mechanism is built in to control the flow of powder, which is controlled by a motorized lead screw. An effective dampening system is designed for the smooth operation of the robot. With the given coordinates, iLMBt calculates the desired path and the required line is marked. A collision avoidance program is included with the path tracking algorithm to smoothly maneuver around obstacles while reaching the required starting point. iLMBt eliminates the human labor in marking sports fields and also improves the accuracy of the lines.",
    images: ["1.png"],
    folderName: "iLMBT/",
  },
  {
    title: "Intelligent Braking System",
    oneLiner:
      "The project deals with the adjustment of balance bar position based on the input of the accelerometer and manipulation of the Arduino board.",
    description:
      "In an automobile, the braking system forms an integral part and accounts for the safety of the passengers. Most of the common failures of any vehicle occur in its braking system and the major cause would be poor implementation of load transfer in the braking system. Based on the dynamic load transfer for varying velocities, the braking force is distributed to the front and rear master cylinders with the help of balance bars fixed at a certain position. The project deals with the adjustment of balance bar position based on the input of the accelerometer and manipulation of the Arduino board.",
    images: ["1.jpg", "2.png"],
    folderName: "Intelligent_Braking_System/",
  },
  {
    title: "Machine Hole Scavenging Bot",
    oneLiner:
      "A manhole cleaning Robot was designed under the inspiration of Safai Mitra Suraksha Challenge to avoid manual scavenging deaths",
    description:
      "Machine Hole Scavenging Bot, a manhole cleaning Robot is designed under the inspiration of Safai Mitra Suraksha Challenge to avoid manual scavenging deaths. The robot consists of 2 major units Standing and Drone, one for stability and mobility and the other for inspection and cleaning respectively. With the consideration of flaws in the existing solutions and their demand for manual intervention, MHSB provides zero manual intervention along with our rover unit for horizontal sewer lines thus preventing the deaths of manual scavengers due to poisonous gasses. This bot thus raises the standard of sanitation with the transformation of manual scavengers to machine operators without unemploying them.",
    images: ["1.png"],
    folderName: "Machine_Hole_Scavenging_Bot/",
  },
  {
    title: "Notify",
    oneLiner:
      "A cloud-based app that facilitates the users to manage the schedule of a group of people",
    description:
      "Notify is a cloud-based app that facilitates the users to manage the schedule of a group of people. It serves as a medium through which various information related to academics can be shared. This app will be available for a wide range of students school students where there will be a fixed set of staff for each class.",
    images: ["1.jpg"],
    folderName: "Notify/",
  },
  {
    title: "Protow Bot",
    oneLiner:
      "The next generation multi-utility bot which tows a wide range of automotive",
    description:
      "Many a time we find ourselves stranded in traffic, due to a car break down or a punctured tire. Rather than waiting for a towing vehicle to arrive, ProTow Bot is an effective solution. We have designed the next generation multi-utility Bot which tows a wide range of automotive. The cost associated with the driving of an aircraft from the runway to the Hangar is drastically reduced, using this bot. With a modular clamping system, our bot can not only be used to tow vehicles, but also facilitates moving goods and heavy packages in warehouses. The bot is designed for seamless operation and can be controlled using an App or remote control.",
    images: ["1.jpg"],
    folderName: "Protow_Bot/",
  },
  {
    title: "Rain-Sensing Shelter",
    oneLiner:
      "An automatic expandable and retractable shelter that protects clothes from sudden rain and highly humid environments",
    description:
      "Introducing an automatic expandable and retractable shelter that protects clothes from sudden rain and highly humid environments. Thereby, this shelter will be very useful to many working adults and students who are facing difficulties in drying their clothes, amidst their work, in today's frequently changing weather conditions. Our future goal is to implement this idea in agricultural fields to protect crops from rain.",
    images: ["1.jpg", "2.jpg"],
    folderName: "Rain_Sensing_Shelter/",
  },
  {
    title: "Voice Controlled Robotic Vehicle",
    oneLiner:
      "This project helps to control a robot through voice commands received through the android application",
    description:
      "The Voice Controlled Robotic Vehicle helps to control a robot through voice commands received through the android application. The integration of the control unit with a Bluetooth device is done to capture and read the voice commands. The robotic vehicle then operates as per the command received. A microcontroller is integrated into the system which makes it possible to operate the vehicle via an android application. The controlling device may be any android based smartphone/tab having an android OS. The android controlling system provides a good interactive GUI that makes it easy for the user to control the vehicle. The android device sends commands to move the vehicle in forward, backward, right and left directions.",
    images: ["1.jpg"],
    folderName: "Voice_Controlled_Robotic_Vehicle/",
  },
  {
    title: "Waste Skimmer Bot",
    oneLiner:
      "A semi-automated robot designed to remove/skim the wastes/weeds on the surface of still water bodies",
    description:
      "WaSkiBo (Waste Skimmer Bot) is a semi-automated robot designed to remove/skim the wastes/weeds on the surface of still water bodies. It is electrically powered and incorporated with solar cell technology. The collected wastes (using a conveyor mechanism) are packaged into reusable bags and released on the water surface to be retrieved later. The key objective is to reduce the area over which wastes are spread to a relatively smaller area, ensuring effortless removal. We aim to bring back our water bodies, in a cost-efficient and eco-friendly manner.",
    images: ["1.png"],
    folderName: "Waste_Skimmer_Bot/",
  },
  {
    title: "XII(th) Man",
    oneLiner:
      "A cricket bowling machine, which enables a batsman to practice and to hone specific skills",
    description:
      "The XII(th)MAN is a cricket bowling machine, which enables a batsman to practice and to hone specific skills through balls being bowled at the desired length, line and speed. In the process of blending engineering into the sport, we came up with the project of cricket bowling machine which uses no electrical source and could perform all the features (different speeds up to 140 kmph, lengths, lines) of a highly equipped electric bowler which cuts down the cost rate considerably by 10-20%, targeting the small-scale coaching institutes and colleges owing to our machine’s reliability and its cost.",
    images: ["1.jpg"],
    folderName: "XIIth_Man/",
  },
];
