import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Technovation.module.css";
import Heading from "../../../components/Heading/Heading";
import SimpleLoader from "../../../components/SimpleLoader/SimpleLoader";
import FormField from "../../../components/FormField/FormField";
import teamNameIcon from "../../../assets/images/LoginRegistration/teamName.png";
import teamSizeIcon from "../../../assets/images/LoginRegistration/department.png";
import nameIcon from "../../../assets/images/LoginRegistration/name.png";
import departmentIcon from "../../../assets/images/LoginRegistration/department.png";
import yearIcon from "../../../assets/images/LoginRegistration/year.png";
import {
  ABSTRACT_PDF_EXTENSIONS,
  DESCRIPTION,
  PROJECT_IMAGE_EXTENSIONS,
  RULES,
  TEAM_SIZES,
} from "../../../data/Technovation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ACADEMIC_YEARS } from "../../../data/FormData";
import GlitchButton from "../../../components/GlitchButton/GlitchButton";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from "../../../components/ToastNotification";
import DeleteIcon from "@mui/icons-material/Delete";
import { validateTechnovationForm } from "../../../validators/technovationFormValidator";
import {
  apiGetEventRegistrations,
  apiTechnovationRegistration,
  apiPay,
} from "../../../api/auth";
import Page_transition from "../../../components/Animation/Transition";
import { Auth } from "../../../App";

const teamDetailsFormat = {
  teamName: "",
  teamCount: 3,
};

const userDetailsFormat = {
  name: "",
  dept: "",
  year: 1,
};

const teamMemberDetailsFormat = {
  ...userDetailsFormat,
  isFormOpen: true,
};

const projectDetailsFormat = {
  title: "",
};

function Technovation() {
  const auth = useContext(Auth);

  const navigate = useNavigate();
  const [teamDetails, setteamDetails] = useState(teamDetailsFormat);
  const [teamMemberDetails, setteamMemberDetails] = useState([]);
  const [projectImages, setprojectImages] = useState([]);
  const [abstractPDF, setabstractPDF] = useState(null);
  const [loader, setloader] = useState(false);

  const [regStatus, setregStatus] = useState(false);
  const [payStatus, setpayStatus] = useState(false);

  useEffect(() => {
    let _teamMemberDetails = [];
    _teamMemberDetails.push({
      name: "",
      dept: "",
      year: 1,
      isFormOpen: true,
    });
    _teamMemberDetails.push({
      name: "",
      dept: "",
      year: 1,
      isFormOpen: false,
    });
    setteamMemberDetails(_teamMemberDetails);

    return () => {};
  }, []);

  const fetchRegistrationsDetails = async () => {
    let config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };

    let resp = await apiGetEventRegistrations(config);

    if (
      (resp.status >= 400 && resp.status < 500) ||
      (resp.status >= 500 && resp.status < 600)
    ) {
      showErrorToastNotification(<p>{resp.data.message}</p>);
      navigate("/");
      return;
    }

    for (let i = 0; i < resp.data.length; i++) {
      let event = resp.data[i];

      // Registered
      if (event.purpose === "TECHNOVATION") {
        setregStatus(true);
        // Paid
        if (event.paymentid === "Credit") {
          setpayStatus(true);
        }
        // Not Paid
        else {
          setpayStatus(false);
        }
        break;
      }
    }
  };

  // useEffect(() => {
  //   fetchRegistrationsDetails();

  //   return () => {};
  // }, []);

  const changeMembers = (prevState) => {
    let currentTeamCount = parseInt(prevState.teamCount) - 1;
    let membersCount = teamMemberDetails.length;
    let _teamMemberDetails = teamMemberDetails;

    // If prevCount same as newCount, return
    if (currentTeamCount === membersCount) return;

    // If newCount < prevCount
    if (currentTeamCount < membersCount) {
      while (membersCount !== currentTeamCount) {
        _teamMemberDetails.pop();
        membersCount--;
      }
    }
    // If newCount > prevCount
    else {
      while (membersCount !== currentTeamCount) {
        _teamMemberDetails.push({
          name: "",
          dept: "",
          year: 1,
          isFormOpen: false,
        });
        membersCount++;
      }
    }
  };

  const changeTeamDetails = (args) => {
    let prevState = teamDetails;
    prevState[args.key] = args.value;

    changeMembers(prevState);

    setteamDetails({ ...prevState });
  };

  const changeTeamMemberDetails = (args) => {
    let prevState = teamMemberDetails;
    prevState[args.memberNo][args.key] = args.value;
    setteamMemberDetails([...prevState]);
  };

  const toggleFormHeight = (index) => {
    let _teamMemberDetails = teamMemberDetails;

    // If form is already open, close it
    if (_teamMemberDetails[index].isFormOpen) {
      _teamMemberDetails[index].isFormOpen = false;
    }
    // If form is not already opened
    else {
      for (let i = 0; i < _teamMemberDetails.length; i++) {
        // Open the clicked form
        if (i === index) {
          _teamMemberDetails[i].isFormOpen = true;
        }
        // Close everything else
        else {
          _teamMemberDetails[i].isFormOpen = false;
        }
      }
    }

    setteamMemberDetails([..._teamMemberDetails]);
  };

  const projectImagesChangeHandler = (event) => {
    if (event.target.files.length + projectImages.length > 3) {
      showErrorToastNotification(<p>Maximum 3 images allowed.</p>);
      return;
    }

    let _projectImages = [];

    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      if (file.size > 5000000) {
        showErrorToastNotification(<p>File size should be less than 5MB.</p>);
        return;
      }

      if (!PROJECT_IMAGE_EXTENSIONS.includes(getFileExtension(file.name))) {
        showErrorToastNotification(
          <p>Only images are allowed (jpeg,jpg,png).</p>
        );
        return;
      }

      _projectImages.push({
        key: i,
        data: file,
      });
    }

    setprojectImages([...projectImages, ..._projectImages]);
  };

  const removeProjectImages = (index) => {
    let _projectImages = projectImages;
    _projectImages.splice(index, 1);
    setprojectImages([..._projectImages]);
  };

  const abstractPDFChangeHandler = (event) => {
    let file = event.target.files[0];
    if (file.size > 5000000) {
      showErrorToastNotification(<p>File size should be less than 5MB.</p>);
      return;
    }

    if (!ABSTRACT_PDF_EXTENSIONS.includes(getFileExtension(file.name))) {
      showErrorToastNotification(<p>Only pdf is allowed.</p>);
      return;
    }
    setabstractPDF(file);
  };

  const removeAbstractPDF = () => {
    setabstractPDF(null);
  };

  const clickedRegister = async () => {
    // Form Validation
    let validation = validateTechnovationForm({
      ...teamDetails,
      teamMemberDetails: teamMemberDetails,
      projectImages: projectImages,
      abstractPDF: abstractPDF,
    });

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    setloader(true);

    let config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };
    let tempo = [];
    projectImages.forEach((element) => {
      tempo.push(element.data);
    });
    const resp = await apiTechnovationRegistration(
      {
        data: {
          ...teamDetails,
          teamMemberDetails: teamMemberDetails,
        },
        projectImages: tempo,
        abstractPDF: abstractPDF,
      },
      config
    );

    setloader(false);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        showSuccessToastNotification(<p>{resp.data.message}</p>);
        setregStatus(true);
        setpayStatus(false);
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  const getFileExtension = (fileName) => {
    let ext = "";

    for (let i = fileName.length - 1; i >= 0; i--) {
      if (fileName.charAt(i) === ".") break;
      ext += fileName.charAt(i);
    }

    return ext.split("").reverse().join("");
  };

  const clickedPay = async () => {
    let resp = await apiPay(
      {
        event: "TECHNOVATION",
      },
      {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }
    );

    // If errors
    if (
      (resp.status >= 400 && resp.status < 500) ||
      (resp.status >= 500 && resp.status < 600)
    ) {
      showErrorToastNotification(<p>{resp.data.message}</p>);
      return;
    }

    // Success ==> Redirect
    window.location = resp.data.link;
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper} colorPaletteGradientBackground`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.headingContainer}`}>
            <Heading text={"Technovation Registration"} />
            <p>A Project Competition by CTF</p>
          </div>
          {loader && <SimpleLoader text={"Registering..."} />}

          {/* Registered && Not Paid */}
          {regStatus && !payStatus && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90%",
                textAlign: "center",
              }}
            >
              <p>Already registered for this event. Pay Rs. 499/-</p>
              <p style={{ marginTop: "10px" }}>
                For any queries, contact projects@cegtechforum.in
              </p>
              <div
                className={`${styles.buttonContainer}`}
                style={{ marginTop: "30px" }}
              >
                <GlitchButton text={"Pay"} onClickMethod={clickedPay} />
              </div>
            </div>
          )}

          {/* Registered && Paid */}
          {regStatus && payStatus && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90%",
                textAlign: "center",
              }}
            >
              <p>Already registered and paid for this event.</p>
              <p style={{ marginTop: "10px" }}>
                For any queries, contact projects@cegtechforum.in
              </p>
            </div>
          )}

          {/* Not Registered && Not Paid */}
          {!regStatus && !payStatus && (
            <div
              style={{ display: loader ? "none" : "flex" }}
              className={`${styles.mainContainer}`}
            >
              <div className={`${styles.infoContainer}`}>
                <div className={`${styles.descriptionContainer}`}>
                  <p>{DESCRIPTION}</p>
                </div>
                <div className={`${styles.rulesContainer}`}>
                  <h3>Rules and Regulations</h3>
                  {RULES.map((rule, index) => {
                    return <p key={index}>-&nbsp;{rule}</p>;
                  })}
                </div>
              </div>
              <div className={`${styles.regFormContainer}`}>
                <div className={`${styles.formTextContainer}`}>
                  {/* Team Details */}
                  <div className={`${styles.teamDetailsContainer}`}>
                    <div className={`${styles.detailsContainer}`}>
                      <FormField
                        type={"text"}
                        fieldIcon={teamNameIcon}
                        placeholder="Team Name"
                        name="teamName"
                        value={teamDetails}
                        setter={changeTeamDetails}
                        info={"Minimum 4 letters"}
                      />
                      <FormField
                        type={"dropdown"}
                        fieldIcon={teamSizeIcon}
                        placeholder="Team Size"
                        name="teamCount"
                        dropdownValues={TEAM_SIZES}
                        value={teamDetails}
                        setter={changeTeamDetails}
                      />
                    </div>
                  </div>

                  {/* Team Member Details */}
                  <div className={`${styles.teamMemberDetailsContainer}`}>
                    {teamMemberDetails.map((member, index1) => {
                      return (
                        <div
                          key={index1}
                          className={`${styles.detailsContainer}`}
                        >
                          <div className={`${styles.formHeader}`}>
                            <p>{`Teammate ${index1 + 1}`}</p>
                            <KeyboardArrowDownIcon
                              onClick={() => toggleFormHeight(index1)}
                              className={`${styles.formIcon} ${
                                member.isFormOpen && `${styles.formIconInverse}`
                              }`}
                            />
                          </div>
                          <div
                            className={`${styles.formWrapper}`}
                            style={{
                              display: member.isFormOpen ? "inherit" : "none",
                            }}
                          >
                            <FormField
                              type={"text"}
                              fieldIcon={nameIcon}
                              placeholder="Name"
                              name="name"
                              value={member}
                              setter={changeTeamMemberDetails}
                              misc={{ memberNo: index1 }}
                            />
                            <FormField
                              type={"text"}
                              fieldIcon={departmentIcon}
                              placeholder="Department"
                              name="dept"
                              value={member}
                              setter={changeTeamMemberDetails}
                              misc={{ memberNo: index1 }}
                            />
                            <FormField
                              type={"dropdown"}
                              fieldIcon={yearIcon}
                              placeholder="Year"
                              name="year"
                              dropdownValues={ACADEMIC_YEARS}
                              value={member}
                              setter={changeTeamMemberDetails}
                              misc={{ memberNo: index1 }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* Project Details */}
                <div className={`${styles.projectDetailsContainer}`}>
                  {/* Project Images */}
                  <div className={`${styles.projectImagesContainer}`}>
                    <p className={`${styles.projectFileHeading}`}>
                      Upload Project Images
                    </p>
                    <p className={`${styles.instructions}`}>
                      Maximum 3 images allowed. Size of each file should be less
                      than 5MB.
                    </p>
                    <input
                      type="file"
                      multiple={true}
                      onChange={projectImagesChangeHandler}
                      className={`${styles.fileInput} ${styles.projectImagesInput}`}
                    />
                    {projectImages.map((image, index) => {
                      return (
                        <div
                          key={index}
                          className={`${styles.projectFileName}`}
                        >
                          <p>{image.data.name}</p>&nbsp;
                          <DeleteIcon
                            className={`${styles.deleteIcon}`}
                            onClick={() => removeProjectImages(index)}
                          />
                        </div>
                      );
                    })}
                  </div>

                  {/* Abstract PDF */}
                  <div className={`${styles.projectImagesContainer}`}>
                    <p className={`${styles.projectFileHeading}`}>
                      Upload Project Abstract Document
                    </p>
                    <p className={`${styles.instructions}`}>
                      Only PDF format is allowed. Size of the file should be
                      less than 5MB.
                    </p>
                    <input
                      type="file"
                      onChange={abstractPDFChangeHandler}
                      className={`${styles.fileInput}`}
                    />
                    {abstractPDF !== null && (
                      <div className={`${styles.projectFileName}`}>
                        <p>{abstractPDF.name}</p>&nbsp;
                        <DeleteIcon
                          className={`${styles.deleteIcon}`}
                          onClick={removeAbstractPDF}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className={`${styles.buttonContainer}`}>
                  <GlitchButton
                    // text={"Register"}
                    text={"Register"}
                    // onClickMethod={clickedRegister}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Page_transition>
  );
}

export default Technovation;
