import React, { useRef, useEffect } from "react";
import { navigatorItems } from "../../../../data/Navigator";
import styles from "./Navigator.module.css";

export default function Navigator(props) {
  let menuRefs = new Array(navigatorItems.length);

  useEffect(() => {
    menuRefs[0].classList.add(`colorPaletteGradientBackground`);
    menuRefs[0].classList.remove(`${styles.itemContainerOff}`);
    menuRefs[0].children[0].classList.remove(`colorPaletteGradientBackground`);
    return () => {};
  }, []);

  const clickedNavigation = (newNavTitle, index) => {
    if (newNavTitle === props.menuOption.title) return;

    // For new domain
    menuRefs[index].classList.add(`colorPaletteGradientBackground`);
    menuRefs[index].classList.remove(`${styles.itemContainerOff}`);
    menuRefs[index].children[0].classList.remove(
      `colorPaletteGradientBackground`
    );

    // For current/old domain
    menuRefs[props.menuOption.index].classList.remove(
      `colorPaletteGradientBackground`
    );
    menuRefs[props.menuOption.index].classList.add(
      `${styles.itemContainerOff}`
    );
    menuRefs[props.menuOption.index].children[0].classList.add(
      `colorPaletteGradientBackground`
    );

    props.changeMenuOption({
      title: newNavTitle,
      index: index,
    });
  };

  return (
    <div className={`${styles.menuPane}`}>
      {navigatorItems.map((item, index) => {
        return (
          <div
            ref={(el) => (menuRefs[index] = el)}
            key={index}
            className={`${styles.itemContainer} ${styles.itemContainerOff}`}
            onClick={() => {
              clickedNavigation(item.title, index);
            }}
          >
            <img
              src={item.icon}
              alt={item.title}
              className={`colorPaletteGradientBackground`}
            />
            <span>{item.title}</span>
          </div>
        );
      })}
    </div>
  );
}
