import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SimpleTypeCard from "../../components/SimpleTypeCard/SimpleTypeCard";
import styles from "./Events.module.css";
import Heading from "../../components/Heading/Heading";
import Page_transition from "../../components/Animation/Transition";
import {
  eventTypeImageBasePath,
  EVENT_TYPES,
} from "../../data/Events/EventTypes";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useOnLoadImages } from "../../utils/useOnLoadImages.ts";
import { ToggleEventListDialog, ToggleEventListMenuDialog } from "../../App";

const Events = () => {
  const wrapperRef = useRef(null);
  const toggleEventListDialog = useContext(ToggleEventListDialog);
  const toggleEventListMenuDialog = useContext(ToggleEventListMenuDialog);

  useEffect(() => {
    toggleEventListMenuDialog(true);
    return () => {
      toggleEventListMenuDialog(false);
    };
  }, []);

  // const imagesLoaded = useOnLoadImages(wrapperRef);

  return (
    <Page_transition>
      {/* {!imagesLoaded && <PageLoader />} */}
      <div
        className={`${styles.wrapper} colorPaletteGradientBackground`}
        // style={{ display: imagesLoaded ? "flex" : "none" }}
      >
        <div className={`${styles.headerContainer}`}>
          <Heading text={"Events"} />
          {/* <p>One simple liner about events</p> */}
        </div>
        <div className={`${styles.contentContainer}`} ref={wrapperRef}>
          {EVENT_TYPES.map((event, index) => {
            if (event.url === "gen-reg") return null;
            return (
              <SimpleTypeCard
                key={index}
                name={event.name}
                image={eventTypeImageBasePath + event.image}
                url={event.url}
              />
            );
          })}
        </div>
      </div>
    </Page_transition>
  );
};
export default Events;
