export const guestLecturesBasePath = `${process.env.PUBLIC_URL}/assets/images/GuestLectures/`;

export const KTALKS_REGISTRATION_LINK =
  "https://ktalks.cegtechforum.in/register";

export const KTALKS_DETAILS = [
  {
    date: "April 6, 2022",
    time: "5 PM",
    venue: "Henry Maudslay Hall",
    speaker: {
      title: "Arjun Vijay",
      oneLiner:
        "Chief Operating Officer and Co-Founder of Giottus Technologies Pvt. Ltd.",
      description:
        "Giottus is the very first crypto trading platform that provides multilingual support and state of the art customer service. The place is built with a vision of making crypto trading as simple as buying candy. How did the founders come up with the idea? How are sustaining this good in the market?  How did their Eureka moment happen? To know everything, Arjun Vijay, one of the founders of Giottus Pvt.Ltd. and the current COO of the brand is joining us to share his answers.",
      filler: guestLecturesBasePath + "arjun_vijay.jpg",
    },
  },
  {
    date: "April 6, 2022",
    time: "5 PM",
    venue: "Henry Maudslay Hall",
    speaker: {
      title: "Nedunchezhian D",
      oneLiner: "Educationist and Social Entrepreneur",
      description:
        "A mechanical engineer and an alumnus of Anna University turned educationist and social entrepreneur who is now working for a cause? What is the cause? Creating educational awareness among students and parents of X, XI, XII standards. He strongly believes that one’s intellect cannot be assessed based on entrance examinations.",
      filler: guestLecturesBasePath + "nedunchezhian.jpg",
    },
  },
  {
    date: "April 6, 2022",
    time: "5 PM",
    venue: "Henry Maudslay Hall",
    speaker: {
      title: "V.M. Muralidharan",
      oneLiner: "Chief Operating Officer ,Bahwan CyberTek",
      description:
        "An ‘83 ECE graduate of College Of Engineering, Guindy went on to start his career in India’s two big tech giants, Wipro and HCL. Later, he headed the Ethiraj College For Women as the chairman of Ethiraj College Trust from 2013 to 2019. Currently, he is the COO of Bahwan CyberTek Group, a global software products and solutions group.",
      filler: guestLecturesBasePath + "mikemurali.jpg",
    },
  },
  {
    date: "April 7, 2022",
    time: "11 AM",
    venue: "Vivekananda Auditorium",
    speaker: {
      title: "Viswanathan Anand",
      oneLiner: "Indian chess grandmaster and a five-time world chess champion",
      description:
        "From learning to play chess from his mother at 6 years old to becoming the youngest Indian who won the international master title, CTF brings to you the one of the finest K! talks speaker, the five-time world chess champion, the one and only Grandmaster Mr. Viswanathan Anand Known for his calm gestures, focused frame of mind and undeniable talent, he is indeed the inspiration for all aspiring chess players out there. Want to know how he did it? Join us to get more insight from the genius himself. Don’t miss this once in a lifetime opportunity",
      filler: guestLecturesBasePath + "viswanathan-anand.jpeg",
    },
  },
];

export const IMAGES = [
  {
    name: "Dr. A. P. J. Abdul Kalam",
    image: guestLecturesBasePath + "AbdulKalam.jpg",
    description: "Former Indian President, Aerospace Scientist",
  },
  {
    name: "Stephen Wolfram",
    image: guestLecturesBasePath + "StephenWolfram.jpg",
    description: "Founder and CEO of Wolfram Research",
  },
  {
    name: "Daniel Kish",
    image: guestLecturesBasePath + "DanielKish.jpg",
    description:
      "American expert in human echolocation and the President of World Access for the Blind (WAFTB)",
  },
  {
    name: "Kiran Bedi",
    image: guestLecturesBasePath + "KiranBedi.jpg",
    description: "India's first ranking woman IPS",
  },
];

export const DOMAINS = [
  {
    name: "Technology",
    description:
      "Trend based tech talks to kindle up the enthusiasm in handling up future-driving technologies.",
  },
  {
    name: "Environment",
    description:
      "Eco-friendly talks that aim to inspire the nature-lovers inside us. ",
  },
  {
    name: "Research",
    description:
      "Awe-inspiring talks that make us traverse via the unexposed extremes of this world and wake the curiosity up within us. ",
  },
  {
    name: "Sports",
    description:
      "Fun-filled informative talks grab our soles to the playgrounds.",
  },
  {
    name: "Motivational",
    description:
      "Talks with a combo of confidence and energy carry you straightaway to the path of success. ",
  },
  {
    name: "Health",
    description:
      "Talks from health-keeping tips to life-saving tricks on a single go.",
  },
  {
    name: "Entrepreneurship",
    description:
      "Experts deliver great hacks and lessons to future CEOs on life-changing decisions. ",
  },
];

export const INFORMATION = {
  date: "February 11, 2022",
  time: "4.30 PM",
  contact: {
    name: "Vaishnavi R",
    mobile: "90879 15558",
    mail: "vaishnavir3reddy14@gmail.com",
  },
};
