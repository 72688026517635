import React from "react";
import styles from "./StudentDetails.module.css";
import Modal from "react-modal";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CloseIcon from "@mui/icons-material/Close";
import LinkedInIcon from "../../../assets/images/Misc/linkedin.png";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import { DOMAINS } from "../../../data/CreativeTeam";

function StudentDetails({ isOpen, student, clickedStudentDetailsClose }) {
  let domainEmail = "";
  for (let i = 0; i < DOMAINS.length; i++) {
    if (DOMAINS[i].name === student.domain) {
      domainEmail = DOMAINS[i].email;
      break;
    }
  }
  return (
    <Modal
      className={`${styles.modalWrapper}`}
      overlayClassName={`${styles.modalOverlay}`}
      isOpen={isOpen}
      closeTimeoutMS={200}
    >
      <div className={`${styles.modalContainer}`}>
        <CloseIcon
          onClick={clickedStudentDetailsClose}
          className={`${styles.close_icon}`}
        />
        <div className={`${styles.imageNameContainer}`}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/CreativeTeam/${student.squareImage}`}
            alt={student.name}
          />
          
        </div>
        <div className={`${styles.quoteLinkContainer}`}>
          <p className={`${styles.name}`}>{student.name}</p>
          <p className={`${styles.iconPlusText}`}>
            <WorkIcon />
            &nbsp;{student.domain}
          </p>
          <p className={`${styles.iconPlusText}`}>
            <PhoneAndroidIcon />
            &nbsp;{student.mobile}
          </p>
          <p className={`${styles.iconPlusText}`}>
            <EmailIcon />
            &nbsp;{domainEmail}
          </p>
          <div className={`${styles.quote}`}>
            <p>{student.quote}</p>
          </div>
          <div className={`${styles.iconsBox}`}>
            <img
              src={LinkedInIcon}
              alt="LinkedIn Icon"
              className={`${styles.icon}`}
              onClick={() => window.open(student.linkedIn)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default StudentDetails;
