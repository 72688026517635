export const stringifyUserDetails = (data) => {
  let obj = {
    kid: data.kid,
    email: data.email,
    firstname: data.firstname,
    lastname: data.lastname,
    phone: data.phone,
    college: data.college,
    dept: data.dept,
    year: data.year,
    cegian: data.cegian.toString(),
  };
  return JSON.stringify(obj);
};

export const isAuthDataStored = () => {
  if (
    localStorage.getItem("details") !== null &&
    localStorage.getItem("token") !== null
  ) {
    return true;
  } else {
    localStorage.removeItem("details");
    localStorage.removeItem("token");
    return false;
  }
};
