import React from "react";
import styles from "./PageLoader.module.css";

function PageLoader() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={`${styles.stars_1}`}></div>
        <div className={`${styles.stars_2}`}></div>
        <div className={`${styles.stars_3}`}></div>
        <div className={styles.loader}>
          <h1>KURUKSHETRA'22</h1>
          <p>Loading...</p>
        </div>
      </div>
    </div>
  );
}

export default PageLoader;
