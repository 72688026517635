import Modal from "react-modal";
import React from "react";
import styles from "./InstructionsDialog.module.css";
import GlitchButton from "../../../components/GlitchButton/GlitchButton";

function InstructionsDialog({ isOpen, clickedClose, clickedRegister }) {
  return (
    <Modal
      className={`${styles.modalWrapper}`}
      overlayClassName={`${styles.modalOverlay}`}
      isOpen={isOpen}
      closeTimeoutMS={200}
    >
      <div className={`${styles.modalContainer}`}>
        <div className={styles.popup_wrapper}>
          <div className={styles.Note}>
            <h4>INSTRUCTIONS</h4>
            <br></br>
            <li>
              Students must bring hard copy of their Government ID card and
              College ID Proof Xerox.
            </li>
            <li>
              Deposit amount of Rs.500 has to be paid at the time of check in
              (Only cash).
            </li>
            <li> Deposit amount will be refunded at the time of check out.</li>
          </div>
          <div className={styles.buttonPanel}>
            <GlitchButton
              onClickMethod={clickedRegister}
              text={"I AGREE"}
            ></GlitchButton>
            <GlitchButton
              onClickMethod={() => clickedClose("CLOSE")}
              text={"BACK"}
            ></GlitchButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default InstructionsDialog;
