export const TEAM_SIZES = [3, 4, 5, 6];

export const PROJECT_IMAGE_EXTENSIONS = [
  "jpeg",
  "JPEG",
  "jpg",
  "JPG",
  "png",
  "PNG",
];

export const ABSTRACT_PDF_EXTENSIONS = ["pdf", "PDF"];

export const DESCRIPTION =
  "It does not take much strength to do things, but it requires a great deal of strength to decide what to do! The Projects team of CEG Tech Forum is here with the platform to display your innovative Projects and to inspire all with your fantabulous ideas! The Competition is on! Let your projects speak, who you are! CEG Tech Forum is proud to organize the 'Technovation' on April 6 during Kurukshetra 2022.";

export const RULES = [
  "Students from any engineering stream can participate.",
  "Participants can showcase their projects as a team. (Should not exceed 6 members)",
  "Participants can register themselves in the given link. Participants are requested to register with their abstract and photograph of the prototype at the time of registration with an entry fee of Rs. 500/-",
  "The evaluation of projects will be done by high-profile academicians and industry persons with preset guidelines.",
  "Decision of the judges will be final.",
  "The last date to register is 02.04.2022.",
];
