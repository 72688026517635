import React from "react";
import styles from "./Workshops.module.css";
import { WORKSHOPS } from "../../data/Workshops/Workshops";
import ListEventsWorkshops from "../../components/ListEventsWorkshops/ListEventsWorkshops";
import Page_transition from "../../components/Animation/Transition";
function Workshops() {
  return (
    <Page_transition>
      <ListEventsWorkshops
        type={"Workshops"}
        data={WORKSHOPS}
        moreData={"workshops"}
      />
    </Page_transition>
  );
}

export default Workshops;
