import React from "react";
import { DOMAINS, guestLecturesBasePath } from "../../../../data/GuestLectures";
import styles from "./domains.module.css";
import Heading from "../../../../components/Heading/Heading";

const Domains = () => {
  return (
    <div className={`${styles.domains}`}>
      <div className={`${styles.bgDiv}`}></div>
      &nbsp;
      <div className={`${styles.headerContainer}`}>
        <Heading text={"DOMAINS"} />
      </div>
      &nbsp;
      <div className={`${styles.domain_cards}`}>
        {DOMAINS.map((item, index) => {
          return (
            <div key={index} className={`${styles.domain_card}`}>
              <img
                src={`${guestLecturesBasePath}${item.name}.jpg`}
                alt={item.name}
                className={`${styles.domain_image}`}
              />
              <div className={`${styles.domain_content}`}>
                <p className={`${styles.domain_name}`}>{item.name}</p>
                <p className={`${styles.domain_desc}`}>{item.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Domains;
