export const validateAccomodationForm = (data) => {
  let dates = data.dates;
  let datesFlag = false;
  for (let i = 0; i < dates.length; i++) {
    if (dates[i]) {
      datesFlag = true;
      break;
    }
  }

  if (!datesFlag)
    return {
      status: false,
      message: "Choose a date",
    };

  if (data.govtIdPDF === null) {
    return {
      status: false,
      message: "Upload Government ID PDF",
    };
  }

  if (data.collegeIdPDF === null) {
    return {
      status: false,
      message: "Upload College ID card PDF",
    };
  }

  return {
    status: true,
  };
};
