import React, { useState } from "react";
import Navigator from "../../components/Dashboard/components/Navigator/Navigator";
import styles from "./Dashboard.module.css";
import { navigatorItems } from "../../data/Navigator";
import Page_transition from "../../components/Animation/Transition";

export default function Dashboard() {
  const [menuOption, setMenuOption] = useState({
    title: "Profile",
    index: 0,
  });

  return (
    <Page_transition>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.navigator}`}>
          <Navigator
            menuOption={menuOption}
            changeMenuOption={(menuOption) => setMenuOption(menuOption)}
          ></Navigator>
        </div>
        <div className={`${styles.mainPane}`}>
          {navigatorItems.map((item, index) => {
            if (menuOption.title === item.title) return item.pane;
          })}
        </div>
      </div>
    </Page_transition>
  );
}
