import React from "react";
import styles from "./GlitchButton.module.css";

function GlitchButton({ text, onClickMethod }) {
  return (
    <button onClick={() => onClickMethod()} className={`${styles.button}`}>
      {text}
      <span></span>
      <span className={`${styles.glitch}`}>{text}</span>
    </button>
  );
}

export default GlitchButton;
