const localImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Events/robotics/`;
export const ROBOTICS_EVENTS = [
  // Pacbot
  {
    price: 499,
    eventcode: "PACBOT",
    participantsTypeFlag: "INDIVIDUAL&TEAM",
    participantsDetail: {
      minSize: 2,
      maxSize: 4,
    },
    regOpen: true,
    title: "Pac Bot",
    path: "pacbot",
    filler: localImageBasePath + "pacbot/filler.jpg",
    oneLiner:
      "An opportunity to relive the famous arcade game in a whole new dimension",
    description: [
      "As a tribute to this revolutionary game, K! 22 gives you an opportunity to relive the famous arcade game in a whole new dimension.",
      "A ROBOTIC Challenge like never before. PACBOT challenges you to capture the gold coins spread over the PAC MAZE combating against the Ghosts. An incredible chance to become the ultimate champion.",
      "Unleash The Power of Your PACBOT in Kurukshetra 2022!",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "The participating teams will be split into 2 teams: the PAC team and the Ghost team.",
          "In the first round, each team from the PAC team must survive the maze with 2 Ghost bots.",
          "The points will be given based on the duration of survival of the PAC bot.",
          "There will be checkpoints in the maze which are considered for bonus points if the PAC BOT passes through it",
          "When a PAC BOT passes all the checkpoints, one of the ghost bot’s motion is halted for a few seconds and then resumed.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "The PAC team and the Ghost team are switched over.",
          "The points are once again calculated accordingly.",
          "The team with the highest points is the winner.",
        ],
      },
    ],
    rules: [
      "Refer Abstract Format for detailed rules and regulations of the event.",
      "Each team is required to build a Remote-controlled bot.",
      "Participants should have a valid K! Id.",
      "Each team can have a maximum of 4 members.",
      "Each team must build a bot which should be within the dimensions of a 30 cm cube",
      "Organizers have the power to disqualify a team if it violates any of the rules.",
      "If the participants have any query during the event, they can clarify it with the organizers and the decision of the judge is final.",
    ],
    prerequisites: [
      "Participants are required to bring their own Remote Controlled Bots (Wired/Wireless)",
    ],
    contact: [
      {
        name: "Jyoti Aditya Giri",
        mobile: "80562 64046",
      },
      {
        name: "Bharath L",
        mobile: "86673 97262",
      },
      {
        name: "Karpaga Poorani P",
        mobile: "80754 46900",
      },
    ],
    mail: "pacbot@cegtechforum.in",
    dateTimeFees: {
      date: "April 6, 2022",
    },
    abstractPDF: `${process.env.PUBLIC_URL}/assets/images/Events/robotics/pacbot/rules.pdf`,
  },

  // Robozest
  {
    price: 499,
    eventcode: "ROBOZEST",
    participantsTypeFlag: "INDIVIDUAL&TEAM",
    participantsDetail: {
      minSize: 2,
      maxSize: 4,
    },
    regOpen: true,
    title: "Robozest",
    path: "robozest",
    filler: localImageBasePath + "robozest/filler.png",
    oneLiner: "",
    description: [
      "A platform for students to showcase their observance and passion for building and coding abot for the given constraints.",
      "The participants will have to bring a line tracer bot with the following specifications.",
      "The robot which traverses the line fastest from the start point to the finish point will be given the highest score.",
      "The track would have alternate zones of white background and black background.",
      "The bot needs to traverse the black line in white background and the white line in black background.",
      "Obstacle detection must also be included in the robot configuration. Moving further, the bot needs to traverse under caves.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "The evaluation will be based on the following parameters: 1.  The time taken to reach the finish line 2. Minimal pick and place. 3. Obstacle detection: Only black and white balls. The track will be of three parts.",
          "The first part of the track will have white background with black track. The bot needs to travel with the highest speed. When it reaches the end of this part, a track with black background and white line starts.",
          "The bot needs to travel this track with half the velocity of the previous track. Obstacles such as White balls will be placed on the second part of the track. The bot needs to stop after it detects an obstacle. The obstacle will be temporary only. After it is detected, it will be removed. The bot can continue its track after the obstacle is removed.",
          "At the end of the second part, the tracks will shift to white background with black lines. In the third part, black balls will be placed as obstacles. Penalties will be given to the bots which collide with the obstacles.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "The track will be shown before the event starts. The teams are allowed to take only one trail run. The track in this round would contain caves as well as obstacles.",
          "This round would also contain balls. The color of the balls would be either Black or White.",
          "There would be diverging routes. If an obstacle is detected in the path, the bot must retrace the track and choose the other path. Penalties will be given to bots that collide with the obstacle.",
          "The track would have alternate zones of black and whites lines with white and black background respectively.",
          "As mentioned, there would be caves in this round. Hence, Participants are allowed to use a light sensor and make sure that the bot can identify and move in darkness. Participants are allowed to use LEDs on their bots to identify the line in dark parts of the cave. ",
        ],
      },
    ],
    rules: [
      "Maximum of Four members are allowed in each participating team. Lone wolves are also welcomed.",
      "The track would be of 3cm width. The arena of both the rounds would have dimensions 10x8ft.",
      "The robots prepared need to be completely autonomous. Dimensions to be	 restricted to 25cm x 25cm x 25cm.",
      "Teams can be inter-disciplinary as well as inter collegiate. Teams need to prepare their own bot. School students are also allowed.",
      "The Robot must be non-destructive and non-harmful to humans and the track.",
      "Readymade kits are strictly prohibited. The robot must not be made from any ready-made kit, if found so, the team will be disqualified.",
      "Once the bot starts, a timer starts and the time taken to reach the finish line will have the highest weightage in evaluation.",
      "Teams are suggested to build generic and robust code.",
      "The teams may use any number of sensors but only a maximum of four actuators are allowed for the movement of the robot.",
      "The run of the bot will be stopped once the timer reaches 3 minutes. Testing marks will be cumulative of both rounds. The weightage of the first round is 30% and the second round will have 70% weightage",
      "The decision of the judges will be final and abiding. Argument with judges in any form will lead to the disqualification of the team right-away.",
      "Penalties will be given to the teams who have touched the bot while the run. For each touch, a penalty of -5 will be provided.",
      "For each collision with the obstacle, a penalty of -10 will be provided.",
      "Bonus points will be provided to the teams based on two criteria.",
      "Design of the bot - the best design will be given a bonus of 15 points",
    ],
    prerequisites: [
      "Participants are required to bring their own Line Tracer Bot (Autonomous)",
    ],
    contact: [
      {
        name: "Manikandan S",
        mobile: "95660 64463",
      },
      {
        name: "Harisha R Sivakumar",
        mobile: "81041 17787",
      },
    ],
    mail: "robozest@cegtechforum.in",
    dateTimeFees: {
      date: "April 7, 2022",
    },
    faqs: [
      {
        question: "Can we use remote control while the bot is running?",
        answer:
          "No, you are not supposed to use remote control. The bot needs to be completely autonomous. Once the bot starts from the start position, The teams are not allowed to touch the bot till it reaches the end point. You will be penalised 5 points if you break the rule.",
      },
      {
        question: "How should the obstacle detection work?",
        answer:
          "There will be black and white balls placed on the track. The team should design the bot such that the bot needs to stop just before it comes near the ball. After the bot stops, the ball will be removed and the bot should continue moving along the line.",
      },
      {
        question:
          "Will the time when the bot is stationary is considered for the final calculation??",
        answer:
          "Once the bot stops near the ball, the timer will be paused. Once the ball is removed, the timer will be resumed. Hence the stationary time during obstacle detection will not be considered.",
      },
      {
        question: "What if the time limit exceeds 3 minutes?",
        answer:
          "Once the timer exceeds 3 minutes, 5 points will be penalised for every extra 10 seconds.",
      },
      {
        question:
          "Are we allowed to use LEDs or any other form of light on the bot?",
        answer:
          "Yes, you are allowed to use any form of lights since there are caves in round 2, provided the total dimensions of the bot should be within 25x25x25cm.",
      },
      {
        question: "Are we allowed to use Lego kits?",
        answer:
          "No, you are not allowed. Violating the rule would lead to disqualification. However you are allowed to some parts of the Lego kits, using the whole kit would lead to disqualification.",
      },
      {
        question: "How does the bonus points work?",
        answer:
          "The team with best aesthetics will be awarded bonus 15 points. The next better design will be given bonus 10 points. The next better team will be given 5 bonus points. Every next design will be given bonus points of 1-4 depending on their aesthetics.",
      },
    ],
  },
];
