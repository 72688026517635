import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Template from "../../components/EventTemplate/Template";
import { WORKSHOPS } from "../../data/Workshops/Workshops";

function WorkshopTemplateMiddleware() {
  const navigate = useNavigate();
  let { title } = useParams();

  const [data, setdata] = useState(null);

  useEffect(() => {
    let requiredData = null;

    for (let i = 0; i < WORKSHOPS.length; i++) {
      if (title === WORKSHOPS[i].path) {
        requiredData = WORKSHOPS[i];
      }
    }

    if (requiredData === null) {
      navigate(`/page-not-found`);
    } else {
      setdata(requiredData);
    }
    return () => {};
  }, []);

  return data !== null ? <Template data={data} /> : null;
}

export default WorkshopTemplateMiddleware;
