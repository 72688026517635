import ProfilePane from '../components/Dashboard/panes/ProfilePane/ProfilePane';
import EventsPane from '../components/Dashboard/panes/EventsPane/EventsPane';
import WorkshopPane from '../components/Dashboard/panes/WorkshopPane/WorkshopPane';

export const dashboardIconBasePath = `${process.env.PUBLIC_URL}/assets/images/Dashboard/`;

export const navigatorItems = [
    {
        title: 'Profile',
        icon: dashboardIconBasePath + "profile.png",
        pane: <ProfilePane />
    },
    {
        title: 'Events',
        icon: dashboardIconBasePath + "events.png",
        pane: <EventsPane />,
    },
    {
        title: 'Workshops',
        icon: dashboardIconBasePath + "workshops.png",
        pane: <WorkshopPane />
    },
];