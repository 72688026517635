import React, { useState, useEffect, useRef, useContext } from "react";
import TemplateButton from "../TemplateButton/TemplateButton";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import { gsap, Power3 } from "gsap";
import styles from "./EventTemplate.module.css";
import DetailsNav from "./DetailsNav";
import Details from "./Details";
import Page_transition from "../Animation/Transition";
import PageLoader from "../PageLoader/PageLoader";
import { useOnLoadImages } from "../../utils/useOnLoadImages.ts";
import RegisterModal from "./RegisterModal/RegisterModal";
import PayModal from "./Pay/PayModal";
import { isAuthDataStored } from "../../utils/localStorageHelper";
import { apiGetEventRegistrations, apiPay } from "../../api/auth";
import { showErrorToastNotification } from "../ToastNotification";
import { TempleBuddhist } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SimpleLoader from "../SimpleLoader/SimpleLoader";
import EventListMenu from "../EventListMenu/EventListMenu";
import EventListDialog from "../EventListDialog/EventListDialog";
import { ToggleEventListDialog, ToggleEventListMenuDialog } from "../../App";

function Template({ data }) {
  let contentRef = useRef();
  let navigate = useNavigate();
  const wrapperRef = useRef(null);
  // const imagesLoaded = useOnLoadImages(wrapperRef);

  const [login, setlogin] = useState(false);
  const [registered, setregistered] = useState(false);
  //const [price, setprice] = useState();
  const [paid, setpaid] = useState(false);
  const [paymentID, setpaymentID] = useState("");
  const [cegian, setcegian] = useState(false);
  const [regStatus, setregStatus] = useState("Register");
  const [dependent, setDependent] = useState(null);
  const [depFlag, setDepFlag] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [loader, setloader] = useState(true);

  const toggleEventListDialog = useContext(ToggleEventListDialog);
  const toggleEventListMenuDialog = useContext(ToggleEventListMenuDialog);

  useEffect(() => {
    toggleEventListMenuDialog(true);
    return () => {
      toggleEventListMenuDialog(false);
    };
  }, []);

  useEffect(() => {
    const fetchRegistrations = async () => {
      const resp = await apiGetEventRegistrations({
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      if (resp === undefined) {
        showErrorToastNotification(<p>Please try again after sometime</p>);
        return;
      } else {
        if (resp.status === 200) {
          //do nothing
          //(replace with some better action or text?)
        } else if (resp.status >= 400 && resp.status < 500) {
          showErrorToastNotification(<p>{resp.data.message}</p>);
          return;
        } else if (resp.status >= 500 && resp.status < 600) {
          showErrorToastNotification(<p>{resp.data.message}</p>);
          return;
        }
      }

      for (let i = 0; i < resp.data.length; i++) {
        // Check if registered for th
        // const parsedDetails = JSON.parse(localStorage.getItem("details"));
        // userDetails = {
        // 	name: parsedDetails.firstname,
        // 	email: parsedDetails.email,
        // 	phone: parsedDetails.phone,
        // };

        // console.log(modalData);

        if (resp.data[i].purpose === data.eventcode) {
          setregistered(true);
          //setprice(resp[i].amount);
          //TODO: set dependents as well, and pass to modal, to show there
          let udata = JSON.parse(localStorage.getItem("details"));
          setcegian(udata.cegian === "true" ? true : false);

          if (resp.data[i].dependent !== null) {
            setDepFlag(true);
            setDependent(resp.data[i].dependent);
          } else if (resp.data[i].status === "Credit") {
            //paid
            setpaid(true);
            setregStatus("Registered & Paid");
            setpaymentID(resp.data[i].paymentid);
          } else if (resp.data[i].status === "Failed") {
            //payment failed
            setpaid(false);
            setregStatus("Pay");
            setpaymentID(resp.data[i].paymentid);
          } else if (resp.data[i].status === "NULL") {
            //not paid
            setpaid(false);
            setregStatus("Pay");
          }

          //stop searching for event, since found
          break;
        }
      }

      if (!registered) {
        setregStatus("Register");
      }
    };

    /*
    setlogin(true);
			// const parsedDetails = JSON.parse(localStorage.getItem("details"));
			// userDetails = {
			// 	name: parsedDetails.firstname,
			// 	email: parsedDetails.email,
			// 	phone: parsedDetails.phone,
			// };

			// console.log(modalData);
    setregistered(true);
    setprice(100);
    setpaid(false);
    setpaymentID('N/A');
    setcegian(true);
    setregStatus('Registered');
    */

    if (isAuthDataStored()) {
      setlogin(true);
      setcegian(
        JSON.parse(localStorage.getItem("details")).cegian === "true"
          ? true
          : false
      );
      const parsedDetails = JSON.parse(localStorage.getItem("details"));
      setUserEmail(parsedDetails.email);
      setUserName(parsedDetails.firstname);
      setUserPhone(parsedDetails.phone);

      // const parsedDetails = JSON.parse(localStorage.getItem("details"));
      // userDetails = {
      // 	name: parsedDetails.firstname,
      // 	email: parsedDetails.email,
      // 	phone: parsedDetails.phone,
      // };

      // console.log(modalData);
      fetchRegistrations();
      setloader(false);
    }

    return () => {};
  }, []);

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const [currentDetail, setcurrentDetail] = useState({
    index: 0,
    name: "description",
  });

  const changeRegistrationStatus = () => {
    setregistered(true);
    setpaid(false);
  };

  const clickedDetailsNav = (newDetail, newDetailRef, currentDetailRef) => {
    if (currentDetail.index === newDetail.index) return;

    setcurrentDetail(newDetail);
    currentDetailRef.classList.remove(`${styles.details_nav_active}`);
    newDetailRef.classList.add(`${styles.details_nav_active}`);
    gsap.from(contentRef.current, {
      duration: 0.5,
      x: newDetail.index > currentDetail.index ? "5%" : "-5%",
      opacity: 0,
      ease: Power3.easeOut,
    });
  };

  const [isModalOpen, setisModalOpen] = useState(false);
  const [isPayModalOpen, setisPayModalOpen] = useState(false);
  const [PayDetails, setPayDetails] = useState({});

  const clickedRegister = () => {
    setisModalOpen(true);
  };

  const redirectSherlock = () => {
    window.open("https://sherlock.kurukshetraceg.org.in", "_blank");
  };

  const redirectWSW = () => {
    window.open("https://wsw.kurukshetraceg.org.in", "_blank");
  };

  const clickedRegisterModalClose = () => {
    setisModalOpen(false);
  };

  const clickedPay = async () => {
    const resp = await apiPay(
      {
        event: data.eventcode,
      },
      {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }
    );

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
      return;
    } else {
      if (resp.status === 200) {
        setPayDetails(resp.data);
        setisPayModalOpen(true);
        //do nothing
        //(replace with some better action or text?)
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
        return;
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
        return;
      }
    }
  };

  const clickedPayClose = () => {
    setisPayModalOpen(false);
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper}`} ref={wrapperRef}>
        {/* {!imagesLoaded && <PageLoader />} */}
        {/* {loader && <SimpleLoader text={"Fetching details"} />} */}
        <div
          // style={{ display: imagesLoaded ? "flex" : "none" }}
          className={`${styles.parallax}`}
          // style={{ display: loader ? "none" : "flex" }}
        >
          <div className={`${styles.blurDiv}`}>
            <img src={data.filler} />
          </div>
          <div className={`${styles.landing_container}`}>
            <h1>{data.title}</h1>
            <p>{data.oneLiner}</p>
            <p>
              {data.hasOwnProperty("dateTimeFees") &&
                data.dateTimeFees.hasOwnProperty("date") &&
                data.dateTimeFees.date}
            </p>
            <div className={`${styles.button_container}`}>
              <div className={`${styles.button_1}`}>
                <TemplateButton
                  text={"Explore"}
                  onClickMethod={executeScroll}
                />
              </div>
              <div className={`${styles.button_1}`}>
                {data.eventcode === "PAPERPRESENTATION" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "PRODUCT" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "HANDGESTURET" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "HANDGESTURE" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "DRONE" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "CREATIVETHINKING" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "STOCKMARKET" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "METAVERSE" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "UIUXDESIGN" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "BLOCKCHAIN" ? (
                  <TemplateButton text={"SOLD OUT!"} />
                ) : data.eventcode === "SHERLOCK" ? (
                  <TemplateButton
                    text={"EVENT CLOSED"}
                    //onClickMethod={redirectSherlock}
                  />
                ) : data.eventcode === "WALLSTREET" ? (
                  <TemplateButton
                    text={"CLICK HERE TO PLAY!"}
                    onClickMethod={redirectWSW}
                  />
                ) : data.regOpen ? (
                  depFlag ? (
                    <TemplateButton
                      text={"Registered by " + dependent}
                      //onClickMethod={clickedRegister}
                    />
                  ) : login ? (
                    registered ? (
                      cegian && data.eventcode === "GENERALREGISTRATION" ? (
                        <TemplateButton
                          text={"Registered as CEGian."}
                          //onClickMethod={clickedRegister}
                        />
                      ) : paid ? (
                        <TemplateButton
                          text={"Registered and Paid"}
                          //onClickMethod={clickedRegister}
                        />
                      ) : data.eventcode === "OLPC" ? (
                        <TemplateButton
                          text={"Redirect to HackerRank"}
                          onClickMethod={() =>
                            window.open(data.regRedirectLink)
                          }
                        />
                      ) : (
                        <TemplateButton
                          text={"Registered. Pay Now!"}
                          onClickMethod={clickedPay}
                        />
                      )
                    ) : (
                      <TemplateButton
                        text={regStatus}
                        onClickMethod={clickedRegister}
                      />
                    )
                  ) : (
                    <TemplateButton
                      text={"Login to Register"}
                      onClickMethod={() => navigate("/login")}
                    />
                  )
                ) : (
                  <TemplateButton text={"Registrations to Open Soon!"} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          // style={{ display: imagesLoaded ? "flex" : "none" }}
          ref={myRef}
          name="css"
          id="check"
          className={`${styles.wrapper_imported} colorPaletteGradientBackground`}
          // style={{ display: loader ? "none" : "flex" }}
        >
          <div
            name="details"
            id="details_div"
            className={`${styles.details_wrapper}`}
          >
            <DetailsNav
              clickedDetailsNav={clickedDetailsNav}
              currentDetail={currentDetail}
              data={data}
            />
            <div className={`${styles.border_css}`}>
              <div className={`${styles.content_container}`}>
                <div
                  ref={contentRef}
                  className={`${styles.content} customScrollbar`}
                >
                  <Details currentDetail={currentDetail} data={data} />
                </div>
              </div>
            </div>

            {/* Misc Instructions */}
            {data.hasOwnProperty("miscInstructions") && (
              <div className={`${styles.miscInstructionsContainer}`}>
                {/* <h3>General Note</h3> */}
                {data.miscInstructions.map((instruction, index) => {
                  return <p key={index}>*&nbsp;{instruction}</p>;
                })}
              </div>
            )}
          </div>
        </div>
        {data.regOpen && (
          <RegisterModal
            isOpen={isModalOpen}
            modalData={{
              regStatus,
              price: data.price,
              paymentID,
              eventcode: data.eventcode,
              teamFlag: data.teamFlag,
              teamSizes: data.teamSizes,
              userDetails: {
                name: userName,
                phone: userPhone,
                email: userEmail,
                cegian: cegian,
              },
            }}
            eventData={data}
            clickedRegisterModalClose={clickedRegisterModalClose}
            changeRegistrationStatus={changeRegistrationStatus}
          />
        )}

        <PayModal
          isOpen={isPayModalOpen}
          modalData={PayDetails}
          clickedPayModalClose={clickedPayClose}
        />
      </div>
    </Page_transition>
  );
}

export default Template;
