import styles from "./Sponsors.module.css";
import { imageBasePath } from "../../data/Sponsors";
import StylishHeading from "../../components/StylishHeading/StylishHeading";
import { Width } from "../../App";
import { useContext } from "react";

function SponsorType({ sponsor, isTitleSponsor }) {
  const width = useContext(Width);

  let imagesRef = new Array(sponsor.companies.length);

  const clickedSponsorImage = (item) => {
    if (!item.hasOwnProperty("url")) return;
    window.open(item.url);
  };

  return (
    <>
      <div className={`${styles.sponsorContainer}`}>
        <div className={`${styles.sponsorSubheading}`}>
          <StylishHeading text={sponsor.type} />
        </div>
        <div className={`${styles.sponsorImages}`}>
          {sponsor.companies.map((item, index) => {
            return (
              <div
                ref={(el) => (imagesRef[index] = el)}
                className={`${styles.sponsorImage}`}
              >
                <img
                  className={
                    isTitleSponsor
                      ? `${styles.titleSponsorImage}`
                      : `${styles.normalSponsorImage}`
                  }
                  src={`${imageBasePath}${item.image}`}
                  alt={`${item.name}`}
                  onClick={() => clickedSponsorImage(item)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SponsorType;
