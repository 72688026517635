import React, { useEffect, useState } from "react";
import styles from "./PayModal.module.css";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import StylishHeading from "../../StylishHeading/StylishHeading";
import GlitchButton from "../../GlitchButton/GlitchButton";
import FormField from "../../FormField/FormField";
import teamIcon from "../../../assets/images/LoginRegistration/department.png";
import nameIcon from "../../../assets/images/LoginRegistration/name.png";
import emailIcon from "../../../assets/images/LoginRegistration/email.png";
import phoneIcon from "../../../assets/images/LoginRegistration/phone.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
  showWarnToastNotification,
} from "../../ToastNotification";
import { validateEventRegistrationForm } from "../../../validators/authValidators";
import { isAuthDataStored } from "../../../utils/localStorageHelper";
import { apiEventRegister } from "../../../api/auth";

import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PaymentIcon from "@mui/icons-material/Payment";
import ReactTooltip from "react-tooltip";

const FORM_FIELDS = [
  {
    type: "text",
    fieldIcon: nameIcon,
    placeholder: "Name",
    name: "name",
  },
  {
    type: "text",
    fieldIcon: emailIcon,
    placeholder: "E-mail ID",
    name: "email",
  },
  {
    type: "text",
    fieldIcon: phoneIcon,
    placeholder: "Phone number",
    name: "phone",
  },
];

const detailsFormat = {
  name: "",
  email: "",
  phone: "",
};

const memberDetailsFormat = {
  ...detailsFormat,
  isFormOpen: true,
};

const registrationDetailsFormat = {
  teamCount: 2,
};

function RegisterModal({ isOpen, modalData, clickedPayModalClose }) {
  // const [teamMemberDetails, setteamMemberDetails] = useState([]);
  // const [registrationDetails, setregistrationDetails] = useState(
  // 	registrationDetailsFormat
  // );

  // let userDetails = {
  // 	name: "",
  // 	email: "",
  // 	phone: "",
  // };

  // const changeMembers = (prevState) => {
  // 	let currentTeamCount = prevState.teamCount - 1;
  // 	let membersCount = teamMemberDetails.length;
  // 	let _teamMemberDetails = teamMemberDetails;

  // 	// If prevCount same as newCount, return
  // 	if (currentTeamCount === membersCount) return;

  // 	// If newCount < prevCount
  // 	if (currentTeamCount < membersCount) {
  // 		while (membersCount !== currentTeamCount) {
  // 			_teamMemberDetails.pop();
  // 			membersCount--;
  // 		}
  // 	}
  // 	// If newCount > prevCount
  // 	else {
  // 		while (membersCount !== currentTeamCount) {
  // 			_teamMemberDetails.push({
  // 				name: '',
  // 				email: '',
  // 				phone: '',
  // 				isFormOpen: false,
  // 			});
  // 			membersCount++;
  // 		}
  // 	}
  // };

  // const changeFormState = (args) => {
  // 	let prevState = registrationDetails;
  // 	prevState[args.key] = args.value;
  // 	changeMembers(prevState);
  // 	setregistrationDetails({ ...prevState });
  // };

  // const changeTeamMemberDetails = (args) => {const [teamMemberDetails, setteamMemberDetails] = useState([]);
  // const [registrationDetails, setregistrationDetails] = useState(
  // 	registrationDetailsFormat
  // );
  // 	let prevState = teamMemberDetails;
  // 	prevState[args.memberNo][args.key] = args.value;
  // 	setteamMemberDetails([...prevState]);
  // };
  // // Toggle team members form open.close
  // const toggleFormHeight = (index) => {
  // 	let _teamMemberDetails = teamMemberDetails;
  // 	// If form is already open, close it
  // 	if (_teamMemberDetails[index].isFormOpen) {
  // 		_teamMemberDetails[index].isFormOpen = false;
  // 	}
  // 	// If form is not already opened
  // 	else {
  // 		for (let i = 0; i < _teamMemberDetails.length; i++) {
  // 			// Open the clicked form
  // 			if (i === index) {const [teamMemberDetails, setteamMemberDetails] = useState([]);
  // const [registrationDetails, setregistrationDetails] = useState(
  // 	registrationDetailsFormat
  // );
  // 				_teamMemberDetails[i].isFormOpen = true;
  // 			}
  // 			// Close everything else
  // 			else {
  // 				_teamMemberDetails[i].isFormOpen = false;
  // 			}
  // 		}
  // 	}

  // 	setteamMemberDetails([..._teamMemberDetails]);
  // };

  useEffect(() => {
    // if (isAuthDataStored()) {
    // 	const parsedDetails = JSON.parse(localStorage.getItem("details"));
    // 	userDetails = {
    // 		name: parsedDetails.firstname,
    // 		email: parsedDetails.email,
    // 		phone: parsedDetails.phone,
    // 	};
    // }
    // return () => { };
  }, []);

  // const clickedReg = async () => {
  // 	let _teamDetails = teamMemberDetails.map(
  // 		({ isFormOpen, ...individualDetails }) => individualDetails
  // 	);

  // 	const eventRegistrationDetails = {
  // 		event: modalData.eventcode,
  // 		team: _teamDetails,
  // 	};

  // 	let validation = validateEventRegistrationForm(eventRegistrationDetails);

  // 	if (validation.status === false) {
  // 		showErrorToastNotification(<p>{validation.message}</p>);
  // 		return;
  // 	}

  // 	let data = {
  // 		...eventRegistrationDetails,
  // 	}

  // 	let config = {
  // 		headers: {
  // 			authorization: localStorage.getItem("token"),
  // 		}
  // 	}

  // 	const resp = await apiEventRegister(data, config);

  // 	if (resp === undefined) {
  // 		showErrorToastNotification(<p>Please try again after sometime</p>);
  // 	} else {
  // 		if (resp.status === 200) {
  // 			//TODO: pass a state prop from Template.js
  // 			//and update registration status and other states accordingly
  // 			showSuccessToastNotification(<p>{resp.data.message}</p>);
  // 			clickedPayModalClose();
  // 		} else if (resp.status >= 400 && resp.status < 500) {
  // 			showErrorToastNotification(<p>{resp.data.message}</p>);
  // 		} else if (resp.status >= 500 && resp.status < 600) {
  // 			showErrorToastNotification(<p>{resp.data.message}</p>);
  // 		}
  // 	}
  // }

  const clickedPay = async () => {
    //TODO: change payment link, and might have to add more actions
    showWarnToastNotification(<p>Opening payment portal, please wait...</p>);
    setTimeout(() => {
      window.location = modalData.link;
    }, 2500);
  };

  // const EventRegHandler = () => {
  // 	switch (modalData.regStatus) {
  // 		case 'Register': return (
  // 			<GlitchButton
  // 				text={modalData.regStatus}
  // 				onClickMethod={clickedReg}
  // 			/>
  // 		);
  // 		case 'Pay': return (
  // 			<GlitchButton
  // 				text={modalData.regStatus}
  // 				onClickMethod={clickedPay}
  // 			/>
  // 		);
  // 		case 'Payment Failed': return (
  // 			<GlitchButton
  // 				text={'Try again'}
  // 				onClickMethod={clickedPay}
  // 			/>
  // 		);
  // 		case 'Registered':
  // 		default: return null;
  // 	}
  // }

  return (
    <Modal
      className={`${styles.modalWrapper}`}
      overlayClassName={`${styles.modalOverlay}`}
      isOpen={isOpen}
      closeTimeoutMS={200}
    >
      <div className={`${styles.modalContainer}`}>
        <CloseIcon
          onClick={clickedPayModalClose}
          className={`${styles.closeIcon}`}
        />
        <div className={`${styles.contentContainer}`}>
          <StylishHeading text={"Pay"} />
          <ul className={`${styles.infoContainer}`}>
            <li>
              <PersonIcon className={`${styles.detailIcon}`} data-tip="Name" />
              &nbsp;{modalData.name}
            </li>
            <li>
              <EmailIcon className={`${styles.detailIcon}`} data-tip="Email" />
              &nbsp;{modalData.email}
            </li>
            <li>
              <PhoneAndroidIcon
                className={`${styles.detailIcon}`}
                data-tip="Phone"
              />
              &nbsp;{modalData.phone}
            </li>
            <li>
              <EmojiEventsIcon
                className={`${styles.detailIcon}`}
                data-tip="Purpose"
              />
              &nbsp;{modalData.purpose}
            </li>
            <li>
              <CurrencyRupeeIcon
                className={`${styles.detailIcon}`}
                data-tip="Registration Fee"
              />
              &nbsp;{modalData.amount}/-
            </li>
            <li>
              <PaymentIcon
                className={`${styles.detailIcon}`}
                data-tip="Payment Request ID"
              />
              &nbsp;{modalData.paymentrequestid}
            </li>
            {/* <li>
							{modalData.paymentID !== '' && (
								`Payment ID: ${modalData.paymentID}`
							)}
						</li> */}

            <li>
              <GlitchButton text={"Pay"} onClickMethod={clickedPay} />
            </li>
          </ul>
        </div>
      </div>
      <ReactTooltip />
    </Modal>
  );
}

export default RegisterModal;
