import React, { useRef, useEffect, useContext } from "react";
import styles from "./LandingPage.module.css";
import { useSearchParams } from "react-router-dom";

import { Auth, SetAuth } from "../../App";
import {
  isAuthDataStored,
  stringifyUserDetails,
} from "../../utils/localStorageHelper";

import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from "../../components/ToastNotification";

import GlitchText from "./GlitchText/GlitchText";
import CountdownTimer from "../../components/CountdownTimer/CountdownTimer";
import Page_transition from "../../components/Animation/Transition";
import { useOnLoadImages } from "../../utils/useOnLoadImages.ts";
import PageLoader from "../../components/PageLoader/PageLoader";
import backdropImage from "../../assets/images/landing_rotated.JPG";
function Landing() {
  const wrapperRef = useRef(null);
  const [searchParams, setsearchParams] = useSearchParams();
  const setAuth = useContext(SetAuth);

  useEffect(() => {
    if(searchParams.get("auth") === "true") {
      setAuth(true);
      localStorage.setItem("details", stringifyUserDetails({
        kid: searchParams.get("kid"),
        email: searchParams.get("email"),
        firstname: searchParams.get("firstname"),
        lastname: searchParams.get("lastname"),
        phone: searchParams.get("phone"),
        college: searchParams.get("college"),
        dept: searchParams.get("dept"),
        year: searchParams.get("year"),
        cegian: searchParams.get("cegian"),
      }));
      
      localStorage.setItem("token", searchParams.get("token"));
      localStorage.setItem("email", searchParams.get("email"));
      showSuccessToastNotification(<p>{searchParams.get("message")}</p>)
    } else if(searchParams.get("auth") === false){
      showErrorToastNotification(<p>{"Error trying to sign you in. Try Again."}</p>);
    }
    return () => {};
  }, []);

  // const imagesLoaded = useOnLoadImages(wrapperRef);
  return (
    <Page_transition>
      <div className={`${styles.wrapper}`} ref={wrapperRef}>
        {/* {!imagesLoaded && <PageLoader />} */}
        <div className={`${styles.backgroundImage}`}>
          <img src={backdropImage} alt="Background Image" />
        </div>
        <div
          // style={{ display: imagesLoaded ? "flex" : "none" }}
          className={`${styles.mainWrapper}`}
        >
          <div className={`${styles.stars_1}`}></div>
          <div className={`${styles.stars_2}`}></div>
          <div className={`${styles.stars_3}`}></div>
          <div className={`${styles.contentContainer}`}>
            <div className={`${styles.headerContainer}`}>
              <p className={`${styles.dates}`}>Apr 5th to 8th</p>
              <GlitchText text="Kurukshetra'22" />
              <p className={`${styles.tagLine}`}>The Battle of Brains</p>
            </div>
            <div className={`${styles.countdownContainer}`}>
              <CountdownTimer />
            </div>
          </div>
        </div>
      </div>
    </Page_transition>
  );
}

export default Landing;