import React from "react";
import { Link } from "react-router-dom";
import styles from "./SimpleTypeCard.module.css";

function SimpleTypeCard({ name, image, url, onClickMethod }) {
  return (
    <Link to={url} className={`${styles.cardContainer}`}>
      <p className={`${styles.title}`}>{name}</p>
      <div className={`${styles.imageContainer}`}>
        <img className={`${styles.image}`} src={image} alt={name} />
      </div>
    </Link>
  );
}

export default SimpleTypeCard;
