import { CODING_EVENTS } from "../data/Events/Coding";
import { ENGINEERING_EVENTS } from "../data/Events/Engineering";
import { EXTRAVAGANZA_EVENTS } from "../data/Events/Extravaganza";
import { MANAGEMENT_EVENTS } from "../data/Events/Management";
import { PAPER_PRESENTATION_EVENTS } from "../data/Events/Paper Presentation";
import { QUIZ_EVENTS } from "../data/Events/Quiz";
import { ROBOTICS_EVENTS } from "../data/Events/Robotics";

export const EVENT_DATA_MAPPING = {
  engineering: ENGINEERING_EVENTS,
  coding: CODING_EVENTS,
  management: MANAGEMENT_EVENTS,
  quiz: QUIZ_EVENTS,
  extravaganza: EXTRAVAGANZA_EVENTS,
  robotics: ROBOTICS_EVENTS,
  "paper-presentation": PAPER_PRESENTATION_EVENTS,
};
