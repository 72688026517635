import React, { useState, useRef } from "react";
import styles from "./LoginRegistration.module.css";
import emailIcon from "../../assets/images/LoginRegistration/email.png";
import passwordIcon from "../../assets/images/LoginRegistration/key.png";
import googleIcon from "../../assets/images/LoginRegistration/google.png";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import FormField from "../../components/FormField/FormField";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { validateEmailForm } from "../../validators/authValidators";
import { showErrorToastNotification } from "../../components/ToastNotification";
import { apiCheckRegister, apiGoogleSignin } from "../../api/auth";
import Page_transition from "../../components/Animation/Transition";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";
const registrationCredentialsFormat = {
  email: "",
};

function Registration() {
  const navigate = useNavigate();
  let reCaptchaRef = useRef(null);
  const [registrationCredentials, setregistrationCredentials] = useState(
    registrationCredentialsFormat
  );

  const [loader, setloader] = useState(false);

  const changeRegistrationCredentials = (args) => {
    let prevState = registrationCredentials;
    prevState[args.key] = args.value;
    setregistrationCredentials({ ...prevState });
  };

  const clickedRegister = async () => {
    // Form Validation
    let validation = validateEmailForm({
      email: registrationCredentials.email,
    });

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    // reCaptcha Validation
    if (reCaptchaRef.current.getValue() === "") {
      showErrorToastNotification(<p>reCaptcha verification failed</p>);
      return;
    }

    setloader(true);

    const resp = await apiCheckRegister({
      ...registrationCredentials,
      captcha: reCaptchaRef.current.getValue(),
    });

    reCaptchaRef.current.reset();
    setloader(false);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        navigate("/registration-details", {
          state: {
            method: "normal",
            email: registrationCredentials.email,
          },
        });
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.componentContainer}`}>
          <div className={`${styles.designContainer}`}></div>
          <div className={`${styles.formContainer} customScrollbar`}>
            {loader && <SimpleLoader text={"Submitting"} />}
            <div
              className={`${styles.formContent}`}
              style={{ display: loader ? "none" : "flex" }}
            >
              <h1>Register to Kurukshetra</h1>
              <img
                onClick={() => apiGoogleSignin()}
                src={googleIcon}
                className={`${styles.googleIcon}`}
                alt="Google Icon"
              />
              <p className={`${styles.divider}`}>
                or use your email to register
              </p>

              <div className={`${styles.formBox}`}>
                <FormField
                  type={"text"}
                  fieldIcon={emailIcon}
                  placeholder="Email"
                  name="email"
                  value={registrationCredentials}
                  setter={changeRegistrationCredentials}
                />
              </div>
              <div className="recaptcha_container">
                <ReCAPTCHA
                  sitekey={"6LcMoTUdAAAAAGFo2lgEFl5sIpitgdT-lExG05FL"}
                  theme="dark"
                  size="normal"
                  className="recaptcha"
                  ref={reCaptchaRef}
                />
              </div>
              <div className={`${styles.buttonContainer}`}>
                <GlitchButton
                  text={"Register"}
                  onClickMethod={clickedRegister}
                />
              </div>
              {/* <p className={`${styles.formText}`}>
                Study at CEG?&nbsp;
                <br />
                <span
                  onClick={() => navigate("/ceg-registration")}
                  className={`${styles.formLink}`}
                >
                  Register as CEGian
                </span>
              </p> */}
              <p className={`${styles.formText}`}>
                Already have an account?&nbsp;
                <br />
                <span
                  onClick={() => navigate("/login")}
                  className={`${styles.formLink}`}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page_transition>
  );
}

export default Registration;
