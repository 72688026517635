import React, { useState } from "react";
import Heading from "../../../Heading/Heading";
import ProfileTable from "../../components/ProfileTable/ProfileTable";
import SmallCard from "../../components/SmallCard/SmallCard";
import styles from "./ProfilePane.module.css";
import verifiedCEGianImg from "../../../../assets/images/verified_cegian.png";
import ReactTooltip from "react-tooltip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

export default function ProfilePane() {
  let profileDetails = JSON.parse(window.localStorage.getItem("details"));
  const navigate = useNavigate();

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.headingContainer}`}>
        <Heading text={"Profile"} />
      </div>
      <div className={`${styles.profileCard}`}>
        <h2 className={`${styles.cardHeading}`}>
          {profileDetails.firstname + " " + profileDetails.lastname}
          {profileDetails.cegian === "true" && (
            <>
              &nbsp;
              <img
                src={verifiedCEGianImg}
                data-tip="Verified CEGian"
                className={`${styles.verifiedCEGianImg}`}
                alt="Verified CEGian"
              />
            </>
          )}
        </h2>
        <div className={`${styles.dataContainer}`}>
          <ProfileTable profileDetails={profileDetails} />
        </div>
        <ReactTooltip />
      </div>
      <div style={{ width: "90%", textAlign: "center" }}>
        {profileDetails.cegian === "true" ? (
          <b>
            General Kurukshetra'22 registration covers 28 events which is free
            of cost for CEGians.
          </b>
        ) : (
          <b>
            General Kurukshetra'22 registration covers 28 events for just Rs.
            299/-
          </b>
        )}
        <br />
        <br />
        {<b>Event registration is free of cost to all workshop particpants.</b>}
      </div>
    </div>
  );
}
