import React, { useRef, useEffect } from "react";
import Heading from "../../components/Heading/Heading";
import { SPONSORS } from "../../data/Sponsors";
import styles from "./Sponsors.module.css";
import SponsorType from "./SponsorType";
//import { WORKSHOPSPONSOR, WorkshopSponsorPath } from "../../data/Sponsors";

function Sponsor() {
  //let containersRef = new Array(WORKSHOPSPONSOR.length);
  //let imagesRef = new Array(WORKSHOPSPONSOR.length);

  return (
    <>
      <div
        className={`${styles.sponsorWrapper} colorPaletteGradientBackground`}
      >
        <div className={`${styles.headingContainer}`}>
          <Heading text={"Sponsors"} />
        </div>
        <div className={`${styles.mainContainer}`}>
          <div className={`${styles.extendWidthContainer}`}>
            <SponsorType sponsor={SPONSORS[0]} isTitleSponsor={true} />
          </div>
          <div className={`${styles.extendWidthContainer}`}>
            <SponsorType sponsor={SPONSORS[1]} isTitleSponsor={false} />
          </div>

          {SPONSORS.map((sponsor, index) => {
            if ([0, 1].includes(index)) return null;
            return (
              <SponsorType
                key={index}
                sponsor={sponsor}
                isTitleSponsor={index == 0 ? true : false}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Sponsor;
