import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListEventsWorkshops from "../../components/ListEventsWorkshops/ListEventsWorkshops";
import { CODING_EVENTS } from "../../data/Events/Coding";
import { ENGINEERING_EVENTS } from "../../data/Events/Engineering";
import { MANAGEMENT_EVENTS } from "../../data/Events/Management";
import { QUIZ_EVENTS } from "../../data/Events/Quiz";
import { EXTRAVAGANZA_EVENTS } from "../../data/Events/Extravaganza";
import { ROBOTICS_EVENTS } from "../../data/Events/Robotics";
import { PAPER_PRESENTATION_EVENTS } from "../../data/Events/Paper Presentation";
import { EVENT_TYPES } from "../../data/Events/EventTypes";
import Page_transition from "../../components/Animation/Transition";
import { useOnLoadImages } from "../../utils/useOnLoadImages.ts";
import PageLoader from "../../components/PageLoader/PageLoader";
import { EVENT_DATA_MAPPING } from "../../utils/eventMapper";

const eventDataMapping = EVENT_DATA_MAPPING;

function ListEvents() {
  const navigate = useNavigate();
  let { category } = useParams();

  const wrapperRef = useRef(null);
  // const imagesLoaded = useOnLoadImages(wrapperRef);

  const [data, setdata] = useState([]);

  useEffect(() => {
    let flag = false;
    for (let i = 0; i < EVENT_TYPES.length; i++) {
      if (category === EVENT_TYPES[i].url) {
        flag = true;
      }
    }

    if (flag === false) {
      navigate(`/page-not-found`);
    } else {
      setdata(eventDataMapping[category]);
    }

    return () => {};
  }, []);

  return (
    <Page_transition>
      {/* {!imagesLoaded && <PageLoader />} */}
      <div ref={wrapperRef}>
        <ListEventsWorkshops type={category} data={data} moreData={"events"} />
      </div>
    </Page_transition>
  );
}

export default ListEvents;
