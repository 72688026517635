import {
  validateBasedOnLength,
  validateContact,
  validateCPassword,
  validateDob,
  validateEmail,
  validateName,
  validatePassword,
  validateRoll,
  validateYearOfStudy,
} from "./validators";

// Use this for login, register through normal mail and forgot password
export const validateEmailForm = ({ email }) => {
  if (!validateEmail(email)) {
    return {
      status: false,
      message: "Invalid Email",
    };
  }

  return {
    status: true,
  };
};

export const validateRegistrationDetailsForm = (
  { email, firstname, lastname, phone, college, dept, year, pwd, cpwd },
  registrationMethod
) => {
  if (!validateEmail(email)) {
    return {
      status: false,
      message: "Invalid Email",
    };
  }

  if (!validateName(firstname)) {
    return {
      status: false,
      message: "Invalid First Name",
    };
  }

  if (phone.length === 0 || !validateContact(phone)) {
    return {
      status: false,
      message: "Invalid Phone Number",
    };
  }

  if (!validateBasedOnLength(college, 3)) {
    return {
      status: false,
      message: "Invalid College (Minimum 3 characters)",
    };
  }

  if (!validateBasedOnLength(dept, 2)) {
    return {
      status: false,
      message: "Invalid Department (Minimum 2 characters)",
    };
  }

  if (!validateYearOfStudy(year)) {
    return {
      status: false,
      message: "Invalid Academic Year",
    };
  }

  if (registrationMethod !== "gAuth" && !validatePassword(pwd)) {
    return {
      status: false,
      message: "Invalid Password",
    };
  }

  if (registrationMethod !== "gAuth" && !validateCPassword(pwd, cpwd)) {
    return {
      status: false,
      message: "Confirmation Password not matching",
    };
  }
  return {
    status: true,
  };
};

export const validateCRegistrationDetailsForm = ({
  email,
  firstname,
  lastname,
  phone,
  roll,
  dob,
  dept,
  year,
  pwd,
  cpwd,
}) => {
  if (!validateEmail(email)) {
    return {
      status: false,
      message: "Invalid Email",
    };
  }

  if (!validateName(firstname)) {
    return {
      status: false,
      message: "Invalid First Name",
    };
  }

  if (phone.length === 0 || !validateContact(phone)) {
    return {
      status: false,
      message: "Invalid Phone Number",
    };
  }

  if (!validateRoll(roll)) {
    return {
      status: false,
      message: "Invalid Roll Number",
    };
  }

  if (!validateDob(dob)) {
    return {
      status: false,
      message: "Invalid Date of Birth",
    };
  }

  if (!validateBasedOnLength(dept, 2)) {
    return {
      status: false,
      message: "Invalid Department (Minimum 2 characters)",
    };
  }

  if (!validateYearOfStudy(year)) {
    return {
      status: false,
      message: "Invalid Academic Year",
    };
  }

  if (!validatePassword(pwd)) {
    return {
      status: false,
      message: "Invalid Password",
    };
  }

  if (!validateCPassword(pwd, cpwd)) {
    return {
      status: false,
      message: "Confirmation Password not matching",
    };
  }
  return {
    status: true,
  };
};

export const validateResetPasswordForm = ({ pwd, cpwd }) => {
  if (!validatePassword(pwd)) {
    return {
      status: false,
      message: "Invalid Password",
    };
  }

  if (!validateCPassword(pwd, cpwd)) {
    return {
      status: false,
      message: "Confirmation Password not matching",
    };
  }
  return {
    status: true,
  };
};

export const validateEventTeamMember = ({ name, email, phone }) => {
  if (!validateName(name)) {
    return {
      status: false,
      message: "Invalid Name",
    };
  }
  if (!validateEmail(email)) {
    return {
      status: false,
      message: "Invalid Email",
    };
  }
  if (phone.length === 0 || !validateContact(phone)) {
    return {
      status: false,
      message: "Invalid Phone Number",
    };
  }
  return {
    status: true,
  };
};

export const validateEventRegistrationForm = ({ purpose, team }) => {
  for (let index = 0; index < team.length; ++index) {
    let memberValid = validateEventTeamMember(team[index]);
    if (!memberValid.status) {
      return {
        status: false,
        message: `Teammate ${index + 1}: ${memberValid.message}`,
      };
    }
  }
  return {
    status: true,
  };
};
