import React from "react";
import styles from "./TemplateButton.module.css";

function TemplateButton({ text, onClickMethod }) {
  return (
    <button className={`${styles.button}`} onClick={onClickMethod}>
      {text}
    </button>
  );
}

export default TemplateButton;
