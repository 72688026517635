import React, { useRef, useEffect, useContext } from "react";
import styles from "./Navbar.module.css";
import CloseIcon from "@mui/icons-material/Close";
import Hamburger from "./Hamburger/Hamburger";
import { Link, useNavigate } from "react-router-dom";
import { Auth, LoggedInUserName, SetAuth } from "../../App";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import EventIcon from "@mui/icons-material/Event";
import HandymanIcon from "@mui/icons-material/Handyman";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import MicIcon from "@mui/icons-material/Mic";
import BusinessIcon from "@mui/icons-material/Business";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import GroupsIcon from "@mui/icons-material/Groups";
import WorkIcon from "@mui/icons-material/Work";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LoginIcon from "@mui/icons-material/Login";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { FaProjectDiagram } from "react-icons/fa";
import { showSuccessToastNotification } from "../ToastNotification";
import technovationIcon from "../../assets/images/technovation.png";

function Navbar() {
  const navigate = useNavigate();
  const loggedInUserName = useContext(LoggedInUserName);
  const logout = () => {
    toggleNav("CLOSE");
    setAuth(false);
    localStorage.removeItem("details");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    showSuccessToastNotification(<p>Logged out</p>);
  };

  const login = () => {
    toggleNav("CLOSE");
    navigate("/login");
  };

  const NAVBAR = [
    {
      title: "Home",
      icon: <HomeIcon className={`${styles.nav_icon}`} />,
      url: "/",
    },
    {
      title: "About",
      icon: <InfoIcon className={`${styles.nav_icon}`} />,
      url: "/about-us",
    },
    {
      title: "Dashboard",
      icon: <PersonIcon className={`${styles.nav_icon}`} />,
      url: "/dashboard",
    },
    // {
    //   title: "Events",
    //   icon: <EventIcon className={`${styles.nav_icon}`} />,
    //   url: "/events",
    // },
    {
      title: "Events",
      icon: <MdOutlineEmojiEvents className={`${styles.nav_icon}`} />,
      url: "/events",
    },
    {
      title: "Workshops",
      icon: <HandymanIcon className={`${styles.nav_icon}`} />,
      url: "/workshops",
    },
    {
      title: "CTF Projects",
      icon: <FaProjectDiagram className={`${styles.nav_icon}`} />,
      url: "/projects",
    },
    {
      title: "Project Competition",
      icon: (
        <img
          src={technovationIcon}
          className={`${styles.nav_icon_image}`}
          alt="Technovation"
        />
      ),
      url: "/projects/technovation",
    },
    {
      title: "Accomodation",
      icon: <CheckroomIcon className={`${styles.nav_icon}`} />,
      url: "/accomodation",
    },
    {
      title: "K! Talks",
      // icon: <MicIcon className={`${styles.nav_icon}`} />,
      icon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/Logos/k_talks_white.png`}
          className={`${styles.nav_icon_image}`}
          alt="Xceed"
        />
      ),
      url: "/ktalks",
    },
    {
      title: "XCEED",
      icon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/Logos/xceed_logo.png`}
          className={`${styles.nav_icon_image}`}
          alt="Xceed"
        />
      ),
      method: () => window.open("https://xceed.kurukshetraceg.org.in/"),
    },
    {
      title: "Sponsors",
      icon: <BusinessIcon className={`${styles.nav_icon}`} />,
      url: "/sponsors",
    },
    {
      title: "K! Mag",
      icon: <ImportContactsIcon className={`${styles.nav_icon}`} />,
      url: "/kmag",
    },
    {
      title: "Team Kurukshetra",
      icon: <WorkIcon className={`${styles.nav_icon}`} />,
      url: "/team",
    },
    {
      title: "Contact",
      icon: <EmailIcon className={`${styles.nav_icon}`} />,
      url: "/contact",
    },
    {
      title: "Logout",
      icon: <LogoutIcon className={`${styles.nav_icon}`} />,
      method: logout,
    },
    {
      title: "Login",
      icon: <LoginIcon className={`${styles.nav_icon}`} />,
      method: login,
    },
  ];

  let navWrapperRef = useRef();
  const auth = useContext(Auth);
  const setAuth = useContext(SetAuth);

  const toggleNav = (ACTION) => {
    switch (ACTION) {
      case "OPEN":
        navWrapperRef.current.classList.remove(`${styles.nav_close}`);
        navWrapperRef.current.classList.add(`${styles.nav_open}`);
        break;

      case "CLOSE":
        navWrapperRef.current.classList.remove(`${styles.nav_open}`);
        navWrapperRef.current.classList.add(`${styles.nav_close}`);
        break;
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <div
        className={`${styles.nav_open_container}`}
        onClick={() => toggleNav("OPEN")}
      >
        <Hamburger />
      </div>

      <div
        ref={navWrapperRef}
        className={`${styles.nav_wrapper} ${styles.nav_close} customScrollbar`}
      >
        <CloseIcon
          className={`${styles.close_icon}`}
          onClick={() => toggleNav("CLOSE")}
        />

        <div className={`${styles.nav_container}`}>
          {/* {auth && (
            <div
              className={`${styles.nav_item} ${styles.userItem} colorPaletteGradientBackground`}
              style={{ cursor: "auto" }}
            >
              <p>Hey, {loggedInUserName}</p>
            </div>
          )} */}

          {NAVBAR.map((item, index) => {
            // if (!auth && item.title === "Logout") return;
            // if (!auth && item.title === "Dashboard") return;
            if (item.title === "Logout") return;
            if (item.title === "Dashboard") return;
            // if (!auth && item.title === "Accomodation") return;
            //if (!auth && item.title === "Technovation") return;
            if (item.title === "Login") return;
            // if (auth && item.title === "Login") return;

            if (!("url" in item)) {
              return (
                <div
                  key={index}
                  className={`${styles.nav_item} colorPaletteGradientBackgroundHover`}
                  onClick={item.method}
                >
                  <span>{item.icon}</span>
                  <p>{item.title}</p>
                </div>
              );
            } else {
              return (
                <Link
                  to={item.url}
                  key={index}
                  onClick={() => toggleNav("CLOSE")}
                >
                  <div
                    className={`${styles.nav_item} colorPaletteGradientBackgroundHover`}
                  >
                    <span>{item.icon}</span>
                    <p>{item.title}</p>
                  </div>
                </Link>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}

export default Navbar;
