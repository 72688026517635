export const workshopsSpeakerImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Workshops/`;

export const WORKSHOPS = [
  // Blockchain
  {
    price: 900,
    eventcode: "BLOCKCHAIN",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Blockchain Technology",
    path: "blockchain",
    filler: workshopsSpeakerImageBasePath + "blockchain/filler.jpg",
    oneLiner:
      "A blockchain is a digitally distributed, decentralized, public ledger that exists across a network.",
    description: [
      "Recently, the use of Blockchain technology has become more common via the creation of various cryptocurrencies, Decentralised Finance (DeFi) applications, Non-Fungible Tokens (NFTs), and smart contracts.",
      "CEG Tech Forum provides you a beginner level workshop, so no prior knowledge of blockchain is necessary. Fundamental concepts like important terms in Blockchain, Blockchain ecosystem, Bitcoin concepts like proof-of-work, transactions etc will be taught. ",
    ],
    prerequisites: [
      "Nothing but your interest",
      "Participants are required to bring their laptops",
    ],
    speakers: [
      {
        name: "Mr. Ram Viswanathan",
        designation: "IBM Fellow",
        company: "IBM",
        linkedIn: "https://www.linkedin.com/in/viswanathanram/",
        image: "blockchain/ram_viswanathan.jpg",
      },
      {
        name: "Arindam Dasgupta",
        designation: "Application Architect | Lead Blockchain Developer",
        company: "IBM",
        linkedIn: "https://www.linkedin.com/in/arindam-dasgupta-04409643/",
        image: "blockchain/arindam_dasgupta.jpg",
      },
      {
        name: "Sahith Shetty",
        designation: "Blockchain and Web3 Application Developer",
        company: "IBM",
        linkedIn: "https://www.linkedin.com/in/sahith-r-shetty-1bb831185/",
        image: "blockchain/sahith_shetty.jpg",
      },
      {
        name: "Mr. Naren Krishnan",
        designation: "Blockchain and IT Architect",
        company: "IBM",
        linkedIn: "https://www.linkedin.com/in/narenkrishnan",
        image: "blockchain/naren_krishnan.jpg",
      },
    ],
    contact: [
      {
        name: "Prajit Rahul",
        mobile: "91764 40016",
      },
      {
        name: "Harini E R",
        mobile: "91505 11278",
      },
    ],
    mail: "blockchain@cegtechforum.in",
    dateTimeFees: {
      date: "April 7-8, 2022",
      fee: "Rs. 900/-",
    },
    miscInstructions: ["Certificates along with OD will be provided."],
  },

  // Stock Market
  {
    price: 499,
    eventcode: "STOCKMARKET",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Stock Market",
    path: "stock-market",
    filler: workshopsSpeakerImageBasePath + "stockmarket/1.webp",
    oneLiner: "",
    description: [
      "According to a Bloomberg report, 35% of gen z millennials of India are choosing to  save money for the future. And most of them have chosen investing as their go to method of saving.",
      "As the biotech industry is working towards revolution, vegan and plant based food are getting more attention, green energy is setting milestones and a lot more changes on a daily basis; it is very important for us to know how to, when to and which stocks to invest in.",
      "CEG Tech forum has planned  a workshop for you on stock market which extensively covers topics like basics of Financial planning, Investment opportunities available, basics of stock market investing, understanding  derivatives, stock valuation and  selection process.",
    ],
    prerequisites: ["Your interest in stocks"],
    speakers: [
      {
        name: "Mr. Glenn Roger Carr",
        designation: "Co-Founder and Director",
        company: "Finmark Trainers Pvt. Ltd",
        linkedIn: "https://www.linkedin.com/in/glenn-roger-carr-667394113/",
        image: "stockmarket/glen.jpg",
      },
    ],
    contact: [
      {
        name: "Anusree V",
        mobile: "93447 01084",
      },
      {
        name: "Sreeratcha B",
        mobile: "9445644788",
      },
    ],
    mail: "stockmarket@cegtechforum.in",
    dateTimeFees: {
      date: "April 6, 2022",
      fee: "Rs. 499/-",
    },
    miscInstructions: ["Certificates along with OD will be provided."],
  },

  // Product Management
  {
    price: 799,
    eventcode: "PRODUCT",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Product Management",
    path: "product",
    filler: workshopsSpeakerImageBasePath + "product/filler.jpg",
    oneLiner: "Make product that lives",
    description: [
      "Every product we receive from the market goes through lots of processes. The products are scrutinized by product managers to satisfy the customer’s needs. Alright, let’s get to the point. This workshop unravels a new career path and will be your map if you wish to venture it.",
      " Do you want to know more about it? Or else more lucidly? You have fortunately landed in the right place. CEG Tech Forum presents you the opportunity to participate in the Product Management Workshop as a part of Kurukshetra’22.",
      "The workshop covers Introduction to Product Management, Product Thinking & Problem Solving, User Research, Roadmap Planning & Prioritization. This may sound like Greek and Latin but believe us, our speaker knows how to bake a piece of cake out of this.",
    ],
    prerequisites: [
      "This is a beginner level workshop with all the necessary content to give you insights into product management.",
    ],
    speakers: [
      {
        name: "Mr. Nagappan Ramasamy",
        designation: "Director of Product Management",
        company: "Multiplier",
        linkedIn: "https://www.linkedin.com/in/nagga/",
        image: "product/naggappan.jpg",
      },
    ],
    contact: [
      {
        name: "Amirtha Varshini S",
        mobile: "93606 61350",
      },
    ],
    mail: "productmanagement@cegtechforum.in",
    dateTimeFees: {
      date: "April 8, 2022",
      fee: "Rs. 799/-",
    },
    miscInstructions: ["Certificates along with OD will be provided."],
  },

  // Drone
  {
    price: 1750,
    eventcode: "DRONE",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Drone",
    path: "drone",
    filler: workshopsSpeakerImageBasePath + "drone/filler.gif",
    oneLiner: "Build and fly your own drone",
    description: [
      "Insider Intelligence predicts total global shipments of drones to reach 2.4 million in 2023 increasing at a 66.8% compound annual growth rate. Drone growth will occur across five main segments of the enterprise industry: Agriculture, construction and mining, insurance, media and telecommunications, and law enforcement.",
      "This workshop is the perfect runway to gear up your drone expeditions.",
      "The workshop by mentors from iSpark Learning Solutions Pvt. Ltd and GJ Drone Solutions Pvt. Ltd provides an unmatchable knowledge about quadcopter and also the skill of building and designing a complete drone.",
      "This 2 days hands-on workshop will take you through the  aerodynamic concepts followed by building and assembling an entire drone while handling batteries, propellers and motors.",
      "Finally your drones will be tested and you will get to fly it in an open area.",
      "All equipment shall be provided.",
    ],
    prerequisites: ["All it takes is your curiosity and inquisitiveness."],
    speakers: [
      {
        name: "Venkatesh Karry",
        designation: "Senior Engineer (Robotics & Automation)",
        company: "iSpark Learning Solutions Pvt Ltd",
        linkedIn: "https://www.linkedin.com/in/venkatesh-karry-915782166/",
        image: "drone/venkatesh.jpg",
      },
      {
        name: "Mr. Srinivas Mamidi",
        designation: "Founder",
        company: "GJ Drone Solutions Pvt.  Ltd",
        linkedIn: "https://www.linkedin.com/in/srinivas-mamidi-05405ba6/",
        image: "drone/srinivas.jpg",
      },
    ],
    contact: [
      {
        name: "Muppudathi",
        mobile: "90928 57226",
      },
      {
        name: "Tharani",
        mobile: "90032 20811",
      },
    ],
    mail: "drone@cegtechforum.in",
    dateTimeFees: {
      date: "April 6-7, 2022",
      fee: "Rs. 1750/-",
    },
    miscInstructions: ["Certificates along with OD will be provided."],
  },

  // Hand Gesture
  {
    price: 2499,
    teamRegPrice: 4499,
    isPriceComplex: true,
    eventcode: "HANDGESTURE",
    teamRegEventCode: "HANDGESTURET",
    participantsTypeFlag: "INDIVIDUAL&TEAM",
    participantsDetail: {
      minSize: 2,
      maxSize: 3,
    },
    regOpen: true,
    title: "Hand Gesture Robotics",
    path: "hand-gesture",
    filler: workshopsSpeakerImageBasePath + "hand-gesture/filler.png",
    oneLiner: "A workshop which covers the basics of hand gesture robotics.",
    description: [
      "This workshop extensively covers the basics of Hand gesture robotics and develops the concepts to other bot mechanisms.",
      "Areas like sensing system, microcontroller functions are explained with industry quality.",
      "To make this as an unforgettable learning, kits to build a hand gesture robot along with the best guidance are provided.",
    ],
    prerequisites: [
      "Your interest in building robots.",
      "Participants are required to bring their laptops",
    ],
    speakers: [
      {
        name: "Vaibhav Agrawal",
        designation: "Director",
        company: "Robotech Labs",
        linkedIn: "https://www.linkedin.com/in/vabs102/",
        image: "hand-gesture/vaibhav.jpg",
      },
    ],
    contact: [
      {
        name: "Jai Prasanth",
        mobile: "63794 87904",
      },
    ],
    mail: "robotics@cegtechforum.in",
    dateTimeFees: {
      date: "April 6-7, 2022",
      fee: "Rs. 2499/- (Individual) Rs. 4499/- (Team)",
    },
    miscInstructions: [
      "Certificates along with OD will be provided.",
      "Kits will be provided to the participants free of cost.",
    ],
  },

  // Metaverse
  {
    price: 950,
    eventcode: "METAVERSE",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Metaverse",
    path: "metaverse",
    filler: workshopsSpeakerImageBasePath + "metaverse/filler.jpg",
    oneLiner: "Unleash the Virtual World",
    description: [
      "Our workshop extensively covers the game engine principles of Unreal which is completely developed by metaverse.",
      "A broad introduction to the essentials of Unreal engine and guidance to create your own project in the metaverse is provided.",
      "Some of the key takeaways would be an in depth understanding of concepts like Physical Based Rendering(PBR) and Blueprint visual scripting.",
      "Industry level scope and standard of metaverse is detailly enclosed in this workshop.",
    ],
    prerequisites: ["Participants are required to bring their laptops"],
    speakers: [
      {
        name: "Karan Parikh",
        designation: "Unreal Authorised Instructor Partner",
        company: "Epic Games",
        linkedIn: "https://www.linkedin.com/in/karan-parikh-55a7a362/",
        image: "metaverse/karan.jpeg",
      },
      {
        name: "Sridharan Venkataramanan",
        designation: "Associate Vice President",
        company: "GlobalLogic",
        linkedIn: "https://www.linkedin.com/in/sridharan-venkataramanan/",
        image: "metaverse/Sridharan.png",
      },
    ],
    contact: [
      {
        name: "Arunachalam",
        mobile: "+91 86108 39747",
      },
    ],
    mail: "metaverse@cegtechforum.in",
    dateTimeFees: {
      date: "April 7, 2022",
      fee: "Rs. 949/-",
    },
    miscInstructions: ["Certificates along with OD will be provided."],
  },

  // UI/UX Design
  {
    price: 500,
    eventcode: "UIUXDESIGN",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "UI/UX Design",
    path: "uiux-design",
    filler: workshopsSpeakerImageBasePath + "uiuxdesign/filler.jpg",
    oneLiner: "",
    description: [
      "This workshop gives you a glimpse into the industry standards of UI/UX designs that are followed by most of the brands.",
      "An interactive Hands-on session is provided to learn the application of the skill.",
      "Concepts like empathy map, user flow, paper sketch, user journey and much more are detailly covered.",
    ],
    prerequisites: ["Participants are required to bring their laptops."],
    speakers: [
      {
        name: "Arjun Nagendran",
        designation: "",
        company: "",
        linkedIn: "https://www.linkedin.com/in/arjun-nagendiran-a98102134/",
        image: "uiuxdesign/arjun.jpeg",
      },
      {
        name: "Kalaiarasan Jayaraman",
        designation: "",
        company: "",
        linkedIn: "https://www.linkedin.com/in/kingofart/",
        image: "uiuxdesign/kalai.jpeg",
      },
    ],
    contact: [
      {
        name: "Revanth",
        mobile: "+91 9600738647",
      },
      {
        name: "Srinivasan",
        mobile: "+91 7867870490",
      },
    ],
    mail: "uiuxdesign@cegtechforum.in",
    dateTimeFees: {
      date: "April 8, 2022",
      fee: "Rs. 500/-",
    },
    miscInstructions: ["Certificates along with OD will be provided."],
  },

  // Creative Workshop
  {
    price: 200,
    eventcode: "CREATIVETHINKING",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Creative Thinking",
    path: "creativity",
    filler: workshopsSpeakerImageBasePath + "creativity/filler.jpeg",
    oneLiner: "",
    description: [
      "Our workshop provides an amazing way of interactive performance where participants can develop a mindset to reframe obstacles into opportunities,make decisions, generate ideas, and solve problems.",
      "You will explore and transform your own creativity through a series of exercises working with a team.",
      "There will be brief video clips at the beginning about creative thinking  where you will learn brainstorming/ideation techniques that help you in generating creative ideas and solutions followed by unique creativity games and exercises.",
      "Taking part in the workshop will build your creative confidence and discover your Creativity and Innovation styles and help you in productivity improvement.",
    ],
    prerequisites: [
      "The only prerequisite for this workshop is your curiosity.",
    ],
    speakers: [
      {
        name: "Prof. Vipinendran K",
        designation: "Director",
        company: "Centre for Alumni Relations and Corporate Affairs",
        linkedIn: "https://www.linkedin.com/in/vipinendran-kannan-67a37219b/",
        image: "creativity/vipin.jpeg",
      },
    ],
    contact: [
      {
        name: "Gayathri",
        mobile: "+91 91503 63862",
      },
      {
        name: "Rukshana Rani",
        mobile: "+91 91507 56345",
      },
    ],
    mail: "creativity@cegtechforum.in",
    dateTimeFees: {
      date: "April 8, 2022",
      fee: "Rs. 200/-",
    },
    miscInstructions: ["Certificates along with OD will be provided."],
  },
];
