import { React, useState, useEffect } from 'react'
import styles from "./ImageCarousel.module.css";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";

const ImageCarousel = ({data}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const length = data.length;

  useEffect(() => {
    const interval = setInterval(() => {
      moveRight();
    }, 5000);
    return () => clearInterval(interval);
  }, [imageIndex]);

  const moveLeft = () => {
    setImageIndex((imageIndex == 0) ? length - 1 : imageIndex - 1);
  };

  const moveRight = () => {
    setImageIndex((imageIndex == length - 1) ? 0 : imageIndex + 1);
  };

  const moveSlide = (index) => {
    setImageIndex(index);
  };

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 75) {
      moveRight();
    }
    if (touchStart - touchEnd < -75) {
      moveLeft();
    }
  }
  return (
    <div className={`${styles.slider}`}>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className={(index == imageIndex) ? `${styles.slide} ${styles.active}` : `${styles.slide}`}
          >
            <BsFillArrowLeftCircleFill className={`${styles.leftcircle}`} onClick={moveLeft} />
            <BsFillArrowRightCircleFill className={`${styles.rightcircle}`} onClick={moveRight} />
            {index == imageIndex &&
              <div className={`${styles.card}`}>
                <img
                  src={item.image}
                  alt={item.name}
                  className={`${styles.image}`}
                  onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
                  onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
                  onTouchEnd={() => handleTouchEnd()}
                />
                <div className={`${styles.text}`}>
                  <span className={`${styles.title}`}>{item.name}</span>
                  <span className={`${styles.subtitle}`}>{item.description}</span>
                  <div className={`${styles.dots}`}>
                    {Array.from({ length: length }).map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => moveSlide(index)}
                          className={(index == imageIndex) ? `${styles.dot} ${styles.active}` : `${styles.dot}`}
                        >
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            }
          </div>
        )
      })}
    </div>
  )
}
export default ImageCarousel;
