import { ACADEMIC_YEARS, COLLEGES, DEPARTMENTS } from "../data/FormData";

export const validateName = (name) => {
  if (name.length === 0) return false;
  return true;
};

export const validateEmail = (email) => {
  let regex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
};

export const validateContact = (contact) => {
  let regex = /^\d{10}$/;
  return regex.test(contact);
};

export const validateCollege = (collegeName) => {
  if (!COLLEGES.includes(collegeName)) return false;
  return true;
};

export const validateRoll = (roll) => {
  if (roll.length !== 10) return false;
  return true;
}

export const validateDob = (dob) => {
  let regex = /^\d{2}[-]\d{2}[-]\d{4}$/;
  return regex.test(dob);
}

export const validateDepartment = (department) => {
  if (!DEPARTMENTS.includes(department)) return false;
  return true;
};

export const validateYearOfStudy = (yearOfStudy) => {
  yearOfStudy = parseInt(yearOfStudy);
  if (!ACADEMIC_YEARS.includes(yearOfStudy)) return false;
  return true;
};

export const validatePassword = (password) => {
  if (password.length < 8) return false;
  return true;
};

export const validateCPassword = (password, cPassword) => {
  if (password !== cPassword) return false;
  return true;
};

export const validateBasedOnLength = (data, len) => {
  if (data.length < len) return false;
  return true;
};
