import React from "react";
import Heading from "../../../../components/Heading/Heading";
import styles from "./header.module.css";

const Header = () => {
  return (
    <div className={`${styles.headerWrapper}`}>
      <div className={`${styles.welcomeHeader}`}>
        <Heading text={"K! Talks"} />
      </div>
    </div>
  );
};

export default Header;
