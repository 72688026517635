import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../Heading/Heading";
import styles from "./ListEventsWorkshops.module.css";
import SimpleDetailHoverCard from "../SimpleDetailHoverCard/SimpleDetailHoverCard";
import hourGlass from "../../assets/images/hour_glass.png";
import { ToggleEventListDialog, ToggleEventListMenuDialog } from "../../App";
function ListEventsWorkshops({ type, data, moreData }) {
  const navigate = useNavigate();
  const navigateTo = (event) => {
    navigate(event.path);
  };

  const toggleEventListDialog = useContext(ToggleEventListDialog);
  const toggleEventListMenuDialog = useContext(ToggleEventListMenuDialog);

  useEffect(() => {
    toggleEventListMenuDialog(true);
    return () => {
      toggleEventListMenuDialog(false);
    };
  }, []);

  return (
    <div className={`${styles.wrapper} colorPaletteGradientBackground`}>
      <div className={`${styles.headerContainer}`}>
        <Heading text={type} />
      </div>
      <div className={`${styles.contentContainer}`}>
        <ul className={`${styles.cards}`}>
          {data.map((event, index) => {
            return (
              <li key={index}>
                <div className={`${styles.card}`}>
                  <SimpleDetailHoverCard
                    key={index}
                    data={event}
                    onClickMethod={navigateTo}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ListEventsWorkshops;
