import React, { useEffect, useState } from "react";
import styles from "./RegisterModal.module.css";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import StylishHeading from "../../StylishHeading/StylishHeading";
import GlitchButton from "../../GlitchButton/GlitchButton";
import FormField from "../../FormField/FormField";
import teamIcon from "../../../assets/images/LoginRegistration/department.png";
import nameIcon from "../../../assets/images/LoginRegistration/name.png";
import emailIcon from "../../../assets/images/LoginRegistration/email.png";
import phoneIcon from "../../../assets/images/LoginRegistration/phone.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
  showWarnToastNotification,
} from "../../ToastNotification";
import { validateEventRegistrationForm } from "../../../validators/authValidators";
import { isAuthDataStored } from "../../../utils/localStorageHelper";
import { apiEventRegister } from "../../../api/auth";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ReactTooltip from "react-tooltip";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const FORM_FIELDS = [
  {
    type: "text",
    fieldIcon: nameIcon,
    placeholder: "Name",
    name: "name",
  },
  {
    type: "text",
    fieldIcon: emailIcon,
    placeholder: "E-mail ID",
    name: "email",
  },
  {
    type: "text",
    fieldIcon: phoneIcon,
    placeholder: "Phone number",
    name: "phone",
  },
];

const detailsFormat = {
  name: "",
  email: "",
  phone: "",
};

const memberDetailsFormat = {
  ...detailsFormat,
  isFormOpen: true,
};

const registrationDetailsFormat = {
  teamCount: 2,
};

function RegisterModal({
  isOpen,
  modalData,
  eventData,
  clickedRegisterModalClose,
  changeRegistrationStatus,
}) {
  const [teamMemberDetails, setteamMemberDetails] = useState([]);
  const [registrationDetails, setregistrationDetails] = useState(
    registrationDetailsFormat
  );
  const [participantsType, setparticipantsType] = useState(
    eventData.participantsTypeFlag
  );
  const [isTeamSizeFixed, setisTeamSizeFixed] = useState(false);

  let userDetails = {
    name: "",
    email: "",
    phone: "",
  };

  const changeMembers = (prevState) => {
    let currentTeamCount = prevState.teamCount - 1;
    let membersCount = teamMemberDetails.length;
    let _teamMemberDetails = teamMemberDetails;

    // If prevCount same as newCount, return
    if (currentTeamCount === membersCount) return;

    // If newCount < prevCount
    if (currentTeamCount < membersCount) {
      while (membersCount !== currentTeamCount) {
        _teamMemberDetails.pop();
        membersCount--;
      }
    }
    // If newCount > prevCount
    else {
      while (membersCount !== currentTeamCount) {
        _teamMemberDetails.push({
          name: "",
          email: "",
          phone: "",
          isFormOpen: false,
        });
        membersCount++;
      }
    }
  };

  const changeFormState = (args) => {
    let prevState = registrationDetails;
    prevState[args.key] = args.value;
    changeMembers(prevState);
    setregistrationDetails({ ...prevState });
  };

  const changeTeamMemberDetails = (args) => {
    let prevState = teamMemberDetails;
    prevState[args.memberNo][args.key] = args.value;
    setteamMemberDetails([...prevState]);
  };
  // Toggle team members form open.close
  const toggleFormHeight = (index) => {
    let _teamMemberDetails = teamMemberDetails;
    // If form is already open, close it
    if (_teamMemberDetails[index].isFormOpen) {
      _teamMemberDetails[index].isFormOpen = false;
    }
    // If form is not already opened
    else {
      for (let i = 0; i < _teamMemberDetails.length; i++) {
        // Open the clicked form
        if (i === index) {
          _teamMemberDetails[i].isFormOpen = true;
        }
        // Close everything else
        else {
          _teamMemberDetails[i].isFormOpen = false;
        }
      }
    }

    setteamMemberDetails([..._teamMemberDetails]);
  };

  const generateDropdownList = (min, max) => {
    let res = [];
    for (let i = min; i <= max; i++) {
      res.push(i);
    }
    return res;
  };

  useEffect(() => {
    if (isAuthDataStored()) {
      // const parsedDetails = JSON.parse(localStorage.getItem("details"));
      // // userDetails = {
      // // 	name: parsedDetails.firstname,
      // // 	email: parsedDetails.email,
      // // 	phone: parsedDetails.phone,
      // // };
      // userDetails.name
      // console.log(userDetails);
    }

    if (eventData.participantsTypeFlag === "INDIVIDUAL&TEAM") {
      setparticipantsType("INDIVIDUAL");
    }

    if (
      participantsType !== "INDIVIDUAL" &&
      eventData.participantsDetail.minSize ===
        eventData.participantsDetail.maxSize
    ) {
      setisTeamSizeFixed(true);
      changeFormState({
        key: "teamCount",
        value: eventData.participantsDetail.minSize,
      });
    }

    return () => {};
  }, []);

  const clickedReg = async () => {
    let _teamDetails = teamMemberDetails.map(
      ({ isFormOpen, ...individualDetails }) => individualDetails
    );

    // {eventData.hasOwnProperty("isPriceComplex")
    //             ? participantsType === "INDIVIDUAL"
    //               ? eventData.price
    //               : eventData.teamRegPrice
    //             : eventData.price}

    let eventRegistrationDetails;

    if (participantsType === "INDIVIDUAL") {
      eventRegistrationDetails = {
        event: eventData.eventcode,
        team: [],
      };
    } else {
      eventRegistrationDetails = {
        event: eventData.eventcode,
        team: _teamDetails,
      };
    }

    if (
      eventData.hasOwnProperty("isPriceComplex") &&
      participantsType !== "INDIVIDUAL"
    ) {
      eventRegistrationDetails.event = eventData.teamRegEventCode;
    }

    let validation = validateEventRegistrationForm(eventRegistrationDetails);

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    let data = {
      ...eventRegistrationDetails,
    };

    let config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };

    const resp = await apiEventRegister(data, config);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        //TODO: pass a state prop from Template.js
        //and update registration status and other states accordingly
        showSuccessToastNotification(<p>{resp.data.message}</p>);
        changeRegistrationStatus();
        clickedRegisterModalClose();
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(
          <p>Server Error. Try again after sometime.</p>
        );
      }
    }
  };

  const clickedPay = async () => {
    //TODO: change payment link, and might have to add more actions
    showWarnToastNotification(<p>Opening payment portal, please wait...</p>);
    setTimeout(() => {
      window.open("https://www.instamojo.com/@instamojo/");
    }, 2500);
  };

  const clickedParticipantTypeRadio = (type) => {
    if (
      !eventData.hasOwnProperty("isPriceComplex") ||
      !eventData.isPriceComplex
    )
      return;
    if (type === participantsType) return;
    showWarnToastNotification(<p>Registration fee updated</p>);
  };

  const EventRegHandler = () => {
    switch (modalData.regStatus) {
      case "Register":
        return (
          <GlitchButton text={modalData.regStatus} onClickMethod={clickedReg} />
        );
      case "Pay":
        return (
          <GlitchButton text={modalData.regStatus} onClickMethod={clickedPay} />
        );
      case "Payment Failed":
        return <GlitchButton text={"Try again"} onClickMethod={clickedPay} />;
      case "Registered":
      default:
        return null;
    }
  };

  return (
    <Modal
      className={`${styles.modalWrapper} customScrollbar`}
      overlayClassName={`${styles.modalOverlay}`}
      isOpen={isOpen}
      closeTimeoutMS={200}
    >
      <div className={`${styles.modalContainer}`}>
        <CloseIcon
          onClick={clickedRegisterModalClose}
          className={`${styles.closeIcon}`}
        />
        <div className={`${styles.contentContainer}`}>
          <StylishHeading text={modalData.regStatus} />
          <ul className={`${styles.infoContainer}`}>
            <li>
              <PersonIcon className={`${styles.detailIcon}`} data-tip="Name" />
              &nbsp;{modalData.userDetails.name}
            </li>
            <li>
              <EmailIcon className={`${styles.detailIcon}`} data-tip="Email" />
              &nbsp;{modalData.userDetails.email}
            </li>
            <li>
              <PhoneAndroidIcon
                className={`${styles.detailIcon}`}
                data-tip="Phone"
              />
              &nbsp;{modalData.userDetails.phone}
            </li>
            <li>
              <CurrencyRupeeIcon
                className={`${styles.detailIcon}`}
                data-tip="Registration Fee"
              />
              &nbsp;
              {modalData.userDetails.cegian &&
              eventData.eventcode === "GENERALREGISTRATION"
                ? 0
                : eventData.hasOwnProperty("isPriceComplex")
                ? participantsType === "INDIVIDUAL"
                  ? eventData.price
                  : eventData.teamRegPrice
                : eventData.price}
              /-
            </li>
            <li>
              <EmojiEventsIcon
                className={`${styles.detailIcon}`}
                data-tip="Purpose"
              />
              &nbsp;
              {eventData.hasOwnProperty("isPriceComplex")
                ? participantsType === "INDIVIDUAL"
                  ? eventData.eventcode
                  : eventData.teamRegEventCode
                : eventData.eventcode}
            </li>

            <li>
              {eventData.participantsTypeFlag !== "TEAM" && (
                <div className={`${styles.participantRadio}`}>
                  <input
                    checked={participantsType === "INDIVIDUAL"}
                    id="individual"
                    type="radio"
                    value={"Individual"}
                    onChange={(e) => setparticipantsType("INDIVIDUAL")}
                    onClick={() => clickedParticipantTypeRadio("INDIVIDUAL")}
                  />
                  <label for="individual">Individual</label>
                </div>
              )}
              {eventData.participantsTypeFlag !== "INDIVIDUAL" && (
                <div className={`${styles.participantRadio}`}>
                  <input
                    checked={participantsType === "TEAM"}
                    id="team"
                    type="radio"
                    value={"Team"}
                    onChange={(e) => setparticipantsType("TEAM")}
                    onClick={() => clickedParticipantTypeRadio("TEAM")}
                  />
                  <label for="team">Team</label>
                </div>
              )}
            </li>
            {}
            <li>
              {modalData.regStatus === "Register" &&
                participantsType !== "INDIVIDUAL" && (
                  <div className={`${styles.teamMemberDetailsContainer}`}>
                    {!isTeamSizeFixed && (
                      <FormField
                        type={"dropdown"}
                        fieldIcon={teamIcon}
                        placeholder={"No. of Team Members"}
                        name={"teamCount"}
                        dropdownValues={generateDropdownList(
                          eventData.participantsDetail.minSize,
                          eventData.participantsDetail.maxSize
                        )}
                        value={registrationDetails}
                        setter={changeFormState}
                        width={"modal"}
                      />
                    )}

                    {teamMemberDetails.map((member, index1) => {
                      return (
                        <div
                          key={index1}
                          className={`${styles.detailsContainer}`}
                        >
                          <div className={`${styles.formHeader}`}>
                            <p>Teammate {index1 + 1}</p>
                            <KeyboardArrowDownIcon
                              onClick={() => toggleFormHeight(index1)}
                              className={`
													${styles.formIcon}
													${member.isFormOpen && `${styles.formIconInverse}`}`}
                            />
                          </div>
                          <div
                            className={`${styles.formWrapper}`}
                            style={{
                              display: member.isFormOpen ? "inherit" : "none",
                            }}
                          >
                            {FORM_FIELDS.map((field, index2) => {
                              return (
                                <FormField
                                  key={index2}
                                  type={field.type}
                                  fieldIcon={field.fieldIcon}
                                  placeholder={field.placeholder}
                                  name={field.name}
                                  value={member}
                                  setter={changeTeamMemberDetails}
                                  misc={{ memberNo: index1 }}
                                  width={"modal"}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
            </li>
            <li>
              <EventRegHandler />
            </li>
          </ul>
        </div>
      </div>
      <ReactTooltip />
    </Modal>
  );
}

export default RegisterModal;
