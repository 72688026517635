import React from "react";
import styles from "./RegisterButton.module.css";
import { FaArrowRight } from "react-icons/fa";

export default function RegisterButton({ text, onClickMethod }) {
  return (
    <div className={`${styles.regBtnContainer}`} onClick={onClickMethod}>
      <span className={`${styles.title}`}>{text}</span>
      <span>
        <FaArrowRight size={10} />
      </span>
    </div>
  );
}
