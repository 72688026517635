import React, { useEffect, useRef } from "react";
import {
  FaUserAlt,
  FaEnvelopeOpen,
  FaMobileAlt,
  FaCalendarAlt,
  FaClock,
} from "react-icons/fa";
import styles from "./information.module.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { INFORMATION } from "../../../../data/GuestLectures";

const Information = () => {
  const infoRef = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.matchMedia({
      all: function () {
        gsap.from(infoRef.current.children, {
          scrollTrigger: {
            trigger: infoRef.current,
            start: "top 90%",
            end: "bottom bottom",
            toggleActions: "play complete reverse none",
          },
          scale: 0.3,
          opacity: 0,
        });
      },
    });
  });
  return (
    <div className={`${styles.info_wrapper}`}>
      <div className={`${styles.information}`} ref={infoRef}>
        <div className={`${styles.datetime}`}>
          {/* <h2>When?</h2>
          <p className={`${styles.date}`}>
            <FaCalendarAlt />
            &nbsp;&nbsp;&nbsp;{INFORMATION.date}
          </p>
          <p className={`${styles.time}`}>
            <FaClock />
            &nbsp;&nbsp;&nbsp;{INFORMATION.time}
          </p> */}
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/Logos/k_talks_white.png`}
            className={`${styles.kTalkLogo}`}
            alt="K Talks"
          />
        </div>
        <div className={`${styles.contact}`}>
          <h2>Need more info?</h2>
          <p className={`${styles.contact_name}`}>
            <FaUserAlt />
            &nbsp;&nbsp;&nbsp;{INFORMATION.contact.name}
          </p>
          <p className={`${styles.contact_phone}`}>
            <FaMobileAlt />
            &nbsp;&nbsp;&nbsp;{INFORMATION.contact.mobile}
          </p>
          <p className={`${styles.contact_mail}`}>
            <FaEnvelopeOpen />
            &nbsp;&nbsp;&nbsp;guestlectures@cegtechforum.in
          </p>
        </div>
      </div>
    </div>
  );
};

export default Information;
