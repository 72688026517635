import axios from "axios";

const url_login = "api/user/login";
const url_register = "api/user/register";
const url_cegianRegister = "api/user/cegianRegister";
const url_checkRegister = "api/user/checkemail";
const url_forgotPassword = "api/user/forgotPassword";
const url_resetPassword = "api/user/resetPassword";

const url_cegianVerify = "api/user/cegianVerify";

const url_googleSignin = "api/auth/googlesignin";
const url_googleRegistrationData = "api/auth/googledata";

const url_eventRegister = "api/event/register";
const url_getEventRegistrations = "api/event/getRegistrations";
const url_contact = "api/user/query";

const url_Pay = "api/payment/pay";

const url_TechnovationRegistration = "api/event/project-register";

const url_Accomodation = "api/event/accomodation-register";

const api = axios.create({
  // baseURL: "http://localhost:3002/",
  baseURL: "https://api.kurukshetraceg.org.in/",
});

export const apiLogin = async (data) => {
  try {
    const response = await axios.post(
      `https://api.kurukshetraceg.org.in/${url_login}`,
      data
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiCheckRegister = async (data) => {
  try {
    const response = await api.post(`${url_checkRegister}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiRegister = async (data) => {
  try {
    const response = await api.post(`${url_register}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiCegianRegister = async (data) => {
  try {
    const response = await api.post(`${url_cegianRegister}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiForgotPassword = async (data) => {
  try {
    const response = await api.post(`${url_forgotPassword}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiResetPassword = async (data) => {
  try {
    const response = await api.post(`${url_resetPassword}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiGoogleSignin = () => {
  // window.location = "http://localhost:3001/" + url_googleSignin;
  window.location = "https://api.kurukshetraceg.org.in/" + url_googleSignin;
};

export const apiGoogleRegistrationData = async (data) => {
  try {
    const response = await api.post(`${url_googleRegistrationData}`, data, {
      headers: {
        Authorization: data.token,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiEventRegister = async (data, config) => {
  try {
    const response = await api.post(`${url_eventRegister}`, data, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiGetEventRegistrations = async (config) => {
  try {
    const response = await api.post(`${url_getEventRegistrations}`, {}, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiPay = async (data, config) => {
  try {
    const response = await api.post(`${url_Pay}`, data, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiSendContactMessage = async (data) => {
  try {
    const response = await api.post(`${url_contact}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiCegianVerify = async (data, config) => {
  try {
    const response = await api.post(`${url_cegianVerify}`, data, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiTechnovationRegistration = async (data, config) => {
  try {
    const formData = new FormData();

    for (let i = 0; i < data.projectImages.length; i++)
      formData.append("projectImages", data.projectImages[i]);

    formData.append("abstractPDF", data.abstractPDF);
    formData.append("data", JSON.stringify(data.data));

    const response = await api.post(
      `${url_TechnovationRegistration}`,
      formData,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiAccomodationRegistration = async (data, config) => {
  try {
    const formData = new FormData();

    formData.append("govID", data.govtIdPDF);
    formData.append("collegeID", data.collegeIdPDF);
    formData.append("data", JSON.stringify(data.data));

    const response = await api.post(`${url_Accomodation}`, formData, config);
    return response;
  } catch (error) {
    return error.response;
  }
};
