import React, { useState, useRef } from "react";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import styles from "./Contact.module.css";

import ReCAPTCHA from "react-google-recaptcha";
import FormField from "../../components/FormField/FormField";
import nameIcon from "../../assets/images/LoginRegistration/name.png";
import emailIcon from "../../assets/images/LoginRegistration/email.png";
import messageIcon from "../../assets/images/LoginRegistration/message.png";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from "../../components/ToastNotification";
import { validateContactForm } from "../../validators/contactFormValidator";
import { apiSendContactMessage } from "../../api/auth";

const contactDetailsFormat = {
  name: "",
  email: "",
  message: "",
};

function ContactForm() {
  let reCaptchaRef = useRef(null);

  const [contactDetails, setcontactDetails] = useState(contactDetailsFormat);

  const [loader, setloader] = useState(false);

  const changeContactFormState = (args) => {
    let prevState = contactDetails;
    prevState[args.key] = args.value;
    setcontactDetails({ ...prevState });
  };

  const clickedSubmit = async () => {
    // Form Validation
    let validation = validateContactForm(contactDetails);

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    // reCaptcha Validation
    if (reCaptchaRef.current.getValue() === "") {
      showErrorToastNotification(<p>reCaptcha verification failed</p>);
      return;
    }

    setloader(true);

    const resp = await apiSendContactMessage({
      ...contactDetails,
      captcha: reCaptchaRef.current.getValue(),
    });

    reCaptchaRef.current.reset();

    setloader(false);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        showSuccessToastNotification(<p>{resp.data.message}</p>);
        setcontactDetails(contactDetailsFormat);
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  return (
    <>
      {loader && <SimpleLoader text={"Sending your message"} />}
      <div
        style={{ display: loader ? "none" : "flex" }}
        className={`${styles.formWrapper}`}
      >
        <FormField
          type={"text"}
          fieldIcon={nameIcon}
          placeholder="Name"
          name="name"
          value={contactDetails}
          setter={changeContactFormState}
        />
        <FormField
          type={"text"}
          fieldIcon={emailIcon}
          placeholder="Email"
          name="email"
          value={contactDetails}
          setter={changeContactFormState}
        />
        <FormField
          type={"textarea"}
          fieldIcon={messageIcon}
          placeholder="Your Message"
          name="message"
          value={contactDetails}
          setter={changeContactFormState}
        />
        <div className={`${styles.recaptcha_container}`}>
          <ReCAPTCHA
            sitekey="6LcMoTUdAAAAAGFo2lgEFl5sIpitgdT-lExG05FL"
            theme="dark"
            size="compact"
            className={`${styles.recaptcha}`}
            ref={reCaptchaRef}
          />
        </div>
        {/* <div> */}
        <GlitchButton text={"Submit"} onClickMethod={clickedSubmit} />
        {/* </div> */}
      </div>
    </>
  );
}

export default ContactForm;
