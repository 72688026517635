const localImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Events/extravaganza/`;
export const EXTRAVAGANZA_EVENTS = [
  // Deziganation
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Dezignation",
    path: "dezignation",
    filler: localImageBasePath + "dezignation/filler.jpg",
    oneLiner: "",
    description: [
      "Have you ever wanted your Shade not to Fade? Hurray!! We got you covered.",
      "Put your arts out of your hearts digitally. Yes, you heard it right.",
      "Digital design is an artform of painting but with paint that never dries.",
      "This event is for those dudes out there having the most beautiful perspective in their lives, being creative enough to turn any topic into most appealing form of art.",
      "Boom us with your mind-blowing creativity knocking out the rest.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "The theme for the contest is announced and the teams are split under three categories: Advert/Poster/Editing.",
          "Are you not satisfied with the topic that you got? Let’s add some fun then.",
          "To get your desired topic you get to engage in little fun task in which one of your team-mates have to transfer a small plastic ball from the top of a bottle on one end of the room to the top of bottle at the other end by using just a plastic straw without any contact.",
          "Each team has to come up with a design and equal number of participant from each category (depends on participation count) will be shortlisted for round 2.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "Each participating team is given a bundle of images.",
          "They have to come up with a design for the given theme using any designing software (Photoshop/Illustrator).",
          "Doesn’t it sound easy? Well, here is the catch, randomly a new image will be given in between which has to be included in the design which tests participants capability.",
          "The designs are evaluated in terms of creativity, how it adheres to the theme, technical skills used etc.",
        ],
      },
    ],
    rules: [
      "Students have must a valid K! ID.",
      "An artwork must adhere to the theme chosen by the team.",
      "Organizers can disqualify a team that violates any of the rules.",
      "Organizers will clarify participants queries even during the event.",
      "Decisions made by the organisers shall be final. ",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    prerequisites: [
      "Participants are required to bring their own laptops with the required softwares (Adobe Photoshop/Adobe Illustrator/Procreate).",
    ],
    contact: [
      {
        name: "Jyotir Aditya Giri ",
        mobile: "+91 8056264046 ",
      },
      {
        name: "Tharun V Darshan",
        mobile: "+91 7092343400",
      },
    ],
    mail: "dezignation@cegtechforum.in",
  },

  // Sherlock
  {
    price: 0,
    eventcode: "SHERLOCK",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: false,
    title: "Sherlock",
    path: "sherlock",
    filler: localImageBasePath + "sherlock/filler.jpg",
    oneLiner: "",
    description: [
      "Thriller and crime have always been a fascination since ages for all age groups.",
      "The game goes live on March 24, 2022.",
      "The name 'Sherlock' itself is enough to excite our brains to wear the shoes of a detective.",
      "This event 'Sherlock' makes you wear a cap, boots and a coat and carry a magnifying lens to solve the obscure mysteries around you.",
      "Thus, it’s time for the Sherlock in you to take action.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "Sherlock is an online game where a criminal case needs to be solved.",
          "Sherlock is a single player game.",
          "The participants can play the game either as Sherlock or Watson.",
          "The game will have a total of 15 levels.",
          "Each level will have one or two questions.",
          "To advance to the next level, you must correctly answer the questions.",
        ],
      },
    ],
    rules: [
      "The participants should register individually only.",
      "Students must have a valid k! Id.",
      "In case of any dispute, organizers decision will be final.",
      "Points will be awarded based on the number of attempts, the use of hints, and the accuracy of the answer.",
      "Sherlock Website will be active for 2 days and the participants are required to submit before the due date.",
    ],
    contact: [
      {
        name: "Anusuya.SLK ",
        mobile: "+91 9150578473",
      },
      {
        name: "Adarshini",
        mobile: "+91 7010372669",
      },
      {
        name: "Divya Priya",
        mobile: "+91 9150840286",
      },
      {
        name: "Gowri Yokesh Kumar S",
        mobile: "+91 6383236636",
      },
    ],
    mail: "sherlock@cegtechforum.in",
    dateTimeFees: {
      date: "March 24, 2022",
    },
  },

  // Alcatraz
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Alcatraz",
    path: "alcatraz",
    filler: localImageBasePath + "alcatraz/filler.jpg",
    oneLiner: "",
    description: [
      "Alcatraz is an event based on prison-break theme. ",
      "Basic rounds will be a test of your puzzle solving skills.",
      "The first round is presentation based.",
      "The participants are supposed to be good in teamwork to be able to pass through these rounds flawlessly.",
      "The participants who scored well will be qualified for the next rounds.",
      "The final round will be a prison breaking or an escape room round.",
      "In this level participants will be expected to solve the questions and connect the clues to get out of the room (prison).",
      "The team which finds the key fastest will be declared as the winner.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "Round 1 is a presentation type round where a paragraph shall be presented to the participants.",
          "The paragraph given will have hidden phrases in them and each team will be given a single chance to say the correct phrases, the team which says the exact phrases will move on to the next round.",
        ],
      },
      {
        roundName: "Round 2 - Eldrow",
        description: [
          "Basically, this is a reversed wordle that is why we have named it as eldrow. ",
        ],
      },
      {
        roundName: "Round 3 - Finale",
        description: [
          "The last round is the so called “ESCAPE ROOM”",
          "The room will be decorated in a prison theme.",
          "From each team a member is selected to be handcuffed. The keys are placed in a container and cannot be picked using hands.",
          "The teams are sent in the room filled with puzzles and clues.",
          "On solving these puzzles, they gain the materials to pick the keys on the way.",
          "The puzzles vary from chess pieces to balloons and at the end, the team which releases their teammate after solving all the puzzles the fastest will be considered the winner.",
          "This round is a time based and the fastest team wins.",
        ],
      },
    ],
    rules: [
      "Teams must be of at least 2 members with a maximum of 4 members.",
      "Students must have a valid K! ID.",
      "Mobile Phones are not allowed while playing.",
      "Discussion between other teams is strictly prohibited",
      "Timings must be followed correctly, and no extra time shall be given ",
      "The organizers’ decision is final.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "M. Prem Kumar",
        mobile: "+91 9176000385",
      },
      {
        name: "Jeeva S",
        mobile: "+91 7339318093",
      },
    ],
    mail: "alcatraz@cegtechforum.in",
    dateTimeFees: {
      date: "April 6-7, 2022",
    },
  },

  // Vice and Virtues
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Vices and Virtues",
    path: "vices-and-virtues",
    filler: localImageBasePath + "vices-and-virtues/filler.jpg",
    oneLiner: "",
    description: [
      "Vices and Virtues is an event which focuses mainly on the observing skill, logical and analytical thinking of the participants",
      "This is an event where there exists a perfect harmony between learning and fun.",
      "This event consists of two rounds in which the first round will be Third Eye which tests the observing skill and decoding capacity of the participants.",
      "Final round is based on the Monopoly game but with a twist. ",
      "It is a customised board having bonuses and obstacles along the way.",
      "The participants will be playing with a life-sized dice and will advance to next positions by answering logical, technical and general knowledge questions which were placed in the obstacles.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Third Eye",
        description: [
          "Participants will be divided into groups and every participant in the team gets a chit. ",
          "First one should start solving the question which is given in the chit. ",
          "Once he/she solves he/she gets a clue, that clue will remain among the team only.",
          "From that clue the first one and second one will find the third clue and this continues. ",
          "Once all the questions are solved, a clue will be given for a demon card. ",
          "The team which finds the demon card is qualified for the next segment.",
        ],
      },
      {
        roundName: "Round 2 - Monopoly",
        description: [
          "The Qualified team plays this game.",
          "The main aim of this game is to make the participant overcome all the obstacles and the bonuses given.",
          "The participant who makes a full round giving all the right answers will be declared the winner.",
        ],
      },
    ],
    rules: [
      "Participants must be at the venue on time.",
      "Students must have a valid K! ID.",
      "Any malpractice will lead to disqualification of the team from the event.",
      "Each round must be completed within the stipulated time.",
      "Organizer’s decision is final.",
      "Participants from all departments are most welcomed.",
      "Mobile phones are strictly restricted.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "K.M.Ramya ",
        mobile: "+91 7995211207",
      },
      {
        name: "Aravindh Guru ",
        mobile: "+91 6383808655",
      },
    ],
    mail: "vicesandvirtues@cegtechforum.in",
    dateTimeFees: {
      date: "April 8, 2022",
    },
  },

  // Memory List
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Memory List",
    path: "memory-list",
    filler: localImageBasePath + "memory-list/filler.jpg",
    oneLiner: "",
    description: [
      "Memory List is an effective brain training fun event, which is mainly concerned on boosting the brain and to improve the brain functions such as concentration, memorizing power and focus of a person.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "In memory list event we have attempted to conduct three rounds.",
          "In the first-round participants must memorize as many technical and complicated words listed on the screen within the time limit.",
          "Then there will be a short interval for about 20 seconds.",
          "Once it ends participants must write the memorized words within a particular time on a paper which will be provided.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "In the second-round participants are required to go through two levels, firstly they should attempt to memorize the listed words with its letters reversed.",
          "Then they have to try to memorize words with their letters jumbled, which will be displayed on the screen within the time limit.",
          "Again, a time limit will be given, then the participants must write the memorized words within a stipulated time on a paper.",
        ],
      },
      {
        roundName: "Round 3",
        description: [
          "In the third-round participants will be shown a set of pictures and they need to observe it keenly in the time provided.",
          "Questions will be asked from the pictures shown.",
          "Only the selected participants from the second round can play.",
          "The participant who answers the greatest number of questions correctly will be given prizes.",
        ],
      },
    ],
    rules: [
      "Usage of mobile phones is strictly prohibited.",
      "Students must have a valid K! ID.",
      "Malpractice is to be completely avoided.",
      "Participants can compete individually. Teams are not allowed.",
      "Participants can write answers only in the stipulated time.",
      "The participant who rings the buzzer first is allowed to answer first in the third round.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Sheela Santha Kumari ",
        mobile: "+91 9159147212",
      },
      {
        name: "Vedha",
        mobile: "+91 9677451757",
      },
    ],
    mail: "memorylist@cegtechforum.in",
    dateTimeFees: {
      date: "April 7, 2022",
    },
  },

  // Mr Cooper and his mystery
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: false,
    title: "Mr. Cooper and his mystery",
    path: "cooper-mystery",
    filler: localImageBasePath + "cooper-mystery/filler.jpg",
    oneLiner: "",
    description: [
      "This event is about crime detection like a real detective. Here the crime story will be given to the participants.",
      "Participants act as the detective agency to whom the records and files related to the crime will be given.",
      "In addition to that, the crime scene also will be given to them, with which they should relate the crime records and files and find out who the criminal is and how the crime would have taken place.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "A case report file with information about the crime scene will be handed to participants to solve the case.",
          "For further files and evidence (like autopsy reports, call recordings), participants will have to go to a specific location.",
          "Location will be accessible through QR codes provided to them.",
          "To connect the dots and solve the case, they must search the crime scene for evidence.",
          "In addition to this, there are some other pending cases that they shall solve to earn extra points to make it through the first round.",
          "Points will be awarded based on the number of cases solved.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "A serial killer murdered some citizens and left some clues and riddles with each murder.",
          "All his clues are encrypted. The cops can’t decrypt his clues nor solve the riddles.",
          "With a dangerous criminal’s identity remains unknown, the citizens are scared. So, the cops asked you for help.",
        ],
      },
    ],
    rules: [
      "Usage of mobile phones is strictly prohibited.",
      "Participants must have a valid K! Id",
      "Malpractice should be completely avoided.",
      "Everyone should participate as a team (maximum of 4 members).",
      "The decision made by the organizers will be final.",
    ],
    contact: [
      {
        name: "Narendira Kumar A",
        mobile: "+91 93606 12317",
      },
      {
        name: "Srihari T",
        mobile: "+91 88256 56830",
      },
    ],
    mail: "events@cegtechforum.in",
  },

  // K! FIESTA
  {
    price: 0,
    eventcode: "ONLINE",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: false,
    title: "K! Fiesta",
    path: "k-fiesta",
    filler: localImageBasePath + "k-fiesta/filler.jpg",
    oneLiner:
      "An event with a set of fun-filled games to happen in one of the hotspots of the college.",
    description: [
      "Get ready to revisit the good old fun days. Time to play them all one more time and experience the fun you might have missed all these days.",
      "An event with a set of fun-filled games to happen in one of the hotspots of the college.",
    ],
    rounds: [
      {
        roundName: "Round 1 - SAY IT LOUD!",
        description: [
          "In this game, one person puts on earphones/headphones with music playing at a volume high enough that they can’t hear anything.",
          "The other person will then say a word or phrase, and the person with the headphones has to guess what the other person said.",
          "Correct guess scores one point, partially correct guess gives you half a point and wrong guess has zero points.",
          "The time limit is 1 minute, within one minute the team with the greatest number of points wins.",
        ],
      },
      {
        roundName: "Round 2 - IRRELEVANT ANSWER",
        description: [
          "Two balls are given to a player and he has to juggle the balls and answer the questions asked.",
          "Answers must be irrelevant to the questions.",
        ],
      },
      {
        roundName: "Round 3 - PIC-CHARADES",
        description: [
          "A team of two is selected and a figure is given to one of the teammates.",
          "The teammate should explain the diagram without using their hands.",
          "The other teammate should draw the diagram similarly. (Similar to Pictionary).",
          "The teammate who is explaining the figure should not see what the other teammate is drawing. Based on the time taken to draw the figure, the points can be awarded.",
          "A time limit of 2 minutes will be given.",
        ],
      },
      {
        roundName: "Round 4 - BUZZ WIRE",
        description: [
          "Buzz wire is a challenging and competitive game.",
          "The object of this game is to carefully move the wire wand loop along the copper wire “maze” without touching it.",
          "When the loop touches the wire, you will hear a “BUZZ”",
          "The goal is to be fast and at the same time to be efficient, having the least number of touches. A mix of speed and skill.",
          "Along with these the participants should have to repeat a tongue twister given by the organizers.",
        ],
      },
    ],
    rules: [
      "Participants must have a valid K! ID.",
      "Cross college teams are allowed.",
      "The organizers' decision will be final.",
    ],
    contact: [
      {
        name: "Pratibha Senthil",
        mobile: "+91 9490365430",
      },
      {
        name: "Jemini M",
        mobile: "+91 8870779958",
      },
    ],
    mail: "events@cegtechforum.in",
  },
];
