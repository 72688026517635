import React, { useEffect } from "react";
import { DOMAINS } from "../../../data/CreativeTeam";
import styles from "./DomainNav.module.css";

function DomainNav({ currentDomain, toggleImages, setcurrentDomain }) {
  let domainNavRefs = new Array(DOMAINS.length);

  useEffect(() => {
    domainNavRefs[0].classList.add(`colorPaletteGradientBackground`);
    domainNavRefs[0].classList.remove(`${styles.domainContainerOff}`);
    domainNavRefs[0].children[0].classList.remove(
      `colorPaletteGradientBackground`
    );

    return () => {};
  }, []);

  const clickedDomain = (newDomain, index) => {
    if (newDomain === currentDomain.name) return;

    // For new domain
    domainNavRefs[index].classList.add(`colorPaletteGradientBackground`);
    domainNavRefs[index].classList.remove(`${styles.domainContainerOff}`);
    domainNavRefs[index].children[0].classList.remove(
      `colorPaletteGradientBackground`
    );

    // For current/old domain
    domainNavRefs[currentDomain.index].classList.remove(
      `colorPaletteGradientBackground`
    );
    domainNavRefs[currentDomain.index].classList.add(
      `${styles.domainContainerOff}`
    );
    domainNavRefs[currentDomain.index].children[0].classList.add(
      `colorPaletteGradientBackground`
    );

    toggleImages(newDomain, currentDomain.name);
    setcurrentDomain({
      name: newDomain,
      index: index,
    });
  };

  return (
    <div className={`${styles.wrapper}`}>
      {DOMAINS.map((domain, index) => {
        return (
          <div
            key={index}
            onClick={() => clickedDomain(domain.name, index)}
            className={`${styles.domainContainer} ${styles.domainContainerOff}`}
            ref={(el) => (domainNavRefs[index] = el)}
          >
            <img
              src={domain.icon}
              alt={domain.name}
              className={`colorPaletteGradientBackground`}
            />
            <span>{domain.name}</span>
          </div>
        );
      })}
    </div>
  );
}

export default DomainNav;
