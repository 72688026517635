import React, { useEffect, useState } from "react";
import { EVENT_DATA_MAPPING } from "../../../../../utils/eventMapper";
import styles from "./GenReg.module.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import {
  apiEventRegister,
  apiGetEventRegistrations,
  apiPay,
} from "../../../../../api/auth";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from "../../../../ToastNotification";
import {
  DASHBOARD_BG_COLOR_MAPPER,
  DASHBOARD_COLOR_MAPPER,
} from "../../../../../utils/dashboardColorMapper";
import { makeStyles } from "@material-ui/core/styles";
import RegisterButton from "../../../components/RegisterButton/RegisterButton";
import { AiOutlineDollar } from "react-icons/ai";

const useStyles = makeStyles({
  tableData: {
    color: "#FFFFFF",
    fontFamily: "PrimaryFont",
    fontWeight: "600",
    fontSize: "0.9rem",
  },
  status: {
    fontFamily: "PrimaryFont",
    fontSize: "0.9rem",
    padding: "0.25rem 0.75rem",
    fontWeight: "600",
    borderRadius: "0.5rem",
    display: "inline-block",
    textAlign: "center",
  },
  accordian: {
    backgroundColor: "#262626",
    color: "#FFFFFF",
    borderRadius: "0.5rem",
    margin: "0.5rem 0",
  },
  tableLink: {
    margin: "1rem 0",
    fontFamily: "PrimaryFont",
    fontWeight: "600",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
  },
  tableLinkSpan: {
    display: "flex",
    alignItems: "center",
  },
});

function GenReg() {
  let profileDetails = JSON.parse(window.localStorage.getItem("details"));
  const navigate = useNavigate();
  const classes = useStyles();

  const [paid, setpaid] = useState(false);
  const [registered, setregistered] = useState(false);
  const [displayInfo, setdisplayInfo] = useState({
    name: "Not Registered",
    colorFlag: "red",
  });

  useEffect(async () => {
    const resp = await apiGetEventRegistrations({
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    if (
      (resp.status >= 400 && resp.status < 500) ||
      (resp.status >= 500 && resp.status < 600)
    ) {
      showErrorToastNotification(<p>{resp.data.message}</p>);
      return;
    }

    let regs = resp.data;

    for (let i = 0; i < regs.length; i++) {
      let reg = regs[i];

      // Registered
      if (reg.purpose === "GENERALREGISTRATION") {
        setregistered(true);
        // Paid
        if (reg.status === "Credit") {
          setpaid(true);
          setdisplayInfo({
            name: "Paid",
            colorFlag: "green",
            paymentId: reg.paymentid,
          });
        }
        // Not Paid
        else {
          setpaid(false);
          if (profileDetails.cegian === "true") {
            setdisplayInfo({
              name: "Registered as CEGIAN",
              colorFlag: "green",
            });
          } else {
            setdisplayInfo({
              name: "Registered. Yet to pay",
              colorFlag: "yellow",
            });
          }
        }
        break;
      }
    }

    return () => {};
  }, []);

  const paymentInfoRender = (status) => {
    switch (status.name) {
      case "Not Registered":
        return (
          <RegisterButton text={"Register"} onClickMethod={clickedRegister} />
        );
      case "Registered. Yet to pay":
        return profileDetails.cegian === "true" ? null : (
          <RegisterButton text={"Pay"} onClickMethod={clickedPay} />
        );
      case "Paid":
        return (
          <span className={`${styles.icon}`}>
            <AiOutlineDollar size={25} data-tip="Payment ID" />
            &nbsp;{status.paymentId}
          </span>
        );
      default:
        return null;
    }
  };

  const clickedPay = async () => {
    let resp = await apiPay(
      {
        event: "GENERALREGISTRATION",
      },
      {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }
    );

    // If errors
    if (
      (resp.status >= 400 && resp.status < 500) ||
      (resp.status >= 500 && resp.status < 600)
    ) {
      showErrorToastNotification(<p>{resp.data.message}</p>);
      return;
    }

    // Success ==> Redirect
    window.location = resp.data.link;
  };

  const clickedRegister = async () => {
    let config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };

    const resp = await apiEventRegister(
      { event: "GENERALREGISTRATION" },
      config
    );

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        showSuccessToastNotification(<p>{resp.data.message}</p>);
        setregistered(true);
        setpaid(false);
        if (profileDetails.cegian === "true") {
          setdisplayInfo({
            name: "Registered as CEGIAN",
            colorFlag: "green",
          });
        } else {
          setdisplayInfo({
            name: "Registered. Yet to pay",
            colorFlag: "yellow",
          });
        }
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.eventsContainer}`}>
        {Object.entries(EVENT_DATA_MAPPING).map((domain, index1) => {
          return domain[1].map((item, index2) => {
            if (item.eventcode !== "GENERALREGISTRATION") return null;
            return (
              <p
                className={`${styles.eventLink}`}
                onClick={() => {
                  navigate(`/events/${domain[0]}/${item.path}`);
                }}
                key={index2}
              >
                <ArrowRightIcon className={`${styles.arrowIcon}`} />
                {item.title}
              </p>
            );
          });
        })}
      </div>
      <div className={`${styles.regInfoContainer}`}>
        <div className={`${styles.statusContainer}`}>
          <Typography
            style={{
              backgroundColor: DASHBOARD_BG_COLOR_MAPPER[displayInfo.colorFlag],
              color: DASHBOARD_COLOR_MAPPER[displayInfo.colorFlag],
            }}
            className={classes.status}
          >
            {displayInfo.name}
          </Typography>
        </div>
        <div className={`${styles.buttonContainer}`}>
          {paymentInfoRender(displayInfo)}
        </div>
      </div>
    </div>
  );
}

export default GenReg;
