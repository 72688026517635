import React from "react";
import Heading from "../../components/Heading/Heading";
import {
  CEG_TECH_FORM_URL,
  CONTACTDETAILS,
  SOCIALMEDIA,
} from "../../data/ContactDetails";
import styles from "./Contact.module.css";
import ContactForm from "./ContactForm";

function Contact() {
  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.innerWrapper}`}>
        <div className={`${styles.infoContainer}`}>
          <div className={`${styles.headingContainer}`}>
            <Heading text={"Contact Us"} />
          </div>
          <p className={`${styles.boldText}`}>
            Have any questions or comments ?
          </p>
          <p className={`${styles.boldText}`}>Drop us a line</p>
          <p className={`${styles.normalText}`}>
            Feel free to contact us for any issues or clarifications. We would
            be happy to answer your questions and clarify your doubts.
          </p>

          {/* Contact Details */}
          <div className={`${styles.contactDetailsBox}`}>
            {CONTACTDETAILS.map((contact, index) => {
              return (
                <div key={index}>
                  <p>{contact.name}</p>
                  <p>{contact.mobile}</p>
                </div>
              );
            })}
          </div>

          {/* Social Media */}
          <div>
            <h3 className={`${styles.subHeading}`}>CONNECT</h3>
            <div className={`${styles.socialMediaIcons}`}>
              {SOCIALMEDIA.map((item, index) => {
                return (
                  <img
                    onClick={() => window.open(item.url)}
                    key={index}
                    src={item.icon}
                    alt={item.network}
                  />
                );
              })}
            </div>
          </div>

          {/* About CTF */}
          <div>
            <h3 className={`${styles.subHeading}`}>ABOUT CTF</h3>
            <p
              className={`${styles.aboutCTF}`}
              onClick={() => window.open(CEG_TECH_FORM_URL)}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/ContactUs/link.png`}
                alt={"CEG Tech Forum"}
              />
              &nbsp;CEG Tech Forum
            </p>
          </div>
        </div>
        <div className={`${styles.formContainer}`}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;
