import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { AiOutlineDollar } from "react-icons/ai";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import styles from "./DataAccordion.module.css";
import { useNavigate } from "react-router-dom";
import RegisterButton from "../RegisterButton/RegisterButton";
import {
  DASHBOARD_BG_COLOR_MAPPER,
  DASHBOARD_COLOR_MAPPER,
} from "../../../../utils/dashboardColorMapper";

const useStyles = makeStyles({
  tableData: {
    color: "#FFFFFF",
    fontFamily: "PrimaryFont",
    fontWeight: "600",
    fontSize: "0.9rem",
  },
  status: {
    fontFamily: "PrimaryFont",
    fontSize: "0.9rem",
    padding: "0.25rem 0.75rem",
    fontWeight: "600",
    borderRadius: "0.5rem",
    display: "inline-block",
    textAlign: "center",
  },
  accordian: {
    backgroundColor: "#211f1f",
    color: "#FFFFFF",
    borderRadius: "0.5rem",
    margin: "0.5rem 0",
    width: "100%",
  },
  tableLink: {
    margin: "1rem 0",
    fontFamily: "PrimaryFont",
    fontWeight: "600",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
  },
  tableLinkSpan: {
    display: "flex",
    alignItems: "center",
  },
});

function DataAccordion({ status, row, eventRedirect }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const paymentInfoRender = (status, eventRedirect) => {
    switch (status.name) {
      case "Not Registered":
        return (
          <RegisterButton
            text={"Register"}
            onClickMethod={() => navigate(eventRedirect)}
          />
        );
      case "Registered. Yet to pay":
        return (
          <RegisterButton
            text={"Pay"}
            onClickMethod={() => navigate(eventRedirect)}
          />
        );
      case "Paid":
        return (
          <span className={`${styles.icon}`}>
            <AiOutlineDollar size={25} data-tip="Payment ID" />
            &nbsp;{status.paymentId}
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Accordion className={classes.accordian}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
      >
        <div className={`${styles.accordianHeader}`}>
          <p className={`${styles.tContent}`}>{row.title}</p>
          {row.eventcode !== "GENERALREGISTRATION" && (
            <div className={`${styles.statusContainer}`}>
              <Typography
                style={{
                  backgroundColor: DASHBOARD_BG_COLOR_MAPPER[status.colorFlag],
                  color: DASHBOARD_COLOR_MAPPER[status.colorFlag],
                }}
                className={classes.status}
              >
                {status.name}
              </Typography>
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {row.eventcode !== "GENERALREGISTRATION" && (
            <Typography className={classes.tableData}>
              {/* {row.paymentInfo !== null ? (
                      <span className={`${styles.icon}`}>
                        <AiOutlineDollar size={25} data-tip="Payment ID" />
                        &nbsp;{row.paymentInfo}
                      </span>
                    ) : (
                      <RegisterButton />
                    )} */}
              {paymentInfoRender(status, eventRedirect)}
            </Typography>
          )}

          <Typography className={`${classes.tableLink} ${styles.tableLink}`}>
            <span
              className={`${classes.tableLinkSpan}`}
              onClick={() => navigate(eventRedirect)}
            >
              Learn more about this&nbsp;
              <BsFillArrowRightCircleFill></BsFillArrowRightCircleFill>
            </span>
          </Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default DataAccordion;
