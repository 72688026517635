import React from "react";
import styles from "./GlitchText.module.css";

function GlitchText({text}) {
  return (
    <h1 className={`${styles.glitchText}`} data-text={text}>
      <span>{text}</span>
    </h1>
  );
}

export default GlitchText;
