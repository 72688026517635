const localImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Events/engineering/`;

export const ENGINEERING_EVENTS = [
  // Buildathon
  {
    price: 849,
    eventcode: "BUILDATHON",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Build-a-Thon",
    path: "buildathon",
    filler: localImageBasePath + "buildathon/filler.jpg",
    oneLiner: "Hardware Hackathon",
    description: [
      "Build-a-thon is an electronics-based hardware hackathon. It is a continuous 48 hours event.",
      "The problem statements will be related to rocket science and its applications.",
      "This event primarily focuses on the team’s ability to analyse the problem and think out of the box for a solution.",
      "It promotes innovative ideas and industrial analysis.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Trial Screening",
        description: [
          "The participants have to bring a hardware model of their own project.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "The Shortlisted participants will be given a problem statement.",
          "They have to work on it and provide a solution within the given time limit.",
        ],
      },
    ],
    rules: [
      "Students must have a valid K! ID.",
      "Every participant must bring their own hardware model.",
      "Only one model per person is allowed.",
      "Participants are requested to bring their own Laptop.",
      "Accommodations and food will be provided for the participants.",
      "No extra time will be provided under any circumstances.",
      "Participants are allowed to use the internet for any kind of assistance.",
      "The winners be will selected based on their approach and the prototype model made.",
      "Decisions made by the judge and organizers will be final. ",
    ],
    prerequisites: [
      "Participants are required to bring their own Hardware project/model they have worked on previously.",
    ],
    contact: [
      {
        name: "Abdullah Shahul Hameed",
        mobile: "9840739702",
      },
      {
        name: "Kishore Naarayan",
        mobile: "7358484426",
      },
      {
        name: "Shibu",
        mobile: "9597703637",
      },
    ],
    mail: "buildathon@cegtechforum.in",
    dateTimeFees: {
      date: "April 8, 2022",
      fee: "Rs. 849/- (Inclusive of food)",
    },
  },

  // Innovate
  {
    regOpen: true,
    price: 299,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "Innovate",
    path: "innovate",
    filler: localImageBasePath + "innovate/filler.jpg",
    oneLiner:
      "A daunting event, where the participants are given a set of challenging tasks to innovate their ideas into real models",
    description: [
      "This event welcomes you with no preparation and leaves you with engineering motivation.",
      "Nothing but your creativity and innovation is needed.",
      "Innovate is a daunting event, where the participants are given a set of challenging tasks to innovate their ideas into real models.",
      "The task of this event is to build a creative and innovative working prototype for the given dimension using the provided materials.",
      "The winner will be selected based on their final models based on the degree of innovation, efficiency, and optimization.",
      "The event tests one’s ability to apply creativity and imagination in real- world applications.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "The problem statement will be given on the spot.",
          "All the logistic materials will be provided. No additional materials other than what is provided can be used.",
          "The participants will be given limited time to complete their models.",
          "The best model is selected based on the constraints.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "Same as Round 1, an advanced real-life problem will be given on the spot.",
          "Participants must prepare a prototype as its solution with the given logistics.",
          "This round tests your creativity and engineering skills.",
        ],
      },
    ],
    rules: [
      "Participants can participate either individually or as a team of 2-3 members.",
      "All the participants must wear their college ID card or valid k! ID",
      "Usage of mobile phones is strictly prohibited.",
      "The problem statement will be disclosed at the commencement of the competition.",
      "All the materials and requirements will be provided by the organizers in  the competition.",
      "Contestants should not ask for additional materials and given materials are sufficient to make the model.",
      "Participants should strictly adhere to time constraints. No extra time will be given under any circumstances.",
      "Any malpractice or misbehavior will lead to disqualification of the team or individual.",
      "The organizer’s decision is final.",
      "Participants must return the left-out logistics to the event organizers.",
      "Participants are not supposed to damage the college properties under any circumstances.",
    ],
    contact: [
      {
        name: "Anitha",
        mobile: "+91 8870792902",
      },
      {
        name: "Divya",
        mobile: "+91 8300499335",
      },
      {
        name: "Prem Kumar",
        mobile: "+91 9176000385",
      },
    ],
    mail: "innovatek20@cegtechforum.in",
  },

  // Godspeed
  {
    price: 1999,
    eventcode: "GODSPEED",
    participantsTypeFlag: "INDIVIDUAL&TEAM",
    participantsDetail: {
      minSize: 2,
      maxSize: 3,
    },
    regOpen: true,
    title: "Godspeed",
    path: "godspeed",
    filler: localImageBasePath + "godspeed/filler.JPG",
    oneLiner:
      "A miniature, remote-controlled nitro car racing and a signature event of Kurukshetra",
    description: [
      "Godspeed is a monumental event of Kurukshetra.",
      "It never missed bestowing its audience, a visual treat! It's an RC car racing event that takes place on the last day of Kurukshetra.",
      "The event is to compete with nitro off-road buggies against other opponents on an off-road dirt track with many obstacles in the least possible time and to score maximum points.",
      "The main objective of this event is to put your driving skills into action.",
      "If you are a great engineer-cum-racer, you are in the right place to be a part of this event.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Abstract Preparation",
        description: [
          "Participants are required to submit an abstract about their work explaining the steering mechanism, suspension mechanism, and chassis layout.",
          "The abstract must be a maximum of 3 pages.",
          "Photographs must also be attached of work done on the machine.",
          "These photographs are required for verifying that the machine has been indigenously built.",
        ],
      },
      {
        roundName: "Round 2 - Track Test",
        description: [
          "After the abstract submission, participants will compete with other RC cars to test the controllability, speed, and driving skills of the participant.",
        ],
      },
    ],
    rules: [
      "A wireless RC car, powered only by an IC engine that confirms the nitro standards is required for participation by each team.",
      "The event is to compete with other opponents on an off-road dirt track with many obstacles in the least possible time to score maximum points.",
      "Participants are required to submit an abstract about their work explaining the steering mechanism, suspension mechanism, and chassis layout. The abstract must be a maximum of 3 pages. Photographs must also be attached of work done on the machine. These photographs are required for verifying that the machine has been indigenously built.",
      "The track will have checkpoints at regular intervals. If a machine tumbles, halts or goes off the arena at any point on the track, one of our volunteers will lift it up and place it at the nearest checkpoint (as it is) behind that point. The time shall still be running in the meantime. Volunteers would try to do this as fast as possible. In case of any controversies, the decision of organizing/mentor panel shall be treated as final.",
      "The machines are not allowed to leave any loose parts on any part of the arena. Any machine disintegrating during the race will be disqualified.",
      "In all the rounds, a maximum of two team members are allowed from a team in the racing arena (including the driver himself).",
      "Teams are not allowed to purposely damage the machine of the opponent's team. If found guilty of doing so on track (while racing), the concerned team will be disqualified. Execution of the last three rules will be subjective and relies completely on judges' and organizers’ discretion.",
      "Judges' decisions shall be treated as final and binding on all.",
    ],
    prerequisites: [
      "Participants are required to bring their own RC 1/8 scale off road buggy.",
    ],
    contact: [
      {
        name: "Tiny Jacob J",
        mobile: "+91 7825028390",
      },
      {
        name: "Araventh M",
        mobile: "+91 8825551857",
      },
      {
        name: "Naveen K",
        mobile: "+91 8075446900",
      },
    ],
    mail: "events@cegtechforum.in",
    dateTimeFees: {
      date: "April 7-8, 2022",
    },
  },

  // Contraption
  {
    price: 299,
    eventcode: "CONTRAPTION",
    participantsTypeFlag: "INDIVIDUAL&TEAM",
    participantsDetail: {
      minSize: 2,
      maxSize: 4,
    },
    regOpen: true,
    title: "Contraptions",
    path: "contraptions",
    filler: localImageBasePath + "contraption/filler.jpg",
    oneLiner: "An on-site event where teams are expected to build a Rube.",
    description: [
      "‘Contraptions’ is an on-site event held during Kurukshetra, where teams are expected to build a Rube.",
      "The players are evaluated based on the perplexity of the mechanism in which the steps are triggered.",
      "The number of mechanisms involved and the degree of complexity are the key areas taken into consideration.",
      "A Rube Goldberg machine is a machine intentionally designed to perform a simple task in an indirect and overcomplicated fashion; these machines consist of a series of simple devices that are linked together to one produce a domino effect, in which each device triggers the next one.",
      "Bottom line, the more complicated the mechanism, the more points will be awarded.",
      "PROBLEM STATEMENT - ",
    ],
    rounds: [
      {
        roundName: "PROBLEM STATEMENT",
        description: [
          "Build a complex contraption composed of mechanisms that are intricate and complex in working along with IoT based components that connect the initial and final position. Ensure that the final position triggers a screening device that shows the output, ‘AHEAD OF TIME’.",
        ],
      },
      {
        roundName: "Rounds",
        description: [
          "The event will consist of 2 rounds.",
          "In each round each team will be given 2 trials.",
          "The best trial of the 2 will be considered for evaluation.",
          "In each round the team must change their contraption slightly and a part must be replaced.",
          "All required materials must be brought by the respective team itself.",
          "The theme of the event is the theme of Kurukshetra 2022, ‘AHEAD OF TIME’",
          "Volume allocated: L - 6 ft, B- 6 ft, D - 6 ft.",
        ],
      },
    ],
    rules: [
      "Each team can have a maximum of 4 members.",
      "Objective of the contraption must be demonstrated clearly.",
      "Students in any year of their engineering program can participate.",
      "Points allocation criteria will be decided and given by the Judges and organisers",
      "Time for contraption: min 2 minutes, max 10 minutes",
      "Minimum of 4 types of energy conversion must be present",
      "More no of energy conversions & mechanisms will be awarded higher no of points",
      "There will be 2 trials. Best out of the two will be considered.",
      "Maximum of 2 touches will be allowed for a trial. Trial will be disqualified if touches exceed 4.",
      "Contraption with no touches will be given extra points.",
      "In case of any dispute, the decision of the organiser will be final.",
      "Every participant must have a valid K! id.",
      "The contraption must be related with the given theme.",
      "Core committee members reserve the right to disqualify any team violating the rules of indulging in misbehaviour.",
      "Team should carry a physical printed copy of the abstract explaining the contraption setup design & the various mechanisms involved in a step-by-step manner.",
      "In each round the team must change their contraption slightly and a part must be replaced. No same contraption must be used in different rounds.",
      "All required materials must be brought by the respective team itself; the event organisers will not provide any logistics requirement to the participants.",
      "Teams should inform the organisers prior about any hazardous equipment/logistics to be used in their contraption mechanism.",
    ],
    contact: [
      {
        name: "Prabunesan",
        mobile: "90258 92336",
      },
      {
        name: "Vinoth",
        mobile: "98405 40427",
      },
    ],
    mail: "contraptions@cegtechforum.in",
    dateTimeFees: {
      date: "April 6, 2022",
    },
  },

  // Water Rocketry
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Water Rocketry",
    path: "water-rocketry",
    filler: localImageBasePath + "waterrocketry/filler.jpg",
    oneLiner: "",
    description: [
      "Water rocketry is an on-site event held during Kurukshetra, where the players are expected to build a type of model rocket using water as its reaction mass.",
      "The water is forced out by a pressurized gas, typically compressed air.",
      "Like all rocket engines, it operates on the principle of Newton's third law of motion.",
      "Water rocket hobbyists typically use one or more plastic soft drink bottles as the rocket's pressure vessel.",
      "A variety of designs are possible including multi- stage rockets.",
      "The results for the event will be based on the range test, reach the target and accuracy test.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Range And Time Test",
        description: [
          "Round 1 will be a range and time test.",
          "The materials will be provided to participants to construct their water rocket.",
          "Limited time will be allotted to construct the rocket.",
          "The distance and duration of flight of the water rockets are tested.",
          "Two launches and one trial is allowed. Based on the distance reached and duration of flight points are awarded.",
        ],
      },
      {
        roundName: "Round 2 - Reach The Target",
        description: [
          "Each participant selected from round 1 will be given 2 launches.",
          "If the target is not reached, no points are awarded.",
          "The participants are allowed to conduct a few test launches before the competition.",
        ],
      },
      {
        roundName: "Round 3 - Accuracy Test",
        description: [
          "Launch the rocket and land the target zone.",
          "Points will be given based upon the landing areas and the accuracy of the rocket launch.",
        ],
      },
    ],
    rules: [
      "Participants should have a valid K! id.",
      "Each team can have 4 members.",
      "Mark allocation will be decided and given by the organizer.",
      "For round 1 only two launches and one trial are allowed.",
      "Every participant will be provided with the same materials and time constraints.",
      "The participants should return the logistics upon completion of the event.",
      "Only half the water bottle can be filled with water.",
      "Mobile phones are not permitted during the event.",
      "Organizers have the right to disqualify any team that violates the rules. This includes misbehavior.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Prabhunesan.S",
        mobile: "+91 9025892336",
      },
      {
        name: "Bharath Sriraman",
        mobile: "+91 8754425548",
      },
    ],
    mail: "waterrocketry@cegtechforum.in",
    dateTimeFees: {
      date: "April 6, 2022",
    },
  },

  // Ctrl + Z
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Ctrl + Z",
    path: "ctrl+z",
    filler: localImageBasePath + "ctrl+z/filler.JPG",
    oneLiner: "",
    description: [
      "Creating cool stuff for school/college projects can never compete with the bliss of knocking down the old TV at home, pulling all its wires out.",
      "K!22 brings to you, first in history, an event where you can tear down things rather than building them.",
      "Reverse-engineering is the act of dismantling an object to see how it works.",
      "It is done primarily to analyse and gain knowledge about the way something works but often is used to duplicate or enhance the object.",
      "Ctrl + Z inspires students to dismantle things and learn how they actually work.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "A fun game of flashcards, which tests the participant’s knowledge of components of a system, their functions and processes.",
          "Participants will be given cards, which they have to group according to the announced systems.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "The selected participants will be given an object or two each, which has to be dismantled and put back together.",
          "Participants have to dismantle them, explain the working and components, and assemble them the way they were before.",
        ],
      },
    ],
    rules: [
      "Participants can come in teams of 2 or individually.",
      "Usage of mobile phones during the event is prohibited.",
      "Googling and exchanging answers is strictly prohibited.",
      "The time limit should be followed.",
      "All the logistics provided should be returned upon completion of the rounds.",
      "Breakage of probes may lead to disqualification.",
      "Participants must carry a valid K! ID. ",
      "The organizer’s decision will be final.",
      "Any kind of misconduct will lead to disqualification. ",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Harini",
        mobile: "+91 8220839679",
      },
      {
        name: "Sivadanus",
        mobile: "+91 7550035799",
      },
    ],
    mail: "ctrlz@cegtechforum.in",
    dateTimeFees: {
      date: "April 6, 2022",
    },
  },

  // Civil X
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Civil X",
    path: "civil-x",
    filler: localImageBasePath + "civil-x/filler.JPG",
    oneLiner: "",
    description: [
      "Civil X is an event to showcase the knowledge on bridge construction and virtual talent.",
      "An exclusive event for civil engineering sketched to cater to the students who are engrossed and profolic in AutoCAD skills, which involves various activities related to designing and construction of models.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "The participants have to answer the descriptive and reasoning type questions; a pen and paper round.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "The participants should build the bridge with the provided materials, which will further undergo a load test. The maximum load-bearing bridges will be chosen for the final round.",
        ],
      },
      {
        roundName: "Round 3",
        description: [
          "The participants have to design a construction model under the given conditions using AutoCAD software.",
        ],
      },
    ],
    rules: [
      "Team participation (minimum of 2 members & maximum of 4 members in a team)",
      "All the participants in a team must have a valid K! ID.",
      "If the teams are tied under all the considerations, the time taken to complete will be considered.",
      "If any team is suspected of malpractice, they shall be disqualified and will not be allowed to participate in the consecutive rounds.",
      "There should be no change in the team members.",
      "The marks are awarded based on partition of marks as mentioned in the question paper.",
      "The time taken to complete each round is also considered.",
      "A participant cannot be a member of more than one team. ",
      "In case of any discrepancies, the decision taken by the organizers will be final. ",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "ARTHI.S",
        mobile: "+91 80988 64074",
      },
      {
        name: "SHARINI.M",
        mobile: "+91 73058 83353",
      },
    ],
    mail: "civilx@cegtechforum.in",
  },

  // Circuit Craze
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Circuit Craze",
    path: "circuit-craze",
    filler: localImageBasePath + "circuit-craze/filler.JPG",
    oneLiner: "",
    description: [
      "Electronic circuits play a big role in our day-to-day existence.",
      "Even the brightest are intimidated by the intricate working of an intelligent design.",
      "If you have a knack for working relentlessly with circuits, this is the spot for you.",
      "Circuit Craze is the best platform to show off one’s best talents if one is passionate about components and connections.",
      "Come compete against the greatest in technical wizardry and decode basic yet dynamic day-to-day circuits.",
      "In Circuit Craze, you must steer through three levels with increasing intricacies, to win the prize.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Written Test",
        description: [
          "Test your knowledge in basic electrical and electronics concepts.",
          "Each Team will be provided with a set of questions and the team with the highest score will be qualified for the next round",
        ],
      },
      {
        roundName: "Round 2 - Tricky Circuits - Level 1 - Pick A Box",
        description: [
          "A PowerPoint presentation will be presented to the participants.",
          "Boxes shall be displayed behind which there will be a problem statement.",
          "Participants should pick a box and solve the question behind it.",
          "If they get it right, the number will be added to their scores, else it'll be subtracted from their Round 1 score.",
          "Unanswered questions will not be passed on.",
        ],
      },
      {
        roundName: "Round 2 - Tricky Circuits - Level 2 - Circuit Debugging",
        description: [
          "Participants will be given a faulty Circuit.",
          "They must debug the circuits within the stipulated time.",
          "If participants require clues, they can pick random chits provided to them and perform some funny technical tasks mentioned in it.",
          "This round is assessed based on the time taken and the closeness of the output obtained.",
          "Also, if the team completes debugging in t minutes, then the team gets additional points of (30-t).",
          "Based on Round 1 & 2 scores, virtual money will be given to each team.",
        ],
      },
      {
        roundName: "Round 3 - Circuit Design",
        description: [
          "An indirect problem statement will be given.",
          "Participants must identify the circuit and components needed to design it.",
          "Meanwhile, the list of components with their price will be displayed.",
          "They have to use the virtual money given to them in the previous round to buy the required components.",
          "Participants are allowed to use the component in their circuit only after buying them. After every ten minutes, the price of the component keeps doubling.",
          "Violation of the rule leads to negative marks.",
          "If the participants run short of virtual money, negative marks equal to half of the components price will be added to the total score.",
          "After every 10 minutes, teams are expected to complete a particular task and make the buzzer circuit work.",
          "The first team to ring the buzzer will get additional benefits.",
          "Scores will be given based on the closeness of the designed circuit to the correct one and the virtual money left.",
        ],
      },
    ],
    rules: [
      "Each team can have a maximum of 3 members",
      "No contestant can be a member of more than one team.",
      "All the participants in a team must have a valid K! ID.",
      "Clues will be provided only for Circuit Debugging Round.",
      "Calculators can be used for Rounds 2 and 3. Participants should bring their calculators.",
      "Calculators cannot be used for round 1.",
      "Usage of mobile phones and/or any electronic gadgets (especially programmable calculators) other than the approved calculator is strictly prohibited.",
      "Teams involved in any kind of malpractice will be immediately disqualified.",
      "Decisions taken by the organizers will be final and binding.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Swetha S",
        mobile: "+91 9894572524",
      },
      {
        name: "Sanjay J",
        mobile: "+91 6369387533",
      },
    ],
    mail: "circuitcraze@cegtechforum.in",
    dateTimeFees: {
      date: "April 7, 2022",
    },
  },

  // Kryptics
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Kryptics",
    path: "kryptics",
    filler: localImageBasePath + "kryptics/filler.JPG",
    oneLiner: "",
    description: [
      "The event KRYPTICS is a game based on engineering themes and activities in which students must use a set of clues to find the whole word or name (pictures from a ppt).",
      "The event is similar to making technical connections.",
      "The event necessitates both imagination and engineering expertise.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Connections",
        description: [
          "In this round, a ppt will be shown, and the team members will have to discuss it amongst themselves to figure out the answers to the clues.",
        ],
      },
      {
        roundName: "Round 2 - Mingle Bingo",
        description: [
          "Each player will be given a worksheet containing clues to identify a personality or object associated with engineering in this round (it's essentially a bingo game with hints instead of numbers).",
          "To locate the solution, the user will be given clues that they can link from top to bottom, across, or diagonally.",
        ],
      },
    ],
    rules: [
      "Students must have a valid K! id",
      "The participants can register individually or as a team of maximum 3 members.",
      "The use of Google or Google Lens is prohibited.",
      "Only students chosen in the first round will advance to the second round.",
      "Discussion with the next teammates is strictly not allowed.",
      "The organizer’s decision will be final.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Anusuya.SLK",
        mobile: "+91 9150578473",
      },
      {
        name: "S. Mohamed Abdullah",
        mobile: "+91 9150450732",
      },
      {
        name: "Subashini B",
        mobile: "+91 9361201915",
      },
    ],
    mail: "kryptics@cegtechforum.in",
  },

  // Hit the Road
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    regOpen: true,
    title: "Hit the Road",
    path: "hit-the-road",
    filler: localImageBasePath + "hit-the-road/filler.jpg",
    oneLiner: "",
    description: [
      "The event ‘hit the road’ is an automobile event where participants get a deep dive into automobiles.",
      "The event aims at giving an idea of a) terms in automobiles, b) knowledge of car models in production and c) the various systems and components of automobiles",
    ],
    rounds: [
      {
        roundName: "Round 1 - Written Test",
        description: [
          "A pen and paper test of 30 questions will be given to the participants regarding automobiles.",
        ],
      },
      {
        roundName: "Round 2 - Level 1 - Car Behind The Words",
        description: [
          "Questions will be displayed and the answers to these questions will be a car.",
          "The question will be passed to another team if not answered by the questioned team with a reduction in points.",
          "Teams participating will have max points of 25 and for every pass, the question will be worth 2 points less.",
          "The team with max points wins the round.",
        ],
      },
      {
        roundName: "Round 2 - Level 2 - Rapid Fire",
        description: [
          "Rapid fire round where teams are asked questions and the order of answering is determined based on the buzzer, 20 points.",
          "It should be answered within 5 seconds.",
          "No passing in this round.",
          "The top 4 teams will be shortlisted and shall proceed to the next round.",
        ],
      },
      {
        roundName: "Round 3",
        description: [
          "In this round, the teams will be given a topic related to current scenarios.",
          "Firstly, team 1 vs team 2 will speak for and against the topic.",
          "The same will take place simultaneously for team 3 vs team 4.",
          "The winners of both will battle against each other in the finale.",
          "The other two teams will compete for 3rd position.",
        ],
      },
    ],
    rules: [
      "Students must have a valid K! ID.",
      "Participants can register either individually or as a team of 3 members.",
      "Mobile phones are strictly prohibited",
      "The candidates must be on time for the presentation.",
      "Points put forward should be valid and documented.",
      "The organizer’s decision will be final.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Bharath Sriraman",
        mobile: "+91 8754425548",
      },
      {
        name: "Gowriyokesh Kumar",
        mobile: "+91 6383236636",
      },
    ],
    mail: "hittheroad@cegtechforum.in",
  },
];
