export const imageBasePath = `${process.env.PUBLIC_URL}/assets/images/Sponsors/`;

export const SPONSORS = [
  {
    type: "Title Sponsor",
    companies: [
      {
        name: "Giottus",
        image: "giottus.png",
        url: "https://giottus.com/",
      },
    ],
  },
  {
    type: "Media Partners",
    companies: [
      {
        name: "Times Of India",
        image: "times_of_india.png",
      },
      {
        name: "Kalaignar Seithigal",
        image: "kalaignar_seithigal.png",
      },
    ],
  },
  {
    type: "Co-Associate Sponsors",
    companies: [
      {
        name: "Sivarajavel IAS Academy",
        image: "ias_academy.png",
        url: "https://www.sivarajavelias.com/",
      },
      {
        name: "BCT",
        image: "bct.png",
        url: "https://bahwancybertek.com/home/",
      },
    ],
  },
  {
    type: "Event Sponsors",
    companies: [
      {
        name: "Ekahaa Solutions",
        image: "ekahaa.png",
        url: "https://www.ekahaa.com/",
      },
      {
        name: "Chronus",
        image: "chronus.png",
        url: "https://chronus.com/",
      },
      {
        name: "Niagara Networks",
        image: "niagara_networks.png",
        url: "https://www.niagaranetworks.com/",
      },
      {
        name: "UKL Instruments",
        image: "ukl.png",
        url: "http://uklindia.com/",
      },
      {
        name: "COSGrid Networks",
        image: "cos_grid.png",
        url: "https://www.cosgrid.com/home",
      },
      {
        name: "Agnikul",
        image: "agnikul.png",
        url: "https://agnikul.in/",
      },
      {
        name: "Nihon Edu Tech",
        image: "nihon.png",
        url: "https://nihonedutech.com/",
      },
    ],
  },
  {
    type: "Radio Partner",
    companies: [
      {
        name: "Suryan FM",
        image: "suryan_fm.png",
        url: "https://www.suryanfm.in/",
      },
    ],
  },
  {
    type: "Refreshment Sponsor",
    companies: [
      {
        name: "MPR",
        image: "mpr.png",
      },
    ],
  },
  {
    type: "Gifting Partner",
    companies: [
      {
        name: "Orient Graphics",
        image: "orient_graphics.png",
      },
    ],
  },
  {
    type: "Energy Partner",
    companies: [
      {
        name: "CPCL",
        image: "cpcl.png",
        url: "https://cpcl.co.in/",
      },
    ],
  },
  {
    type: "Giveaway Partner",
    companies: [
      {
        name: "Chennai Micro Prints",
        image: "chennai_micro_prints.png",
      },
    ],
  },
  {
    type: "Eco-friendly Partner",
    companies: [
      {
        name: "CM Seed Pens",
        image: "cm_seed.png",
        url: "https://cmseed.myshopmatic.com/",
      },
    ],
  },
  {
    type: "Coding Partner",
    companies: [
      {
        name: "Coding Ninjas",
        image: "coding_ninjas.png",
      },
    ],
  },
  {
    type: "Online Education Partner",
    companies: [
      {
        name: "Prepp",
        image: "prepp.png",
        url: "http://prepp.in/",
      },
    ],
  },
  {
    type: "Online Media Partner",
    companies: [
      {
        name: "Collegedunia",
        image: "college_dunia.png",
        url: "https://collegedunia.com/",
      },
    ],
  },
  {
    type: "Online Coupon Partner",
    companies: [
      {
        name: "Zoutons",
        image: "zoutons.png",
        url: "http://zoutons.com/",
      },
    ],
  },
  {
    type: "Gaming Accessories Partner",
    companies: [
      {
        name: "Coconut",
        image: "coconut.png",
      },
      {
        name: "Coco Sports",
        image: "coco_sports.png",
      },
    ],
  },
  {
    type: "VR Partner",
    companies: [
      {
        name: "VREON",
        image: "vreon.png",
        url: "http://www.vreontech.com/",
      },
    ],
  },
  {
    type: "Apparel Sponsor",
    companies: [
      {
        name: "iClothing",
        image: "i_clothing.png",
        url: "https://www.iclothing.com/",
      },
    ],
  },
  {
    type: "Incubation Partner",
    companies: [
      {
        name: "AIC",
        image: "aic_au.png",
        url: "https://annaincubator.org/",
      },
    ],
  },
  {
    type: "Education Partner",
    companies: [
      {
        name: "University of Twente",
        image: "university_of_twente.png",
        url: "https://www.utwente.nl/en/",
      },
    ],
  },

  {
    type: "Our sincere thanks to",
    companies: [
      {
        name: "CEGAANA",
        image: "cegaana.png",
        url: "https://www.cegaana.org/",
      },
      {
        name: "AACEG",
        image: "alumni_association.png",
        url: "https://www.aaceg.org/",
      },
    ],
  },
];
