import React, { useEffect, useState, useRef } from "react";
import Heading from "../../components/Heading/Heading";
import { CREATIVE_TEAM, teamImageBasePath } from "../../data/CreativeTeam";
import styles from "./CreativeTeam.module.css";
import DomainNav from "./DomainNav/DomainNav";
import StudentDetails from "./StudentDetails/StudentDetails";
import Page_transition from "../../components/Animation/Transition";
import { useOnLoadImages } from "../../utils/useOnLoadImages.ts";
import PageLoader from "../../components/PageLoader/PageLoader";
import backdrop from "../../assets/bg/quote_bg_1.jpg";
function CreativeTeam() {
  const wrapperRef = useRef(null);
  // const imagesLoaded = useOnLoadImages(wrapperRef);
  const [imagesLoaded, setimagesLoaded] = useState(false);

  let imageRefs = new Array(CREATIVE_TEAM.length);
  let imageCounter = useRef(0);

  const [currentDomain, setcurrentDomain] = useState({ index: 0, name: "All" });
  const [isStudentDetailsOpen, setisStudentDetailsOpen] = useState(false);
  const [studentData, setstudentData] = useState({});
  useEffect(() => {
    // if (isStudentDetailsOpen) document.body.style.overflow = "hidden";
    // else document.body.style.overflow = "auto";
    return () => {};
  }, []);

  useEffect(() => {
    toggleImages(currentDomain.name);
    return () => {};
  }, []);

  const toggleImages = (newDomain, prevDomain) => {
    for (let i = 0; i < CREATIVE_TEAM.length; i++) {
      if (newDomain === "All" && prevDomain === undefined) {
        imageRefs[i].classList.add(`${styles.imageOn}`);
      } else if (newDomain === "All" && prevDomain !== undefined) {
        if (prevDomain !== CREATIVE_TEAM[i].domain) {
          imageRefs[i].classList.remove(`${styles.imageOff}`);
          imageRefs[i].classList.add(`${styles.imageOn}`);
        }
      } else {
        if (newDomain === CREATIVE_TEAM[i].domain) {
          imageRefs[i].classList.remove(`${styles.imageOff}`);
          imageRefs[i].classList.add(`${styles.imageOn}`);
        } else if (
          prevDomain === CREATIVE_TEAM[i].domain ||
          prevDomain === "All"
        ) {
          imageRefs[i].classList.remove(`${styles.imageOn}`);
          imageRefs[i].classList.add(`${styles.imageOff}`);
        }
      }
    }
  };

  const clickedImage = (student) => {
    setstudentData(student);
    setisStudentDetailsOpen(true);
  };

  const clickedStudentDetailsClose = () => {
    setisStudentDetailsOpen(false);
  };

  const onImageLoad = () => {
    imageCounter.current += 1;

    // If all images are loaded, set loader to false
    if (imageCounter.current >= CREATIVE_TEAM.length) {
      setimagesLoaded(true);
      imageCounter.current = 0;
    }
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper} `} ref={wrapperRef}>
        {!imagesLoaded && <PageLoader />}

        <section style={{ display: imagesLoaded ? "flex" : "none" }}>
          <div className={`${styles.headingContainer}`}>
            <Heading text={"Meet the team"} />
          </div>
          <div className={`${styles.navContainer} customScrollbar`}>
            <DomainNav
              currentDomain={currentDomain}
              toggleImages={toggleImages}
              setcurrentDomain={setcurrentDomain}
            />
          </div>
          <div className={`${styles.teamWrapper}`}>
            {CREATIVE_TEAM.map((item, index) => {
              return (
                <div
                  onClick={() => clickedImage(item)}
                  key={index}
                  className={`${styles.imageContainer}`}
                >
                  <img
                    ref={(el) => (imageRefs[index] = el)}
                    src={teamImageBasePath + item.image}
                    className={`${styles.image}`}
                    alt="Image"
                    onLoad={onImageLoad}
                  />
                </div>
              );
            })}
          </div>
        </section>
        <StudentDetails
          isOpen={isStudentDetailsOpen}
          student={studentData}
          clickedStudentDetailsClose={clickedStudentDetailsClose}
        />
      </div>
    </Page_transition>
  );
}

export default CreativeTeam;
