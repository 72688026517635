import React, { useState, useRef, useEffect } from "react";
import styles from "./LoginRegistration.module.css";
import emailIcon from "../../assets/images/LoginRegistration/email.png";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import FormField from "../../components/FormField/FormField";
import ReCAPTCHA from "react-google-recaptcha";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from "../../components/ToastNotification";
import { validateEmailForm } from "../../validators/authValidators";
import { apiForgotPassword } from "../../api/auth";
import { useNavigate } from "react-router-dom";

import Page_transition from "../../components/Animation/Transition";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";

const forgotPasswordFormFormat = {
  email: "",
};

function ForgotPassword() {
  const navigate = useNavigate();

  let reCaptchaRef = useRef(null);
  const [forgotPasswordDetails, setforgotPasswordDetails] = useState(
    forgotPasswordFormFormat
  );

  const [loader, setloader] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  const changeForgotPasswordDetails = (args) => {
    let prevState = forgotPasswordDetails;
    prevState[args.key] = args.value;
    setforgotPasswordDetails({ ...prevState });
  };

  const clickedResetPassword = async () => {
    // Form Validation
    let validation = validateEmailForm({ email: forgotPasswordDetails.email });

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    // reCaptcha Validation
    if (reCaptchaRef.current.getValue() === "") {
      showErrorToastNotification(<p>reCaptcha verification failed</p>);
      return;
    }

    setloader(true);

    const resp = await apiForgotPassword({
      ...forgotPasswordDetails,
      captcha: reCaptchaRef.current.getValue(),
    });

    reCaptchaRef.current.reset();
    setloader(false);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        showSuccessToastNotification(<p>{resp.data.message}</p>);
        navigate("/login");
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.componentContainer}`}>
          <div className={`${styles.designContainer}`}></div>
          <div className={`${styles.formContainer}  customScrollbar`}>
            {loader && <SimpleLoader text={"Submitting"} />}
            <div
              className={`${styles.formContent}`}
              style={{ display: loader ? "none" : "flex" }}
            >
              <h1>Forgot Password</h1>
              <p className={`${styles.divider}`}>Enter your email</p>

              <div className={`${styles.formBox}`}>
                <FormField
                  type={"text"}
                  fieldIcon={emailIcon}
                  placeholder="Email"
                  name="email"
                  value={forgotPasswordDetails}
                  setter={changeForgotPasswordDetails}
                />
              </div>
              <div className="recaptcha_container">
                <ReCAPTCHA
                  sitekey={"6LcMoTUdAAAAAGFo2lgEFl5sIpitgdT-lExG05FL"}
                  theme="dark"
                  size="normal"
                  className="recaptcha"
                  ref={reCaptchaRef}
                />
              </div>
              <div className={`${styles.buttonContainer}`}>
                <GlitchButton
                  text={"Reset"}
                  onClickMethod={clickedResetPassword}
                />
              </div>
              <p className={`${styles.formText}`}>
                Don't have an account?&nbsp;
                <br />
                <span
                  onClick={() => navigate("/register")}
                  className={`${styles.formLink}`}
                >
                  Register
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page_transition>
  );
}

export default ForgotPassword;
