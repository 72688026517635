import React from "react";
import { Link } from "react-router-dom";
import { CODING_EVENTS } from "../../data/Events/Coding";
import { ENGINEERING_EVENTS } from "../../data/Events/Engineering";
import { EXTRAVAGANZA_EVENTS } from "../../data/Events/Extravaganza";
import { MANAGEMENT_EVENTS } from "../../data/Events/Management";
import { PAPER_PRESENTATION_EVENTS } from "../../data/Events/Paper Presentation";
import { QUIZ_EVENTS } from "../../data/Events/Quiz";
import { ROBOTICS_EVENTS } from "../../data/Events/Robotics";
import Heading from "../Heading/Heading";
import styles from "./EventListDialog.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { WORKSHOPS } from "../../data/Workshops/Workshops";

const EVENT_DATA_MAPPING = [
  {
    title: "Engineering",
    data: ENGINEERING_EVENTS,
    categoryUrl: "engineering",
  },
  {
    title: "Coding",
    data: CODING_EVENTS,
    categoryUrl: "coding",
  },
  {
    title: "Management",
    data: MANAGEMENT_EVENTS,
    categoryUrl: "management",
  },
  {
    title: "Quiz",
    data: QUIZ_EVENTS,
    categoryUrl: "quiz",
  },
  {
    title: "Extravaganza",
    data: EXTRAVAGANZA_EVENTS,
    categoryUrl: "extravaganza",
  },
  {
    title: "Robotics",
    data: ROBOTICS_EVENTS,
    categoryUrl: "robotics",
  },
  {
    title: "Paper Presentation",
    data: PAPER_PRESENTATION_EVENTS,
    categoryUrl: "paper-presentation",
  },
];

function EventListDialog({ isOpen, toggleDialog }) {
  return (
    <Modal
      className={`${styles.modalWrapper} customScrollbar`}
      overlayClassName={`${styles.modalOverlay}`}
      isOpen={isOpen}
      closeTimeoutMS={200}
    >
      <div className={`${styles.modalContainer}`}>
        <CloseIcon
          onClick={() => toggleDialog(false)}
          className={`${styles.close_icon}`}
        />
        {/* Events */}
        <div className={`${styles.contentWrapper} `}>
          <div className={`${styles.headingContainer}`}>
            <Heading text={"Events"} />
          </div>
          <div className={`${styles.contentContainer}`}>
            {EVENT_DATA_MAPPING.map((type, index1) => {
              return (
                <div className={`${styles.contentBox}`} key={index1}>
                  <h2>{type.title}</h2>
                  <div>
                    {type.data.map((item, index2) => {
                      return (
                        <Link
                          key={index2}
                          to={"/events/" + type.categoryUrl + "/" + item.path}
                          onClick={() => toggleDialog(false)}
                          className={`${styles.eventLink}`}
                        >
                          <ArrowForwardIosIcon
                            className={`${styles.arrowIcon}`}
                          />
                          &nbsp;{item.title}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Workshops */}
        <div className={`${styles.contentWrapper} `}>
          <div className={`${styles.headingContainer}`}>
            <Heading text={"Workshops"} />
          </div>
          <div className={`${styles.contentContainer}`}>
            <div className={`${styles.contentBox}`}>
              <div>
                {WORKSHOPS.map((workshop, index) => {
                  return (
                    <Link
                      key={index}
                      to={"/workshops/" + workshop.path}
                      onClick={() => toggleDialog(false)}
                      className={`${styles.eventLink}`}
                    >
                      <ArrowForwardIosIcon className={`${styles.arrowIcon}`} />
                      &nbsp;{workshop.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EventListDialog;
