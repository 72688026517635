import React, { useEffect } from "react";
import { EVENT_SAMPLE } from "../../data/EventSample";
import styles from "./EventTemplate.module.css";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { workshopsSpeakerImageBasePath } from "../../data/Workshops/Workshops";
import ReactTooltip from "react-tooltip";
import GlitchButton from "../GlitchButton/GlitchButton";

function Details({ currentDetail, data }) {
  const renderRequiredDetailsComponent = (currentDetail) => {
    switch (currentDetail.name) {
      case "description":
        return <Description data={data} />;

      case "contact":
        return <Contact data={data} />;

      case "dateTimeFees":
        return <DateTimeFees data={data} />;

      case "rules":
        return <Rules data={data} />;

      case "rounds":
        return <Rounds data={data} />;

      case "speakers":
        return <Speakers data={data} />;

      case "prerequisites":
        return <Prerequisites data={data} />;

      case "abstractPDF":
        return <AbstractFormat data={data} />;

      case "faqs":
        return <FAQS data={data} />;
      default:
        return;
    }
  };

  useEffect(() => {
    return () => {};
  });

  return <>{renderRequiredDetailsComponent(currentDetail)}</>;
}

const Description = ({ data }) => {
  return (
    <>
      {data.description.map((line, index) => {
        return (
          <p key={index} className={`${styles.normalLine}`}>
            &#9679;&nbsp;{line}
          </p>
        );
      })}
    </>
  );
};

const Contact = ({ data }) => {
  return (
    <>
      {data.contact.map((item, index) => {
        return (
          <p key={index} className={`${styles.normalLine}`}>
            <PhoneAndroidIcon />
            &nbsp;{item.name}&nbsp;-&nbsp;
            {item.mobile}
          </p>
        );
      })}
      <p className={`${styles.normalLine}`}>
        <EmailIcon />
        &nbsp;{data.mail}
      </p>
    </>
  );
};

const DateTimeFees = ({ data }) => {
  return (
    <>
      {data.dateTimeFees.hasOwnProperty("date") && (
        <p className={`${styles.normalLine}`}>
          <TodayIcon data-tip="Date" />
          &nbsp;{data.dateTimeFees.date}
        </p>
      )}

      {data.dateTimeFees.hasOwnProperty("time") && (
        <p className={`${styles.normalLine}`}>
          <AccessTimeIcon data-tip="Time" />
          &nbsp;{data.dateTimeFees.time}
        </p>
      )}

      {data.dateTimeFees.hasOwnProperty("fee") && (
        <p className={`${styles.normalLine}`}>
          <AccessTimeIcon data-tip="Fee" />
          &nbsp;{data.dateTimeFees.fee}
        </p>
      )}

      {data.dateTimeFees.hasOwnProperty("platform") && (
        <p className={`${styles.normalLine}`}>
          <VideoCallIcon data-tip="Platform" />
          &nbsp;{data.dateTimeFees.platform}
        </p>
      )}
      <ReactTooltip />
    </>
  );
};

const Rules = ({ data }) => {
  return (
    <>
      {data.rules.map((rule, index) => {
        return (
          <p key={index} className={`${styles.normalLine}`}>
            &#9679;&nbsp;{rule}
          </p>
        );
      })}
    </>
  );
};

const Rounds = ({ data }) => {
  return (
    <>
      {data.rounds.map((round, index) => {
        return (
          <div key={index} className={`${styles.wrapping}`}>
            <div className={`${styles.round_heading}`}>
              <p className={`${styles.heading}`}>{round.roundName}</p>
            </div>
            <div className={`${styles.round_content}`}>
              {round.description.map((line, index1) => {
                return (
                  <p key={index1} className={`${styles.normalLine}`}>
                    &#9679;&nbsp;{line}
                  </p>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

const Speakers = ({ data }) => {
  return (
    <div className={`${styles.speakerWrapper}`}>
      {data.speakers.map((speaker, index) => {
        return (
          <div key={index} className={`${styles.speakerCard}`}>
            <img
              onClick={() => window.open(speaker.linkedIn)}
              src={workshopsSpeakerImageBasePath + speaker.image}
              alt={speaker.name}
            />
            <p className={`${styles.speakerName}`}>{speaker.name}</p>
            <p>{speaker.designation}</p>
            <p>{speaker.company}</p>
          </div>
        );
      })}
    </div>
  );
};

const Prerequisites = ({ data }) => {
  return (
    <>
      {data.prerequisites.map((line, index) => {
        return (
          <p key={index} className={`${styles.normalLine}`}>
            &#9679;&nbsp;{line}
          </p>
        );
      })}
    </>
  );
};

const FAQS = ({ data }) => {
  return (
    <>
      {data.faqs.map((faq, index) => {
        return (
          <div className={`${styles.faqContainer}`}>
            <p key={index}>Q)&nbsp;{faq.question}</p>
            <p key={index}>A)&nbsp;{faq.answer}</p>
          </div>
        );
      })}
    </>
  );
};

const AbstractFormat = ({ data }) => {
  return (
    <>
      {data.hasOwnProperty("abstractPDF") && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p
            style={{
              marginBottom: "10px",
            }}
          >
            To download the abstract format, click the button
          </p>
          <GlitchButton
            text={"Download"}
            onClickMethod={() => {
              window.open(data.abstractPDF);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Details;
