import React from "react";
import { projectsImageBasePath } from "../../data/Projects";
import styles from "./SimpleDetailHoverCard.module.css";

function SimpleDetailHoverCard({ data, onClickMethod }) {
  return (
    <div
      className={`${styles.cardWrapper}`}
      onClick={() => onClickMethod(data)}
    >
      <div className={`${styles.card}`}>
        <div className={`${styles.img_container}`}>
          <img
            src={
              data.images
                ? `${projectsImageBasePath}${data.folderName}${data.images[0]}`
                : `${data.filler}`
            }
            className={`${styles.card__image}`}
            alt={data.title}
          />
        </div>
        <div className={`${styles.card__overlay}`}>
          <div className={`${styles.card__header}`}>
            <h3 className={`${styles.card__title}`}>{data.title}</h3>
          </div>
          <p className={`${styles.card__description}`}>{data.oneLiner}</p>
        </div>
      </div>
    </div>
  );
}

export default SimpleDetailHoverCard;
