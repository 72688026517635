import React from "react";
import styles from "./ProfileTable.module.css";
import { profile } from "../../../../data/ProfileData";

export default function ProfileTable({ profileDetails }) {
  return (
    <>
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>K! ID</p>
        <p className={`${styles.itemContent}`}>{profileDetails.kid}</p>
      </div>
      <hr />
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>Email</p>
        <p className={`${styles.itemContent}`}>{profileDetails.email}</p>
      </div>
      <hr />
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>College</p>
        <p className={`${styles.itemContent}`}>{profileDetails.college}</p>
      </div>
      <hr />
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>Department</p>
        <p className={`${styles.itemContent}`}>{profileDetails.dept}</p>
      </div>
      <hr />
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>Year</p>
        <p className={`${styles.itemContent}`}>
          {profileDetails.year + "th year"}
        </p>
      </div>
      <hr />
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>Mobile</p>
        <p className={`${styles.itemContent}`}>{profileDetails.phone}</p>
      </div>
    </>
  );
  return (
    <>
      {profile.map((row, index, profile) => {
        var next = profile[index + 1];
        return <></>;
      })}
    </>
  );
}
