const localImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Events/quiz/`;
export const QUIZ_EVENTS = [
  // Night Owl Gen Quiz
  {
    price: 299,
    regOpen: true,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "Night Owl Gen Quiz",
    path: "night-owl-gen-quiz",
    filler: localImageBasePath + "night-owl-gen-quiz/filler.jpg",
    oneLiner: "",
    description: [
      "The night sky is aglow with bright city lights, when the whole world sleeps, the night owl is wide awake.",
      "Far from classroom rote, this quiz is all about General Knowledge",
      "General knowledge spanning all fields is included with a way more fun now.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "The first round will be a pen and paper round.",
          "There will be a maximum of 25 questions in this round.",
          "There is no negative marking in this round.",
          "Questions marked with a star will decide the winner in case of a tie.",
          "The top 10 teams will be selected for the second round.",
          "This round will be conducted in the morning, in a night room setup.",
        ],
      },
      {
        roundName: "Round 2",
        description: ["The second round will have 3 sub rounds."],
      },
      {
        roundName: "Round 2: Subround 1 - Connections",
        description: [
          "A popular interactive game, random pictures are presented on the screen.",
          "Contestants ought to guess the relation between them and answer the question accordingly.",
        ],
      },
      {
        roundName: "Round 2: Subround 2 - Whisper the Answers",
        description: [
          "In this event, two of the team members should participate. ",
          "Questions will be given to one of the teammates (1) and the other teammate (2) will have headphones.",
        ],
      },
      {
        roundName: "Round 2: Subround 3 - Snookered",
        description: [
          "A classic final round can change everything with the final answer.",
          "The format is 12 questions, alternating between simple ones (red balls) and progressively more obscure ones (colours).",
          "Teams get one point for each red ball question they get right, but the corresponding snooker score for every colour they answer (pot) - so if you answer the green question right, you get three points instead of one.",
        ],
      },
    ],
    rules: [
      "This is NOT a rolling event and the participants are requested to be on time.",
      "A team can have 2 or 4 members.",
      "Discussion of answers with other teams is prohibited.",
      "Use of mobile phones or any other electronic gadgets will lead to disqualification.",
      "Students must have a valid K! ID.",
      "Quiz Master’s decision will be final.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Deepak",
        mobile: "+91 7904391142",
      },
      {
        name: "Pratibha Senthil",
        mobile: "+91 9490365430",
      },
    ],
    mail: "nightowlquiz@cegtechforum.in",
    dateTimeFees: {
      date: "April 6, 2022",
    },
  },

  // Fandom Quiz
  {
    price: 299,
    regOpen: true,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "Fandom Quiz",
    path: "fandom-quiz",
    filler: localImageBasePath + "fandom-quiz/filler.jpg",
    oneLiner: "",
    description: [
      "A fun filled quiz event based on a theme which tests the participant teams about their knowledge on Movies, Series and Animes and how much they know about it.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "A question sheet will be provided to the participants.",
          "It will pertain to the theme of the quiz and the participants must answer the same in the stipulated time.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "Selected teams are displayed a PPT with 30 question and are expected to compete with other teams to answer the question and score better.",
        ],
      },
    ],
    rules: [
      "Usage of phones is strictly prohibited.",
      "Students must have a valid K! ID.",
      "Participants can register individually or as a team of maximum 3 members.",
      "Teams should complete all the rounds within the given time.",
      "Choices cannot be discussed among other teams.",
      "The panel members’ decision is the final, teams are expected to abide by it.",
    ],
    contact: [
      {
        name: "Bharath",
        mobile: "+91 8667397262",
      },
      {
        name: "Mohamed Jalaludeen ",
        mobile: "+91 9789631491",
      },
    ],
    mail: "fandomquiz@cegtechforum.in",
    dateTimeFees: {
      date: "April 8, 2022",
    },
  },

  // Sci Tech Quiz
  {
    price: 299,
    regOpen: true,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "Sci-tech Quiz",
    path: "sci-tech-quiz",
    filler: localImageBasePath + "sci-tech-quiz/filler.jpg",
    oneLiner: "",
    description: [
      "Sci-Tech Quiz is a sort of quiz mixed with both science and technology.",
      "It mainly consists of two Qualifying rounds and a final round.",
      "Since it is a Quiz event the first round consists of two divisions.",
      "First division will be a conventional quiz, i.e., in pen and paper mode.",
      "The second division is a crossword puzzle round.",
      "The second round is a “domain quiz”.",
      "The final round will be a “stabilize” round.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "First-round will be a conventional quiz round.",
          "It will be a pen and paper test.",
          "The questions will be from both science and technology.",
        ],
      },
      {
        roundName: "Round 2",
        description: ["Second round is a domain specific quiz."],
      },
      {
        roundName: "Round 3",
        description: [
          "Final round will be a stabilizing round with an intriguing game, stuffs and concepts.",
        ],
      },
    ],
    rules: [
      "Participants must have a valid K! ID.",
      "Mobile phones are strictly prohibited.",
      "Each team should consist of a minimum of 2 and maximum of 4 students.",
      "Discussion with other teams is strictly prohibited.",
      "Time constraints will be strictly followed.",
      "Answers which are scientifically proved will only be accepted.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Subashini M",
        mobile: "+91 8925738823",
      },
      {
        name: "Jeeva S",
        mobile: "+91 7339318093",
      },
    ],
    mail: "scitechquiz@cegtechforum.in",
    dateTimeFees: {
      date: "April 6-7, 2022",
    },
  },
];
