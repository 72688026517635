import { validateDob, validateRoll } from "./validators";

export const validateCEGianVerificationForm = ({ roll, dob }) => {
  if (!validateRoll(roll)) {
    return {
      status: false,
      message: "Invalid Roll Number",
    };
  }

  if (!validateDob(dob)) {
    return {
      status: false,
      message: "Invalid Date of Birth",
    };
  }

  return {
    status: true,
  };
};
