import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "./LoginRegistration.module.css";
import emailIcon from "../../assets/images/LoginRegistration/email.png";
import passwordIcon from "../../assets/images/LoginRegistration/key.png";
import googleIcon from "../../assets/images/LoginRegistration/google.png";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import FormField from "../../components/FormField/FormField";
import ReCAPTCHA from "react-google-recaptcha";

import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from "../../components/ToastNotification";
import { validateEmailForm } from "../../validators/authValidators";
import { apiGoogleSignin, apiLogin } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import {
  Auth,
  LoggedInUserName,
  SetAuth,
  SetLoggedInUserName,
} from "../../App";
import {
  isAuthDataStored,
  stringifyUserDetails,
} from "../../utils/localStorageHelper";
import Page_transition from "../../components/Animation/Transition";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";

const loginCredentialsFormat = {
  email: "",
  pwd: "",
};

function Login() {
  const auth = useContext(Auth);
  const setAuth = useContext(SetAuth);
  const loggedInUserName = useContext(LoggedInUserName);
  const setLoggedInUserName = useContext(SetLoggedInUserName);
  const navigate = useNavigate();

  let reCaptchaRef = useRef(null);
  const [loginCredentials, setloginCredentials] = useState(
    loginCredentialsFormat
  );

  const [loader, setloader] = useState(false);

  useEffect(() => {
    // if (isAuthDataStored() || auth) {
    //   console.log("Navigating to dashboard", auth);
    //   navigate("/dashboard");
    // } else {
    //   setAuth(false);
    // }

    return () => {};
  }, []);

  const changeLoginCredentials = (args) => {
    let prevState = loginCredentials;
    prevState[args.key] = args.value;
    setloginCredentials({ ...prevState });
  };

  const clickedLogin = async () => {
    // Form Validation
    let validation = validateEmailForm({ email: loginCredentials.email });

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    // reCaptcha Validation
    if (reCaptchaRef.current.getValue() === "") {
      showErrorToastNotification(<p>reCaptcha verification failed</p>);
      return;
    }

    setloader(true);

    const resp = await apiLogin({
      ...loginCredentials,
      captcha: reCaptchaRef.current.getValue(),
    });

    reCaptchaRef.current.reset();
    setloader(false);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        setAuth(true);
        localStorage.setItem("details", stringifyUserDetails(resp.data));
        localStorage.setItem("token", resp.data.token);
        localStorage.setItem("email", resp.data.email);
        setLoggedInUserName(resp.data.firstname);
        navigate("/dashboard");
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.componentContainer}`}>
          <div className={`${styles.designContainer}`}></div>
          <div className={`${styles.formContainer}  customScrollbar`}>
            {loader && <SimpleLoader text={"Logging in"} />}
            <div
              className={`${styles.formContent}`}
              style={{ display: loader ? "none" : "flex" }}
            >
              <h1>Login to Kurukshetra</h1>
              <img
                onClick={() => apiGoogleSignin()}
                src={googleIcon}
                className={`${styles.googleIcon}`}
                alt="Google Icon"
              />
              <p className={`${styles.divider}`}>or use your email to login</p>

              <div className={`${styles.formBox}`}>
                <FormField
                  type={"text"}
                  fieldIcon={emailIcon}
                  placeholder="Email"
                  name="email"
                  value={loginCredentials}
                  setter={changeLoginCredentials}
                />
                <FormField
                  type={"password"}
                  fieldIcon={passwordIcon}
                  placeholder="Password"
                  name="pwd"
                  value={loginCredentials}
                  setter={changeLoginCredentials}
                />
              </div>
              <p
                className={`${styles.formText}`}
                onClick={() => navigate("/forgot-password")}
              >
                <span className={`${styles.formLink}`}>
                  Forgot your password?
                </span>
              </p>
              <div className="recaptcha_container">
                <ReCAPTCHA
                  sitekey={"6LcMoTUdAAAAAGFo2lgEFl5sIpitgdT-lExG05FL"}
                  theme="dark"
                  size="normal"
                  className="recaptcha"
                  ref={reCaptchaRef}
                />
              </div>
              <div className={`${styles.buttonContainer}`}>
                <GlitchButton text={"Login"} onClickMethod={clickedLogin} />
              </div>
              {/* <p className={`${styles.formText}`}>
                Studying at CEG?&nbsp;
                <br />
                <span
                  onClick={() => navigate("/ceg-registration")}
                  className={`${styles.formLink}`}
                >
                  Register as CEGian
                </span>
              </p> */}
              <p className={`${styles.formText}`}>
                Don't have an account?&nbsp;
                <br />
                <span
                  onClick={() => navigate("/register")}
                  className={`${styles.formLink}`}
                >
                  Register
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page_transition>
  );
}

export default Login;
