import React, { useState, useRef, useContext } from "react";
import Page_transition from "../../components/Animation/Transition";
import Heading from "../../components/Heading/Heading";
import SimpleDetailHoverCard from "../../components/SimpleDetailHoverCard/SimpleDetailHoverCard";
import { PROJECTS, projectsImageBasePath } from "../../data/Projects";
import ProjectDetails from "./ProjectDetails/ProjectDetails";
import styles from "./Projects.module.css";
import { useOnLoadImages } from "../../utils/useOnLoadImages.ts";
import PageLoader from "../../components/PageLoader/PageLoader";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../App";

function Projects() {
  const navigate = useNavigate();
  const auth = useContext(Auth);

  const wrapperRef = useRef(null);
  // const imagesLoaded = useOnLoadImages(wrapperRef);
  const [isProjectDetailsOpen, setisProjectDetailsOpen] = useState(false);
  const [projectDetails, setprojectDetails] = useState(PROJECTS[0]);

  const clickedProject = (project) => {
    setprojectDetails(project);
    setisProjectDetailsOpen(true);
  };

  const clickedProjectDetailsClose = () => {
    setisProjectDetailsOpen(false);
  };

  return (
    <Page_transition>
      {/* {!imagesLoaded && <PageLoader />} */}
      <div
        className={`${styles.wrapper} colorPaletteGradientBackground`}
        ref={wrapperRef}
      >
        {/* <img
          src={`${process.env.PUBLIC_URL}/assets/images/Logos/kurukshetra_logo_white.png`}
          alt={"Kurukshetra Logo"}
          className={`${styles.brandLogo}`}
        /> */}
        <div
          // style={{ display: imagesLoaded ? "flex" : "none" }}
          className={`${styles.headingContainer}`}
        >
          <Heading text={"CTF Projects"} />
          {/* {auth && ( */}
          <p>
            <span
              className={`${styles.CLICK_HERE}`}
              onClick={() => navigate("technovation")}
            >
              Click here
            </span>
            {/* <ArrowRightIcon className={`${styles.CLICK_HERE}`} /> */}
            &nbsp;to register for&nbsp;
            <span className={`${styles.TECHNOVATION}`}>TECHNOVATION</span>
          </p>
          {/* )} */}
        </div>
        <div
          className={`${styles.cards_wrapper}`}
          // style={{ display: imagesLoaded ? "flex" : "none" }}
        >
          <div className={`${styles.cards_wrapper_inside} customScrollbar`}>
            <ul className={`${styles.cards}`}>
              {PROJECTS.map((project, index) => {
                return (
                  <li>
                    <div className={`${styles.col} ${styles.grid_1_of_3}`}>
                      <SimpleDetailHoverCard
                        key={index}
                        data={project}
                        onClickMethod={clickedProject}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <ProjectDetails
          isOpen={isProjectDetailsOpen}
          project={projectDetails}
          clickedProjectDetailsClose={clickedProjectDetailsClose}
        />
      </div>
    </Page_transition>
  );
}

export default Projects;
