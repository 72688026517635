import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Template from "../../components/EventTemplate/Template";
import { eventDataMapping, EVENT_TYPES } from "../../data/Events/EventTypes";

function EventTemplateMiddleware() {
  const navigate = useNavigate();
  let { category, title } = useParams();

  const [data, setdata] = useState(null);

  useEffect(() => {
    let flag = false;

    for (let i = 0; i < EVENT_TYPES.length; i++) {
      if (category === EVENT_TYPES[i].url) {
        flag = true;
      }
    }

    if (flag === false) {
      navigate(`/page-not-found`);
      return;
    }

    let correspondingEvents = eventDataMapping[category];
    let requiredData = null;

    for (let i = 0; i < correspondingEvents.length; i++) {
      if (title === correspondingEvents[i].path) {
        requiredData = correspondingEvents[i];
      }
    }

    if (requiredData === null) {
      navigate(`/page-not-found`);
    } else {
      setdata(requiredData);
    }
    return () => {};
  }, []);

  return data !== null ? <Template data={data} /> : null;
}

export default EventTemplateMiddleware;
