export const DASHBOARD_COLOR_MAPPER = {
  red: "#cb0a0a",
  yellow: "#ffcb2e",
  green: "#0eba1f",
};

export const DASHBOARD_BG_COLOR_MAPPER = {
  red: "#400404",
  yellow: "#946f00",
  green: "#033708",
};
