import React, { useRef, useState, useEffect } from "react";
import styles from "./CountdownTimer.module.css";

function CountdownTimer() {
  const [timerDays, settimerDays] = useState("00");
  const [timerHours, settimerHours] = useState("00");
  const [timerMinutes, settimerMinutes] = useState("00");
  const [timerSeconds, settimerSeconds] = useState("00");

  let interval = useRef();

  const startCountdown = () => {
    const countdownDate = new Date("Apr 5, 2022 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const diff = countdownDate - now;

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      if (diff < 0) {
        clearInterval(interval);
      } else {
        settimerDays(days);
        settimerHours(hours);
        settimerMinutes(minutes);
        settimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startCountdown();
    return () => {};
  }, []);

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.container}`}>
        <div>
          <p className={`${styles.time}`}>{timerDays}</p>
          <p>
            <small>Days</small>
          </p>
        </div>
        {/* <span>:</span> */}
        <div>
          <p className={`${styles.time}`}>{timerHours}</p>
          <p>
            <small>Hours</small>
          </p>
        </div>
        {/* <span>:</span> */}
        <div>
          <p className={`${styles.time}`}>{timerMinutes}</p>
          <p>
            <small>Minutes</small>
          </p>
        </div>
        {/* <span>:</span> */}
        <div>
          <p className={`${styles.time}`}>{timerSeconds}</p>
          <p>
            <small>Seconds</small>
          </p>
        </div>
      </div>
      <div className={`${styles.textContainer}`}>
        <p>To go Offline!</p>
      </div>
    </div>
  );
}

export default CountdownTimer;
