import React, { useEffect, useRef } from "react";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import styles from "./quote.module.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Quote = () => {
  const quoteRef = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.matchMedia({
      all: function () {
        gsap.from(quoteRef.current, {
          scale: 0,
          opacity: 0,
        });
      },
    });
  }, []);
  return (
    <div className={`${styles.quote_wrapper}`}>
      <div className={`${styles.bgDiv}`}></div>
      <div className={`${styles.quote_card}`} ref={quoteRef}>
        <FormatQuoteIcon className={`${styles.quote_icon}`} />
        <p className={`${styles.quote}`}>
          Learning gives Creativity
          <br />
          Creativity leads to thinking
          <br />
          Thinking provides knowledge
          <br />
          Knowledge makes you great.
        </p>
        <p className={`${styles.author}`}>- Dr. A. P. J. Abdul Kalam</p>
      </div>
    </div>
  );
};

export default Quote;
