import DescriptionIcon from "@mui/icons-material/Description";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import GavelIcon from "@mui/icons-material/Gavel";
import InfoIcon from "@mui/icons-material/Info";
import BusinessIcon from "@mui/icons-material/Business";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SegmentIcon from "@mui/icons-material/Segment";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

import styles from "../components/EventTemplate/EventTemplate.module.css";

/*
    0 - Description
    1 - Contact
    2 - Time and Platform
    3 - Rules
    4 - Rounds
    5 - Company
    6 - Speakers
*/

export const EVENT_TEMPLATE_DETAILS_NAV = [
  {
    title: "Description",
    icon: <DescriptionIcon className={`${styles.details_nav_icon}`} />,
    key: "description",
  },
  {
    title: "Rounds",
    icon: <InfoIcon className={`${styles.details_nav_icon}`} />,
    key: "rounds",
  },
  {
    title: "Rules",
    icon: <GavelIcon className={`${styles.details_nav_icon}`} />,
    key: "rules",
  },
  {
    title: "Company",
    icon: <BusinessIcon className={`${styles.details_nav_icon}`} />,
    key: "company",
  },
  {
    title: "Speakers",
    icon: <KeyboardVoiceIcon className={`${styles.details_nav_icon}`} />,
    key: "speakers",
  },
  {
    title: "Contact",
    icon: <ContactMailIcon className={`${styles.details_nav_icon}`} />,
    key: "contact",
  },
  {
    title: "Date and Fees",
    icon: <AccessTimeFilledIcon className={`${styles.details_nav_icon}`} />,
    key: "dateTimeFees",
  },
  {
    title: "Prerequisites",
    icon: <SegmentIcon className={`${styles.details_nav_icon}`} />,
    key: "prerequisites",
  },
  {
    title: "Abstract Format",
    icon: <SegmentIcon className={`${styles.details_nav_icon}`} />,
    key: "abstractPDF",
  },
  {
    title: "FAQ",
    icon: <LiveHelpIcon className={`${styles.details_nav_icon}`} />,
    key: "faqs",
  },
];
