import React from "react";
import styles from "./EventListMenu.module.css";
import ListIcon from "@mui/icons-material/List";

function EventListMenu({ onClickMethod }) {
  return (
    <div className={`${styles.wrapper}`} onClick={onClickMethod}>
      <ListIcon className={`${styles.icon}`} />
    </div>
  );
}

export default EventListMenu;
