import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./LoginRegistration.module.css";
import emailIcon from "../../assets/images/LoginRegistration/email.png";
import passwordIcon from "../../assets/images/LoginRegistration/key.png";
import nameIcon from "../../assets/images/LoginRegistration/name.png";
import phoneIcon from "../../assets/images/LoginRegistration/phone.png";
import collegeIcon from "../../assets/images/LoginRegistration/college.png";
import departmentIcon from "../../assets/images/LoginRegistration/department.png";
import yearIcon from "../../assets/images/LoginRegistration/year.png";
import referralIcon from "../../assets/images/LoginRegistration/referral.png";

import GlitchButton from "../../components/GlitchButton/GlitchButton";
import FormField from "../../components/FormField/FormField";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ACADEMIC_YEARS, COLLEGES, DEPARTMENTS } from "../../data/FormData";
import { validateRegistrationDetailsForm } from "../../validators/authValidators";
import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from "../../components/ToastNotification";
import { apiGoogleRegistrationData, apiRegister } from "../../api/auth";
import { stringifyUserDetails } from "../../utils/localStorageHelper";
import { Auth, SetAuth } from "../../App";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";
import rollNoIcon from "../../assets/images/LoginRegistration/idcard.png";

const registrationDetailsFormat = {
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  college: "",
  dept: "",
  year: 1,
  roll: "",
  dob: "",
  code: "",
  pwd: "",
  cpwd: "",
};

function RegistrationDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setsearchParams] = useSearchParams();

  const auth = useContext(Auth);
  const setAuth = useContext(SetAuth);

  let reCaptchaRef = useRef(null);
  const [registrationDetails, setregistrationDetails] = useState(
    registrationDetailsFormat
  );

  const [loader, setloader] = useState(false);
  const [registrationMethod, setregistrationMethod] = useState("gAuth");
  const [token, setToken] = useState("token");

  useEffect(() => {
    // if (state === undefined) {
    //   console.log("returning");
    //   return;
    // }

    // Registration through normal email
    if (state !== null && state.method === "normal") {
      changeRegistrationDetails({
        key: "email",
        value: state.email,
      });
      setregistrationMethod("normal");
    }
    // Registration through Google
    else {
      changeRegistrationDetails({
        key: "email",
        value: searchParams.get("email"),
      });
      changeRegistrationDetails({
        key: "firstname",
        value: searchParams.get("name"),
      });
      setToken(searchParams.get("token"));
      setregistrationMethod("gAuth");
    }

    return () => {};
  }, []);

  const changeRegistrationDetails = (args) => {
    let prevState = registrationDetails;
    prevState[args.key] = args.value;
    setregistrationDetails({ ...prevState });
  };

  const clickedRegister = async () => {
    // Form Validation
    let validation = validateRegistrationDetailsForm(
      registrationDetails,
      registrationMethod
    );

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    // reCaptcha Validation
    if (reCaptchaRef.current.getValue() === "") {
      showErrorToastNotification(<p>reCaptcha verification failed</p>);
      return;
    }

    setloader(true);

    let resp;

    if (registrationMethod === "normal") {
      resp = await apiRegister({
        ...registrationDetails,
        captcha: reCaptchaRef.current.getValue(),
      });
    } else if (registrationMethod === "gAuth") {
      resp = await apiGoogleRegistrationData({
        ...registrationDetails,
        captcha: reCaptchaRef.current.getValue(),
        token: token,
      });
    }

    reCaptchaRef.current.reset();
    setloader(false);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
        localStorage.setItem("details", stringifyUserDetails(resp.data));
        localStorage.setItem("token", resp.data.token);
        setAuth(true);
        showSuccessToastNotification(<p>{resp.data.message}</p>);
        navigate("/dashboard");
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.componentContainer}`}>
        <div className={`${styles.designContainer}`}></div>
        <div className={`${styles.formContainer} customScrollbar`}>
          {loader && <SimpleLoader text={"Registering"} />}
          <div
            className={`${styles.formContent}`}
            style={{ display: loader ? "none" : "flex" }}
          >
            <h1>Registration Details</h1>
            <p className={`${styles.divider}`}>Fill in your details</p>
            <div className={`${styles.formBox}`}>
              <FormField
                type={"text"}
                fieldIcon={emailIcon}
                placeholder="Email"
                name="email"
                value={registrationDetails}
                setter={changeRegistrationDetails}
                isReadOnly={true}
              />
              <FormField
                type={"text"}
                fieldIcon={nameIcon}
                placeholder="First Name"
                name="firstname"
                value={registrationDetails}
                setter={changeRegistrationDetails}
                isReadOnly={registrationMethod === "gAuth" && true}
              />
              <FormField
                type={"text"}
                fieldIcon={nameIcon}
                placeholder="Last Name"
                name="lastname"
                value={registrationDetails}
                setter={changeRegistrationDetails}
                isReadOnly={registrationMethod === "gAuth" && true}
              />
              <FormField
                type={"text"}
                fieldIcon={phoneIcon}
                placeholder="Phone Number"
                name="phone"
                value={registrationDetails}
                setter={changeRegistrationDetails}
              />
              <FormField
                type={"text"}
                fieldIcon={rollNoIcon}
                placeholder="Roll Number"
                name="roll"
                value={registrationDetails}
                setter={changeRegistrationDetails}
              />
              <FormField
                type={"text"}
                fieldIcon={yearIcon}
                placeholder="Date of Birth (DD-MM-YYYY)"
                name="dob"
                value={registrationDetails}
                setter={changeRegistrationDetails}
              />
              <FormField
                type={"text"}
                fieldIcon={collegeIcon}
                placeholder="College"
                name="college"
                value={registrationDetails}
                setter={changeRegistrationDetails}
              />
              <FormField
                type={"text"}
                fieldIcon={departmentIcon}
                placeholder="Department"
                name="dept"
                value={registrationDetails}
                setter={changeRegistrationDetails}
              />
              <FormField
                type={"dropdown"}
                fieldIcon={yearIcon}
                placeholder="Year"
                name="year"
                dropdownValues={ACADEMIC_YEARS}
                value={registrationDetails}
                setter={changeRegistrationDetails}
              />
              <FormField
                type={"text"}
                fieldIcon={referralIcon}
                placeholder="Referral Code"
                name="code"
                value={registrationDetails}
                setter={changeRegistrationDetails}
                info={"Optional"}
              />
              {registrationMethod !== "gAuth" && (
                <>
                  <FormField
                    type={"password"}
                    fieldIcon={passwordIcon}
                    placeholder="Password"
                    name="pwd"
                    value={registrationDetails}
                    setter={changeRegistrationDetails}
                    info={"Minimum 8 characters"}
                  />
                  <FormField
                    type={"password"}
                    fieldIcon={passwordIcon}
                    placeholder="Confirm Password"
                    name="cpwd"
                    value={registrationDetails}
                    setter={changeRegistrationDetails}
                  />
                </>
              )}

              <div className="recaptcha_container">
                <ReCAPTCHA
                  sitekey={"6LcMoTUdAAAAAGFo2lgEFl5sIpitgdT-lExG05FL"}
                  theme="dark"
                  size="normal"
                  className="recaptcha"
                  ref={reCaptchaRef}
                />
              </div>
              <div className={`${styles.buttonContainer}`}>
                <GlitchButton
                  text={"Register"}
                  onClickMethod={clickedRegister}
                />
              </div>
              <p className={`${styles.formText}`}>
                Already have an account?&nbsp;
                <br />
                <span
                  onClick={() => navigate("/login")}
                  className={`${styles.formLink}`}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationDetails;
