import React from "react";
import styles from "./MoreStuffCard.module.css";
import hourGlass from "../../assets/images/hour_glass.png";
function MoreStuffCard({ moreData }) {
  return (
    <div className={`${styles.extraCard}`}>
      <img
        src={hourGlass}
        className={`${styles.hourGlass}`}
        alt={"Hour Glass"}
      />
      <p>More {moreData} will be updated soon</p>
    </div>
  );
}

export default MoreStuffCard;
