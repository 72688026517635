import React from "react";
import styles from "./KMag.module.css";
import Heading from "../../components/Heading/Heading";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import { KMAG_DATA } from "../../data/KMag";
function KMag() {
  return (
    <div className={`${styles.wrapper} colorPaletteGradientBackground`}>
      <div className={`${styles.mainWrapper}`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.coverBox}`}>
            <img src={KMAG_DATA.magCoverPath} alt="K!Mag Cover" />
          </div>
          <div className={`${styles.textBox}`}>
            <div className={`${styles.headingContainer}`}>
              <Heading text={"K! Mag"} />
            </div>
            <p>{KMAG_DATA.description}</p>
            <div className={`${styles.buttonContainer}`}>
              <GlitchButton
                text={"Download"}
                onClickMethod={() => window.open(KMAG_DATA.magPDFPath)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KMag;
