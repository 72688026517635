import React from "react";
import styles from "./PageNotFound.module.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { gsap, Linear } from "gsap";
import { useState, useEffect, useRef } from "react";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import Page_transition from "../../components/Animation/Transition";
export default function PageNotFound() {
  const navigate = useNavigate();

  let t1 = gsap.timeline();
  let t3 = gsap.timeline();

  const boxRef = useRef();
  useEffect(() => {
    t1.to(boxRef.current, {
      transformOrigin: "50% 50%",
      rotation: "+=360",
      repeat: -1,
      ease: Linear.easeNone,
      duration: 8,
    });
  });

  const boxRef2 = useRef();
  useEffect(() => {
    t3.fromTo(
      boxRef2.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        stagger: {
          repeat: -1,
          yoyo: true,
        },
      }
    );
  });

  return (
    <Page_transition>
      <>
        <div className={`${styles.outer}`}>
          <div className={`${styles.wrapper}`}>
            <div className={styles.container}>
              <h1 className={styles.firstfour}>4</h1>

              <div className={styles.cogwheel1} ref={boxRef}>
                <div className={styles.cog1}>
                  <div className={styles.top}></div>
                  <div className={styles.down}></div>
                  <div className={styles.lefttop}></div>
                  <div className={styles.leftdown}></div>
                  <div className={styles.righttop}></div>
                  <div className={styles.rightdown}></div>
                  <div className={styles.left}></div>
                  <div className={styles.right}></div>
                </div>
              </div>

              <h1 className={styles.secondfour}>4</h1>
            </div>
            <div className={`${styles.content}`}>
              <p className={styles.wrongpara} ref={boxRef2}>
                Uh! Page not found!
              </p>
              <div>
                <GlitchButton
                  text={"Back to Homepage"}
                  onClickMethod={() => navigate("/")}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Page_transition>
  );
}
