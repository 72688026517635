import React, { useState, useRef } from "react";
import styles from "./LoginRegistration.module.css";
import emailIcon from "../../assets/images/LoginRegistration/email.png";
import passwordIcon from "../../assets/images/LoginRegistration/key.png";
import GlitchButton from "../../components/GlitchButton/GlitchButton";
import FormField from "../../components/FormField/FormField";
import ReCAPTCHA from "react-google-recaptcha";
import { validateResetPasswordForm } from "../../validators/authValidators";
import { showErrorToastNotification } from "../../components/ToastNotification";
import { apiResetPassword } from "../../api/auth";
import Page_transition from "../../components/Animation/Transition";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";
const resetPasswordFormFormat = {
  email: "",
  pwd: "",
  cpwd: "",
};

function ResetPassword() {
  let reCaptchaRef = useRef(null);
  const [resetPasswordDetails, setresetPasswordDetails] = useState(
    resetPasswordFormFormat
  );

  const [loader, setloader] = useState(false);

  const changeResetPasswordDetails = (args) => {
    let prevState = resetPasswordDetails;
    prevState[args.key] = args.value;
    setresetPasswordDetails({ ...prevState });
  };

  const clickedResetPassword = async () => {
    // Form Validation
    let validation = validateResetPasswordForm({
      pwd: resetPasswordDetails.pwd,
      cpwd: resetPasswordDetails.cpwd,
    });

    if (validation.status === false) {
      showErrorToastNotification(<p>{validation.message}</p>);
      return;
    }

    // reCaptcha Validation
    if (reCaptchaRef.current.getValue() === "") {
      showErrorToastNotification(<p>reCaptcha verification failed</p>);
      return;
    }

    setloader(true);

    const resp = await apiResetPassword({
      ...resetPasswordDetails,
      captcha: reCaptchaRef.current.getValue(),
    });

    reCaptchaRef.current.reset();
    setloader(false);

    if (resp === undefined) {
      showErrorToastNotification(<p>Please try again after sometime</p>);
    } else {
      if (resp.status === 200) {
        // Success
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      } else if (resp.status >= 500 && resp.status < 600) {
        showErrorToastNotification(<p>{resp.data.message}</p>);
      }
    }
  };

  return (
    <Page_transition>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.componentContainer}`}>
          <div className={`${styles.designContainer}`}></div>
          <div className={`${styles.formContainer}  customScrollbar`}>
            {loader && <SimpleLoader text={"Resetting password"} />}
            <div
              className={`${styles.formContent}`}
              style={{ display: loader ? "none" : "flex" }}
            >
              <h1>Reset Password</h1>
              <p className={`${styles.divider}`}>Enter your new password</p>

              <div className={`${styles.formBox}`}>
                <FormField
                  type={"text"}
                  fieldIcon={emailIcon}
                  placeholder="Email"
                  name="email"
                  value={resetPasswordDetails}
                  setter={changeResetPasswordDetails}
                />
                <FormField
                  type={"password"}
                  fieldIcon={passwordIcon}
                  placeholder="New Password"
                  name="pwd"
                  value={resetPasswordDetails}
                  setter={changeResetPasswordDetails}
                />
                <FormField
                  type={"password"}
                  fieldIcon={passwordIcon}
                  placeholder="Confirm New Password"
                  name="cpwd"
                  value={resetPasswordDetails}
                  setter={changeResetPasswordDetails}
                />
              </div>
              <div className="recaptcha_container">
                <ReCAPTCHA
                  sitekey={"6LcMoTUdAAAAAGFo2lgEFl5sIpitgdT-lExG05FL"}
                  theme="dark"
                  size="normal"
                  className="recaptcha"
                  ref={reCaptchaRef}
                />
              </div>
              <div className={`${styles.buttonContainer}`}>
                <GlitchButton
                  text={"Reset"}
                  onClickMethod={clickedResetPassword}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page_transition>
  );
}

export default ResetPassword;
