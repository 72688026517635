const localImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Events/coding/`;
export const CODING_EVENTS = [
  // Ninja Coding
  {
    price: 299,
    eventcode: "NINJACODING",
    participantsTypeFlag: "INDIVIDUAL&TEAM",
    participantsDetail: {
      minSize: 2,
      maxSize: 2,
    },
    regOpen: true,
    title: "Ninja Coding",
    path: "ninja-coding",
    filler: localImageBasePath + "ninja-coding/filler.jpg",
    oneLiner:
      "A unique coding event that tests one's ability to code in an environment contrast to normal labs or solitude",
    description: [
      "Ninja coding of Kurukshetra is a unique coding event that tests one's ability to code in an environment contrast to normal labs or solitude, where the programmers prefer to test their tolerance level.",
      "The event comprises three rounds, with an increasing count of hindrances with each level.",
      "This possibly includes code obfuscation, loud music, open space coding, frequent switching between systems, etc.",
      "The top three ninjas of coding who are capable of playing with challenging programs are crowned in the final round.",
      "Kurukshetra’22 calls out all Ninjas to the coding celebrations.",
    ],
    rounds: [
      {
        roundName: "Round 1 - SOLVE AND BUBBLE",
        description: [
          "The participants are expected to solve questions [25 MCQs and 5 Debugging queries] based on Data Structures and Algorithms concepts within 30 minutes in this pen and paper round.",
        ],
      },
      {
        roundName: "Round 2 - SWAP ‘N’ CODE",
        description: [
          "Each member of the team is given a different problem statement.",
          "Swaps are made between the team members every 5 minutes to disrupt their thinking.",
          "The members are expected to code their solution within 60 minutes and overcome several other hindrances (loud music, open space coding etc).",
          "The round will be hosted on online coding platforms.",
        ],
      },
      {
        roundName: "Round 3 - CODE INDIVIDUALLY AND WIN TOGETHER",
        description: [
          "Each member is provided with a different set of questions [Two problem statements].",
          "The members must blind code, i.e., code with their monitors off within 60 minutes.",
          "Finally, their scores are cumulated to judge.",
          "The round will be hosted on online coding platforms.",
        ],
      },
    ],
    rules: [
      "Participants should have a valid K! id.",
      "Each team can have a maximum of 2 members.",
      "A team may comprise students from the same or different colleges.",
      "Any sort of malpractice would lead to disqualification of the team.",
      "Result of the judges & organizer’s is final.",
    ],
    contact: [
      {
        name: "Rajkumar G",
        mobile: "72001 63152",
      },
      {
        name: "Kanimozhi K",
        mobile: "99527 23176",
      },
      {
        name: "Jesima A",
        mobile: "96000 47786",
      },
    ],
    mail: "ninjacoding@cegtechforum.in",
    dateTimeFees: {
      date: "April 6-7, 2022",
    },
  },

  // Web Design
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    participantsDetail: {
      minSize: 2,
      maxSize: 2,
    },
    regOpen: true,
    title: "Web Design",
    path: "web-design",
    filler: localImageBasePath + "web-design/filler.jpg",
    oneLiner: "",
    description: [
      "The event deals with web page designing, which mainly gives different scopes of services in the field of web design. Participants should work behind the scenes to make the web page perform well with a flawless user experience",
      "The performance of the web page should focus on aesthetic factors like User Interface, layout, and other visual imagery",
      "Participants are required to recreate the webpage with the given resources",
      "In the final round, a theme will be given to the contestants, and with that, they must build an eye-pleasing webpage.",
    ],
    rounds: [
      {
        roundName: "Round 1 ",
        description: [
          "The participants are given a screenshot of a webpage; they must recreate it using HTML, CSS, Bootstrap, JS, and jQuery. ",
          "Points are awarded based on how fast and accurate they have recreated it and how responsive it is",
          "Resources like images, logos will be given to the participants.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "A Theme of a web page (like E-commerce, Food Delivery website) is given",
          "The Participants must design a web page with the given theme",
          "The web page should speak of the theme in a creative way and in a responsive manner",
          "Marks are awarded based on the responsiveness of the page, creativity, and user-friendliness",
          "Participants are allowed to use any kind of framework.",
        ],
      },
    ],
    rules: [
      "Each Team can include a maximum of 2 members (1-2). ",
      "A Participant cannot be a member of more than one team.",
      "The Team members need not necessarily be from the same institution/college.",
      "Team members must remain the same throughout all rounds.",
      "Teams involved in any kind of malpractice will be disqualified.",
      "Decision of the organizers will be final.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    prerequisites: [
      "Participants should bring their laptops installed with the frontend framework of their choice.",
    ],
    contact: [
      {
        name: "M.Nithiya ",
        mobile: "88259 87442",
      },
      {
        name: "A.Narendirakumar ",
        mobile: "9360612317",
      },
    ],
    mail: "webdesign@cegtechforum.in ",
    dateTimeFees: {
      date: "April 6, 2022",
    },
  },

  // OLPC
  {
    regRedirectLink: "https://www.hackerrank.com/olpc-kurukshetra22",
    price: 0,
    eventcode: "OLPC",
    participantsTypeFlag: "INDIVIDUAL",
    participantsDetail: {
      minSize: 2,
      maxSize: 2,
    },
    regOpen: true,
    title: "Online Programming Contest (OLPC)",
    path: "online-programming-contest",
    filler: localImageBasePath + "online-programming-contest/filler.jpg",
    oneLiner: "",
    description: [
      "Are you the one, who always thinks out of box to solve problems? Are you passionate about programming?",
      "This is the perfect event for you. You don’t need to travel long distances to show your love for programming.",
      "You can just stay at your place and enjoy the art of programming. What are you waiting for?",
    ],

    rounds: [
      {
        roundName: "Round 1",
        description: [
          "It is be a coding round, where you are required to solve a number of problems within the given time.",
          "The Participants solve the problems using the permitted coding platform and allowed programming languages.",
          "Accuracy of solution, speed of execution and efficient memory usage are the key criteria for evaluation.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "It is an online Competitive programming round.",
          "The rules are similar to round 1.",
        ],
      },
    ],
    rules: [
      "Participants can participate as individual only.",
      "Participants must create a HackerRank account to participate in the event.",
      "Organizers have the right to eliminate any team if found guilty of any malpractice.",
      "Participants must be registered to participate in the event.",
      "Participants must participate only with registered ids. Unregistered participation will not be considered.",
      "Participants are not allowed to copy from the internet or any other participants. The participants will be disqualified if found copying.",
      "Participants should submit the code within the time. Codes that are not submitted are not considered for evaluation.",
      "Only one submission per team is allowed.",
      "In case of a tie, the participant solving the question in minimum time will be declared the winner.",
    ],

    contact: [
      {
        name: "Srihari T",
        mobile: "88256 56830",
      },
      {
        name: "Suvetha Devi R",
        mobile: "93443 59099",
      },
      {
        name: "Subashini M",
        mobile: "8925738823",
      },
    ],
    mail: "olpc@cegtechforum.in",
    dateTimeFees: {
      date: "April 2, 2022",
    },
  },

  // Datathon
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    participantsDetail: {
      minSize: 2,
      maxSize: 2,
    },
    regOpen: true,
    title: "Datathon",
    path: "datathon",
    filler: localImageBasePath + "datathon/filler.jpg",

    oneLiner: "",
    description: [
      "The event focuses on the ability of individuals/teams to maximize the data available to them and come up with the best solution/algorithm",
      "This ability is useful for anyone who wishes to explore their skills in data analysis, which is in great demand in the current world",
      "Other than analyzing the participant’s skills, the event also aims to see the strategic vision of participants in the business world",
      "Complimentary drinks will be provided for all participants and as the event round proceeds, cool drinks will be used as a hindrance.",
    ],

    rounds: [
      {
        roundName: "Round 1",
        description: [
          "A Written MCQ round testing the participants on various topics such as Data Science, Parallel computing, Machine Language, DBMS and DSA.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "This round focuses on the participants' knowledge on SQL, given the database and the expected retrievals to be made from the table, the participants should write/code the SQL query and execute them.",
          "It also tests participants' knowledge in Data Science, where the participants are asked to code and solve an ML problem.",
        ],
      },
    ],
    rules: [
      "Students can participate as an individual/team of 2.",
      "Participants must have a valid K! ID.",
      "Organizers have the right to eliminate any team if found guilty of any malpractice.",
      "Participants should not use mobile phones/internet during the event.",
      "In round 2, Participants should take the drink offered during the event after completing and verifying the output of each query, before moving to the next question.",
      "Participants should not alter the database given in the second round. If done so, it shall be treated as malpractice.",
      "The organizer’s decision will be final.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],

    contact: [
      {
        name: "Araventh M",
        mobile: "88255 51857",
      },
      {
        name: "Adarshini K",
        mobile: "70103 72669",
      },
    ],
    mail: "datathon@cegtechforum.in",
  },

  // She-Codes
  {
    price: 299,
    eventcode: "GENERALREGISTRATION",
    participantsTypeFlag: "INDIVIDUAL",
    participantsDetail: {
      minSize: 2,
      maxSize: 2,
    },
    regOpen: true,
    title: "She Codes",
    path: "she-codes",
    filler: localImageBasePath + "she-codes/filler.jpg",
    oneLiner: "Women only event",
    description: [
      "SheCodes is a for women only coding event, in which the participants will be assigned with MCQ questions from seven domains for round one, challenging individual debugging questions for round two, where the participant has to code in flipped landscape screen and a complex programming question for round three, where the teams have to solve and get the desired output within the given time.",
      "Overall, it tests the logical, mathematical and programming skills of the participants.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Heptathlon",
        description: [
          "Round one consists of 35 MCQs from various fields of computer science, participants have to answer them within 40 minutes.",
          "Questions will be asked from the following 7 domains - Ciphering, Theory of Computation, Basic Programming, Website Developing, DBMS, Networks and Software",
        ],
      },
      {
        roundName: "Round 2 - Flipped",
        description: [
          "This round consists of challenging debugging questions.",
          "Each team (consists of two) will be given two questions to debug individually within 45 minutes.",
          "The main hindrance in this round is the flipped screen.",
          "The participants have to debug and get the required output in their upside-down, flipped landscape screen.",
        ],
      },
      {
        roundName: "Round 3 - Code Time",
        description: [
          "This round consists of complex programming questions.",
          "In this, each team has to solve and get the desired output for two complex programs within the given time.",
        ],
      },
    ],
    rules: [
      "Teams must have a proper registered K! Id.",
      "Once a choice is made, it cannot be changed.",
      "Mobile phones are strictly prohibited.",
      "Teams should complete all the rounds within the given time.",
      "Coding questions and MCQ questions shouldn’t be discussed among other teams.",
      "The Organizer's decision is the final, teams are expected to abide by it.",
      "For round 2, i) participants have to code in an upside-down, flipped landscape screen. ii) If a participant inverts the screen, then they will be disqualified.",
      "For round 3, participants have to solve two complex programs within the given time.",
      "Winners will be selected based on code complexity, recorded time and output.",
    ],

    contact: [
      {
        name: "Farhana S",
        mobile: "80721 81067",
      },
      {
        name: "Suryaa",
        mobile: "98841 52271",
      },
      {
        name: "Subhashree",
        mobile: "70103 72669",
      },
    ],
    mail: "heptathlon@cegtechforum.in",
    dateTimeFees: {
      date: "April 7, 2022",
    },
  },
];
