const localImageBasePath = `${process.env.PUBLIC_URL}/assets/images/Events/management/`;
export const MANAGEMENT_EVENTS = [
  // Mad over marketing
  {
    price: 299,
    regOpen: true,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "Mad Over Marketing",
    path: "mad-over-marketing",
    filler: localImageBasePath + "mad-over-marketing/filler.jpg",
    oneLiner: "",
    description: [
      "'Deiii ithellam vyabaaramey aagathu da !'– Ha-ha, that is when marketing comes to play.",
      "Come get mad over marketing establishing your marketing and management skills. Show how much better can you make things look interesting, just like how we managed our event description.",
      "Mad over Marketing is sure shot to help the participants to hone their entrepreneurship, marketing, and management skills.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Mark-a-tick",
        description: [
          "Quiz based on marketing and aptitude related questions.",
          "Question paper will be given with 4 appropriate options for each question and participants need to tick the right option.",
          "This round consists of 30 questions and 60 seconds will be provided to solve each question.",
          "Timing will be strictly followed, and malpractice will not be encouraged.",
        ],
      },
      {
        roundName: "Round 2 - Star Debugger",
        description: [
          "A television advertisement for a product will be given (to select the ad, one participant from each team will pick a ball from the box of colored ping pong balls) and participants will have to hunt for loopholes and overhype in the ad and come up with solution and an improvised version of the same ad, with better appealing tag line.",
        ],
      },
      {
        roundName: "Round 3 - Marketing Campaign",
        description: [
          "A product will be given (by picking a card from a deck of playing cards) to a team and they are expected to present an entire marketing strategy (short- and long-term goals, target audience, brand promotion, swot analysis of their product, competition in the market, color psychology) with ad campaign (perform a skit with tag lines) and explain the mission, vision, and values. 25 minutes for preparation, 15 mins to present and few minutes to answer the questions raised by the judges. Should not use false information to promote the product",
        ],
      },
    ],
    rules: [
      "Switch off your mobile phones before the start of the event.",
      "Use of false information to promote your product is not encouraged.",
      "Malpractice in the pen and paper test will not be encouraged as well.",
      "Judges’ and organizers’ decision will be final.",
      "Other rules will be explained before the start of each round.",
      "Time limit will be strictly followed.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Vaishnavi S ",
        mobile: "+91 9444656713 ",
      },
      {
        name: "Kamalesh P ",
        mobile: "+91 9384736041",
      },
    ],
    mail: "madovermarketing@cegtechforum.in",
    dateTimeFees: {
      date: "April 7, 2022",
    },
  },

  // The Startup
  {
    price: 299,
    regOpen: true,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "The Startup",
    path: "startup",
    filler: localImageBasePath + "startup/filler.jpg",
    oneLiner: "",
    description: [
      "It's a fun-filled management event, in which a start-up idase will be given prior, keeping that in mind teams have to invest wisely to establish a wonderful start-up company.",
      "A team of 2 players will be given an initial investment amount Rs.50,00,000 to enter the game.",
      "Using this initial amount, the teams have to make wise decisions and invest the amount in the first 2 rounds. Students go through the chaotic and exciting early days of a start-up in each round, and then learn to make sense of what they experienced.",
      "Each team takes on the role of either a founder, a Co-founder, or a key early employee and works to secure the right combination of decisions and talent in order to make their start-up a success.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "In this, the participants are asked to pick the best options(which they feel would be right) from the given 20 MCQ questions.",
          "In round 1, all the prices will be mentioned next to the options, so they can plan the amount of money they are going to invest beforehand.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "In this round, the shortlisted participants will be given several questions regarding hiring of employees for auction just like the IPL Auction, in which they have to bid among other teams with their remaining amount.",
          "Thus, they have to wisely analyze and select options regarding the startup idea.",
          "The teams then have to give a detailed explanation or review about their wise investment of money, the choices they made and pitch their idea to the panel, they also have to answer the question asked by the panel to finalize the 3 winners.",
        ],
      },
    ],
    rules: [
      "The participants should have a valid registered K! ID",
      "Teams can’t quit in the middle of the event due to any reasons.",
      "Once a choice is made, it cannot be changed.",
      "Browsing/Referring to any resources are strictly prohibited.",
      "Teams should complete all the rounds within the given time.",
      "Choices cannot be discussed among other teams.",
      "The panel members’ decision is final, teams are expected to abide by it.",
      "All the materials used	during the event should	be handed	over to	the Organisers/Coordinators present at the venue.",
      "Materials provided in the event should be handled with care.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Jayitha Sadhanandh ",
        mobile: "+91 9003211499",
      },
      {
        name: "Farhana S ",
        mobile: "+91 80721 81067",
      },
    ],
    mail: "thestartup@cegtechforum.in",
    dateTimeFees: {
      date: "April 6-7, 2022",
    },
  },

  // Pitch Perfect
  {
    price: 299,
    regOpen: true,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "Pitch Perfect",
    path: "pitch-perfect",
    filler: localImageBasePath + "pitch-perfect/filler.jpg",
    oneLiner: "",
    description: [
	    "Pitch Perfect is a management event which tests the participant’s marketing and negotiating skills in convincing the venture capitalists to invest in their business.",
      "The event comprises of three rounds and each round being an elimination round.",
      "The top three teams who best negotiates with the venture capitalist(judge) at the final round will be declared as winners. ",
    ],
    rounds: [
      {
        roundName: "Round 1 - Pen and Paper Round",
        description: [
          "It is a pen and paper round, consists of 30 MCQ’s questions. ",
          "Questions will be based on start-up, entrepreneurship, quantitative aptitude, logical reasoning and verbal ability, based on the marks scored you will be shortlisted to the next round.",
   
        ],
      },
      {
        roundName: "Round 2 - Shipwreck",
        description: [
          "Participant will be given the role of famous personalities ",
          "The ship is sinking, and the Captain has only one Life-Jacket left. ",
          "You are on the ship with other famous celebrities/ fictional characters/ hypothetical characters. ",
          "Sink into the given character and try to escape from the sinking ship by using your impressive convincing skills and prove that you deserve the only Life-jacket. ",
          "Make yourself appear prominent and better than any other person/ thing on board to win the Life jacket and eventually win the event. ",
          "The participants are judged on content, humour, cogent arguments, valid points and audience response.",
        ],
      },
      {
        roundName: "Round 3 - Negotiation",
        description: [
          "Shortlisted participants from Round 2 will be given a datasheet about the business they need to negotiate. ",
          "They have to consider themselves as an entrepreneur running that business and propose that business idea to the venture capitalist in order to get capital to their company. ",
		  "The more practical and how well they market the idea and negotiate with the venture capitalist, the better is their chance to win.",
        ],
      },
    ],
    rules: [
      "Each team can consist of a maximum of 2 members.",
      "One person cannot be in more than one team.",
      "Team members need not necessarily be from the same institution.",
      "Team members must remain the same throughout all rounds.",
      "Round 1 will be conducted as pen and paper round.",
      "Participants should submit the answer sheet within given time limit.",
      "Discussing with other teams will lead to disqualification.",
      "Teams shortlisted alone will be participating in successive rounds.",
      "Teams shortlisted for successive rounds only will be given details of that round.",
      "The shortlisting will be based purely on the scores from the previous round.",
      "The teams will be instantly disqualified if found guilty of malpractice in any form.",
      "Decision of the organizers will be final.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Karthikeyan N ",
        mobile: "+91 6383793732",
      },
      {
        name: "Tiny Jacob J ",
        mobile: "+91 7825028390",
      },
      {
        name: "Joshika G",
        mobile: "+91 8838011703",
      },
    ],
    mail: "pitchperfect@cegtechforum.in",
    dateTimeFees: {
      date: "April 7-8, 2022",
    },
  },

 

  // Tech Crunch
  {
    price: 299,
    regOpen: true,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "Tech Crunch",
    path: "tech-crunch",
    filler: localImageBasePath + "tech-crunch/filler.jpg",
    oneLiner: "",
    description: [
      "Do You want to become an entrepreneur? Having an idea and don’t know how to start and where to start? Then this event, ‘Tech crunch’ will help you enhance your skills and ideas for your growing future.",
      "This event comprises of two rounds.",
      "Teams qualifying round 1 will be selected for round 2.",
      "The team which is proposing the most creative idea in round 2 wins the event.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "All the participants will be split up into teams to make them interactive.",
          "They will be provided with a flashcard which consists of few clues.",
          "Using those clues, they have to create and propose a start-up idea within a time span of 30 to 45 minutes.",
          "The best 3 ideas from 3 teams will be shortlisted to the second round.",
        ],
      },
      {
        roundName: "Round 2",
        description: [
          "The 9 shortlisted students from 3 teams will be asked to present their own start up idea and give a business proposal to the judge.",
          "The best proposal among the 3 teams wins the event.",
        ],
      },
    ],
    rules: [
      "Usage of mobile phones will be strictly prohibited.",
      "Participants must pre-register themselves.",
      "Participants will be allowed only if they have their welcome band which will be issued to them after registration.",
      "Any malpractice would lead to disqualification of the team.",
      "Idea should be proposed within the stipulated time.",
      "Judge’s decision is final.",
      "This event is covered under the General Kurukshetra'22 Registration for Rs. 299/- only",
    ],
    contact: [
      {
        name: "Ramya K M",
        mobile: "+91 7995211207",
      },
      {
        name: "Jemini M ",
        mobile: "+91 8870779958",
      },
      {
        name: "Subashini B",
        mobile: "+91 9361201915",
      },
    ],
    mail: "techcrunch@cegtechforum.in",
  },

  // Modulo Management
  {
    price: 299,
    regOpen: true,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "GENERALREGISTRATION",
    title: "Modulo Management",
    path: "modulo-management",
    filler: localImageBasePath + "modulo-management/filler.jpg",
    oneLiner: "",
    description: [
      "Modulo Management is an event that promotes innovative ideas and industrial analysis.",
      "This event primarily focuses on the team's ability to analyse a case and think out of the box for a solution.",
    ],
    rounds: [
      {
        roundName: "Round 1 - Preliminary",
        description: [
          "This round will be a pen and paper round.",
          "Question Paper Pattern will be aptitude based questions, logical reasoning, puzzle based questions.",
          "Questions will be available for three levels – easy, medium and hard.",
          "Teams will be given time based on number of team members.",
          "Teams will be shortlisted based on their scores.",
        ],
      },
      {
        roundName: "Round 2 - Final",
        description: [
          "Case studies of real or hypothetical businesses are handed over to the students. They are given sufficient time to understand and analyze the case study and come up with feasible solutions to the problems or provide an alternative path that could have shown better results for the business. This event primarily focuses on the ability to analyze a case and think out of the box for a solution.",
          "Guidelines will be provided along with the case study.",
          "Teams are expected to prepare a PPT and present it within 10 minutes.",
        ],
      },
    ],
    rules: [
      "Minimum of 1 and maximum of 3 members per team is allowed.",
      "No participant can be a member of more than one team.",
      "Participants can be from any branch, any year and any college.",
      "No extra time will be provided under any circumstances.",
      "Marks shall be deducted accordingly for exceeding time limits.",
      "Participants involved in any kind of malpractice will be disqualified immediately.",
      "Decisions made by the judge and organizers will be final.",
    ],
    contact: [
      {
        name: "Kavya Sridhar",
        mobile: "+91 9566281518",
      },
      {
        name: "Diviya",
        mobile: "+91 8300499335",
      },
      {
        name: "Naveena",
        mobile: "+91 7339366598",
      },
    ],
    mail: "modulomanagement@cegtechforum.in",
    dateTimeFees: {
      date: "April 7-8, 2022",
    },
  },

  // Wall street wolverine
  {
    price: 0,
    regOpen: false,
    participantsTypeFlag: "INDIVIDUAL",
    eventcode: "WALLSTREET",
    title: "Wall Street Wolverine",
    path: "wall-street-wolverine",
    filler: localImageBasePath + "wall-street-wolverine/filler.jpg",
    oneLiner:
      "Tired of only hearing and watching advertisements about the stock market and trading but really wanted to try them yourself virtually without any property loss.",
    description: [
      "Tired of only hearing and watching advertisements about the stock market and trading but really wanted to try them yourself virtually without any property loss.",
      "You are at the right place, this event allows for a certain number of credits to be provided with news updates and statistics.",
      "Participants will be able to buy or sell their stocks using the information displayed. At the end of the event, the participants with the most exchanges and credits win.",
      "Practice and compete along with other participants at the same time and unleash your skills.",
    ],
    rounds: [
      {
        roundName: "Round 1",
        description: [
          "This is a two full-day online mode event consisting of a single round.",
          "It will be conducted through a custom build website with news updating on a regular basis, which enables users to buy/sell stocks within the site.",
          "Duration: 6 hours (10:00 AM - 04:00 PM)",
        ],
      },
    ],
    rules: [
      "Participants must have a valid K! ID.",
      "At the end of the event, the player with the highest total assets (cash holding and worth of stocks) across the series will be declared the winner.",
      "A player can buy or sell stocks only during the various sessions of a day.",
      "Market timing is subject to changes.",
      "An initially ‘X’ credit of virtual money will be provided to trade.",
      "The league rules will be displayed at the start of the game.",
      "Further details will be provided at the start of the game.",
      "The rules are subject to change before the game and the Organizer’s decision is final at any point of the game. Buy/sell can’t be reverted back once made for an instance time.",
      "No real-world money is involved in this event, the credits provided are virtual with no real-life exchange value.",
    ],
    contact: [
      {
        name: "Deepak",
        mobile: "+91 7904391142",
      },
      {
        name: "Sivadanus",
        mobile: "+91  7550035799",
      },
    ],
    mail: "wallstreethacks@cegtechforum.in",
    dateTimeFees: {
      date: "April 2-3, 2022",
    },
  },
];
