import React, { useRef, useEffect } from "react";
import styles from "./EventTemplate.module.css";
import { EVENT_TEMPLATE_DETAILS_NAV as DETAILS_NAV } from "../../data/EventTemplate";

function DetailsNav({ clickedDetailsNav, currentDetail, data }) {
  let detailsNavRefs = new Array(DETAILS_NAV.length);

  useEffect(() => {
    detailsNavRefs[currentDetail.index].classList.add(
      `${styles.details_nav_active}`
    );
    return () => {};
  }, []);

  return (
    <div className={`${styles.details_nav_container}`}>
      {DETAILS_NAV.map((item, index) => {
        if (!data.hasOwnProperty(item.key)) return;
        return (
          <div
            ref={(el) => (detailsNavRefs[index] = el)}
            key={index}
            className={`${styles.details_nav}`}
            onClick={() =>
              clickedDetailsNav(
                {
                  name: item.key,
                  index: index,
                },
                detailsNavRefs[index],
                detailsNavRefs[currentDetail.index]
              )
            }
          >
            {item.icon}&nbsp;{item.title}
          </div>
        );
      })}
    </div>
  );
}

export default DetailsNav;
